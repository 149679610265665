import { Component } from 'react';
import {Banner} from './Banner';
import {SectionOne} from './SectionOne';

export class PrivacyPolicy extends Component {
    render() {
        return (
            <div>
                <Banner/>
                <SectionOne/>
            </div>
        )
    }
}

export default PrivacyPolicy;
