import { Component } from 'react';
import {SectionFourth} from './Styled';
import image from '../../../assets/Home/D.webp';

export class SectionFour extends Component {

    render() {
        return (
            <SectionFourth>
                <div className="section-lining lining-before"><div className="dotted"></div></div>
                <div className="skew-inner-container container">
                    <div className="row">
                        <div className="col-md-7 order-2">
                            <div className="l-box">
                                <img src={image} alt=""/>
                            </div>
                        </div>
                        <div className="col-md-5 order-1">
                            <div className="r-box">
                                <h2 className="large-text">4.screen Portal</h2>
                                <h3 className="small-text">Launch Your In-car Campaign in Minutes!</h3>
                                <p className="paragraph">
                                    Fast set up. Personal support. Precise targeting. Transparent pricing. Real-time performance tracking.
                                </p>
                                <p className="paragraph">
                                    Run your campaign using the 4.screen portal today to drive real business results.
                                </p>
                                <a href='/contact-us' className="btn">Book a Demo
                                    <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.03516 6.4314C6.48287 6.4314 6.03516 6.87911 6.03516 7.4314C6.03516 7.98368 6.48287 8.4314 7.03516 8.4314L7.03516 6.4314ZM8.37202 8.1385C8.76255 7.74798 8.76255 7.11481 8.37202 6.72429L2.00806 0.360328C1.61754 -0.0301964 0.984371 -0.0301964 0.593847 0.360328C0.203322 0.750852 0.203322 1.38402 0.593847 1.77454L6.2507 7.4314L0.593846 13.0882C0.203322 13.4788 0.203322 14.1119 0.593846 14.5025C0.98437 14.893 1.61754 14.893 2.00806 14.5025L8.37202 8.1385ZM7.03516 8.4314L7.66491 8.4314L7.66491 6.4314L7.03516 6.4314L7.03516 8.4314Z" fill="white"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-lining lining-after"><div className="dotted"></div></div>
            </SectionFourth>
        )
    }
}

export default SectionFour;