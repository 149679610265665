import { Component } from 'react';
import { FooterPart } from '../Helpers/Styled';
import logo from '../assets/footer/Logo.png';
import l from '../assets/footer/linkedin.png';
import n from '../assets/footer/network.png';
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';

export class Footer extends Component {
    render() {
        return (
            <FooterPart>
                <div className="container">
                    {this.HeaderView() !== 'contact-us'  &&                       
                        this.HeaderView() !== 'career-details'  &&   
                        this.HeaderView() !== 'career'  &&                      
                        this.HeaderView() !== 'marketer'  &&                      
                        this.HeaderView() !== 'about-us'  &&                      
                        <div className="card-container">
                            <ScrollAnimation duration={2} animateIn="animate__slideInUp" animateOnce={true}>
                            <div className="card text-center mx-auto">
                                <h2>Interested? Contact Us!</h2>                                
                                <form action="#" className="d-flex justify-content-center">                                    
                                    <a href="/contact-us" className="btn">Contact Us 
                                        <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.03516 6.4314C6.48287 6.4314 6.03516 6.87911 6.03516 7.4314C6.03516 7.98368 6.48287 8.4314 7.03516 8.4314L7.03516 6.4314ZM8.37202 8.1385C8.76255 7.74798 8.76255 7.11481 8.37202 6.72429L2.00806 0.360328C1.61754 -0.0301964 0.984371 -0.0301964 0.593847 0.360328C0.203322 0.750852 0.203322 1.38402 0.593847 1.77454L6.2507 7.4314L0.593846 13.0882C0.203322 13.4788 0.203322 14.1119 0.593846 14.5025C0.98437 14.893 1.61754 14.893 2.00806 14.5025L8.37202 8.1385ZM7.03516 8.4314L7.66491 8.4314L7.66491 6.4314L7.03516 6.4314L7.03516 8.4314Z" fill="white"/>
                                        </svg>
                                    </a>
                                </form>
                            </div>  
                            </ScrollAnimation>                      
                        </div>                        
                    }
                    <div className="list-group-container d-flex flex-wrap">                        
                        <ul>
                            <li className="heading">Product</li>
                            <li><a href="/marketer">Marketing</a></li>
                            <li><a href="/automotive">Automotive</a></li>
                        </ul>
                        <ul>
                            <li className="heading">Company</li>
                            <li><a href="/about-us">About Us</a></li>
                            <li><a href="/news-room">Newsroom</a></li>
                            <li><a href="/blog">Blog</a></li>
                        </ul>
                        <ul>
                            <li className="heading">Careers</li>
                            <li><a href="/career">Job Listings</a></li>
                        </ul>
                        <ul>
                            <li className="heading">Help &amp; Support</li>
                            <li><a href="/contact-us">Contact Us</a></li>
                        </ul>
                        <ul>
                            <li className="heading">Terms &amp; Conditions</li>
                            <li><a target="_blank" href="/privacy-policy">Privacy Policy</a></li>
                            <li><a target="_blank" href="/legal">Legal Notice</a></li>
                        </ul>
                        <ul>
                            <li className="heading">Social</li>
                            <li className="social d-flex">
                                {/* <a href="/#"><img src={fb} alt=""/></a>
                                <a href="/#"><img src={t} alt=""/></a>
                                <a href="/#"><img src={d} alt=""/></a> */}
                                {/* <a href="https://www.youtube.com/watch?v=-TuC9Q9MG-c&t=3s"><img src={yt} alt=""/></a> */}
                                <a href="https://www.linkedin.com/company/4-screen/?lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BigpeYaD9S0yXLi263rIwqg%3D%3D"><img src={l} alt=""/></a>
                            </li>
                        </ul>
                    </div>
                    <div className="bottom">
                        <div className="row align-items-end">
                            <div className="col-lg-7 col-md-4 col-sm-12 center-sm"><img src={logo} className="footer-logo" alt="" /></div>
                            <div className="col-lg-2 col-md-4 col-sm-12 text-right center-sm">&copy; 2022 4.screen</div>
                            <div className="col-lg-3 col-md-4 col-sm-12 text-right center-sm">
                                {/* <span className="en"><img src={n} alt="" /> EN</span> */}
                                <span className="terms"><a href="/privacy-policy" target="_blank">Terms &amp; Privacy</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </FooterPart>
        )
    }
    HeaderView(){
        const location = window.location;
        return location.pathname.split('/')[1];
    }
}

export default Footer;