import { Component } from 'react';
import {SectionSecond} from './Styled';
import Lottie from 'react-lottie-player'
import {Waypoint} from 'react-waypoint';
import lottieJson from '../../../assets/Home/cloud_animation.json'

export class SectionTwo extends Component<{}, { renderLottie: any } > {
    constructor(props: any) {
        super(props);
        this.state = {
            renderLottie: false
        };
    }
    _handleWaypointEnter = () => {
        this.setState({ renderLottie: true });
    }
    _handleWaypointLeave = () => {
        this.setState({ renderLottie: false });
    }
    render() {
        return (
            <SectionSecond>
                <div className="section-lining lining-before"><div className="dotted"></div></div>
                <div className="skew-inner-container container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="content left text-center">
                            <Waypoint onEnter={this._handleWaypointEnter} onLeave={this._handleWaypointLeave} />
                                { this.state.renderLottie && <Lottie animationData={lottieJson} play rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}  style={{ width: "100%", height: "auto" }}/> }
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="content right">
                                <h2>The Mobility Experience Cloud</h2>
                                <h3>Enable. Empower. Enhance</h3>
                                <p>The 4.screen Mobility Experience Cloud (MXC) seamlessly connects the car to the mobility ecosystem and provides a standardized interface for real-time communication with drivers.</p>
                                <p>Automakers and Mobility Service Providers implement 4.screen APIs into their systems to provide integrated services for seamless journeys. Businesses of all sizes - from local shops to global brands - use 4.screen to communicate with drivers directly through the car screen.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-lining lining-after"><div className="dotted"></div></div>
            </SectionSecond>
        )
    }
}

export default SectionTwo;