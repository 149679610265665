import { Component } from 'react';
import { BannerSection } from './Styled';

export class Banner extends Component {
    render() {
        return (
            <BannerSection>
                <div className="container">
                  <h1>Legal Notice</h1>
                </div>
            </BannerSection>
        )
    }
}

export default Banner;