import { Component } from 'react';
import {Banner} from './Banner';
import {SectionTwo} from './SectionTwo';

export class Career extends Component {
    render() {
        return (
            <div>
                <Banner/>
                <SectionTwo/>
            </div>
        )
    }
}

export default Career;
