import { Component } from 'react';
import {Banner} from './Banner';
import {SectionOne} from './SectionOne';
import {SectionTwo} from './SectionTwo';
import {SectionThree} from './SectionThree';

export class ForAutomotive extends Component {
    render() {
        return (
            <div>
                <Banner/>
                <SectionOne/>
                <SectionTwo/>
                <SectionThree/>
            </div>
        )
    }
}

export default ForAutomotive;