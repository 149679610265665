import { Component } from 'react';
import { SectionSecond } from './Styled';
import image1 from '../../../assets/About_Us/2.png';
import image2 from '../../../assets/About_Us/3.png';
import image3 from '../../../assets/About_Us/4.png';
import image4 from '../../../assets/About_Us/5.png';

export class SectionTwo extends Component {
    render() {
        return (
            <SectionSecond>
                <div className="section-lining lining-before"><div className="dotted"></div></div>
                <div className="container skew-inner-container">
                    <h2 className="text-center">Our Values</h2>                    
                    <div className="box-container d-flex flex-wrap">
                        <div className="box">
                            <div className="image-container text-center">
                                <img src={image1} alt=""/>
                            </div>
                            <h3>Think Big, <br/> Be Bold</h3>
                            <p>We have ambitious goals, take bold decisions and progress from talking brave bets.</p>
                        </div>
                        <div className="box">
                            <div className="image-container text-center">
                                <img src={image2} alt=""/>
                            </div>
                            <h3>Be Excellent &amp; <br/> Pragmatic</h3>
                            <p>We strive for excellence in every task we do. In every problem we solve, and incorporate existing solutions into our work.</p>
                        </div>
                        <div className="box">
                            <div className="image-container text-center">
                                <img src={image3} alt=""/>
                            </div>
                            <h3>Live the <br/> Community</h3>
                            <p>We never let another 4.screen member fail and proactively help each other to be successful.</p>
                        </div>
                        <div className="box">
                            <div className="image-container text-center">
                                <img src={image4} alt=""/>
                            </div>
                            <h3>Empower the <br/> 4 Wins</h3>
                            <p>Our solution helps all 4 sides of the platform to be successful - Drivers, OEMs, Businesses, and us.</p>
                        </div>
                    </div>
                </div>
            </SectionSecond>
        )
    }
}

export default SectionTwo;