import { Component } from 'react';
import { SectionFirst } from './Styled';

export class SectionOne extends Component {
    render() {
        return (
            <SectionFirst>
                <div className="section-lining lining-before"><div className="dotted"></div></div>
                <div className="container skew-inner-container">
                    <h2 className="heading text text-center">Our mission is to connect a fragmented world, bringing to life a network of businesses, drivers and mobility providers.</h2>
                    <p className="description text-center">Since launching, 4.screen has been instrumental in defining the future of mobility and connected cars. We’ve built an amazing team of talented people who are committed to helping the our customers thrive.</p>
                </div>
            </SectionFirst>
        )
    }
}

export default SectionOne;