import React from 'react'
import { useParams } from 'react-router-dom';
import {SectionOne} from './SectionOne';

export function CareersDetails() {
    const { id } = useParams();
    console.log(id)
    return (
        <div>
            <SectionOne id={id}/>
        </div>
    )
}

export default CareersDetails;


