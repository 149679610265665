import { Component } from 'react';
import { BannerSection } from './Styled';
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';

export class Banner extends Component {
  render() {
    return (
      <BannerSection>
          <div className="container text-center banner">   
            <ScrollAnimation offset={0} duration={1} animateIn="animate__fadeInUp" animateOnce={true}>                            
            <h1>Blog</h1>
            </ScrollAnimation>             
          </div>
      </BannerSection>
    )
  }
}

export default Banner;