import styled from "styled-components";
import variables from '../../../Style-js/variable';

export const BannerSection = styled.section`
    margin: 80px 0 0 0;
    position: relative;
    // padding: 100px 0 180px;
    padding: 60px 0 150px;  
    background: #F0F7FF;
    position: relative;
    .container-wrapper {
        position: absolute;
        top: 18%;
        left: 0;
        width: 100%;
    }
    .container {
        max-width: ${variables.container_size};
    }
    .left {
        // width: 43%;
        text-align: center;
        h1 {
            font-weight: 600;
            font-size: ${variables.l_heading};
            line-height: 50px;
            // width: 94%;
        }
        p {
            font-weight: 500;
            font-size: ${variables.l_paragraph};
            margin: 6% 0 0 0;
            line-height: 36px;
        }
    }

    @media screen and (min-width: 1793px) {
        margin: 94px 0 0 0;
        padding: 100px 0 235px;
        .container {
            max-width: ${variables.container_size_1920};
        }   
        .left {
            h1 {
                line-height: 68px;
                // width: 98%;
                font-size: ${variables.l_heading_1920};
            }
            p {
                font-size: ${variables.l_paragraph_1920};
                line-height: 46px;
                margin: 7% 0 0 0;
            }
        } 
    }

    @media screen and (min-width: 3000px) {
        padding: 150px 0 330px;
    }

    @media screen and (max-width: 1792px) and (min-width: 1601px) {
        .container {
            max-width: ${variables.container_size_1792};
        } 
        .left {
            h1 {
                font-size: ${variables.l_heading_1792};
                line-height: 60px;
                // width: 97%;
            }
            p {
                font-size: 22px;
                line-height: 41px;
                font-size: ${variables.l_paragraph_1792};
            }
        }
    }

    @media screen and (max-width: 1441px) {
        .container {
            max-width: ${variables.container_size_1440};
        }
        .left {
            // width: 45%;
            h1 {
                font-size: ${variables.l_heading_1440};
                    line-height: 45px;
                    // width: 83%;
            }
            p {
                line-height: 32px;
                font-size: ${variables.l_paragraph_1440};
            }
        }
    }

    @media screen and (max-width: 1367px) {
        margin: 75px 0 0 0;
        .container {
            max-width: ${variables.container_size_1366};
        }
        .left {
            h1 {
                font-size: ${variables.l_heading_1366};
                line-height: 40px;
                // width: 81%;
            }
            p {
                font-size: ${variables.l_paragraph_1366};
                line-height: 30px;
            }
        }
    }

    @media screen and (max-width: 1100px) {
        .container {
            max-width: ${variables.container_size_1100};
        }
        .left {
            position: relative;
            z-index: 6;
            h1 {
                font-size: ${variables.l_heading_1100};
                line-height: 35px;
            }
            p {
                font-size: ${variables.l_paragraph_1100};
                line-height: 27px;
            }
        }
    }

    @media screen and (max-width: 900px) {
        .container {
            max-width: ${variables.container_size_800};
        }
        .left {
            h1 {
                font-size: ${variables.l_heading_800};
                line-height: 30px;
            }
            p {
                font-size: ${variables.l_paragraph_800};
                line-height: 25px;
            }
        }
    }

    @media screen and (max-width: 767px) {  
        padding: 30px 0 100px;      
        // padding: 50px 0 130px;
        .container {
            max-width: ${variables.container_size_500};
        }
        .left {
            width: 100%;
            max-width: 300px;
            text-align: center;
            margin: 0 auto;   
            br {
                display: none;
            }                   
            h1 {
                font-size: ${variables.l_heading_500};
                line-height: 25px;    
            }
            p {
                font-size: ${variables.l_paragraph_500};
            }
        }
    }
`;

export const SectionSecond = styled.section`
    margin-top: -84px;
    background: #fff;
    padding: 100px 0 300px;
    transform: skew(0,-5deg);
    .skew-inner-container {
        transform: skew(0,5deg);
    }
    .container {
        max-width: ${variables.container_size};
    } 
    .heading {
        h2 {
            font-weight: 500;
            font-size: ${variables.m_heading};
            margin: 0px 0 1.3%;
        }
        p {
            font-weight: 300;
            font-size: ${variables.l_paragraph};
            margin: 0 auto;
            width: 40%;
            line-height: 24px;
        }
    }
    .job-list-container {
        .job-filter-container {
            margin: 6% 0 0 0;
            .job-filter {
                background: #FFFFFF;
                border: 2px solid rgba(140, 211, 250, 0.52);
                border-radius: 15px;
                position: relative;
                margin: 0 0 0 15px;
                select {
                    position: relative;
                    z-index: 1;
                    background: none;
                    border: none;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    text-indent: 1px;
                    text-overflow: '';
                    font-size: ${variables.l_paragraph};                                        
                    font-weight: 500;
                    border-radius: 15px;
                    padding: 15px 50px 15px 20px;
                    &:focus {
                        outline: none;
                    }
                }
                img {
                    position: absolute;
                    right: 20px;
                    top: 43%;
                    z-index: 0;
                    width: 15px;
                }
            }
        }
        .no-result {
               padding-top: 60px;   
        }
        .job-list {
            list-style-type: none;
            padding: 0;
            
            li {
                margin: 60px 0 0 0;
                box-shadow: 0.4px 0.4px 1px 0.5px #00E1F8;
                border-radius: 40px;        
                padding: 30px 40px;        
                .job-name {
                    .image-container {
                        width: 74px;
                        height: 64px;
                        background: #FFFFFF;
                        border: 1px solid #00E1F8;
                        box-sizing: border-box;
                        border-radius: 15px;
                        margin: 0 20px 0 0;
                        img {
                            width: 57%;
                            position: relative;
                            top: 9px;
                        }
                    }
                    .name {
                        h2 {
                            font-size: ${variables.job_container_heading};
                            font-weight: 700;
                            color: #1F2121;
                            margin: 0;
                            letter-spacing: 0.5px;
                        }
                        p {
                            font-size: 16px;
                            color: #757575;
                            letter-spacing: 0.5px;
                            margin: 0;
                            .circle {
                                width: 6px;
                                height: 6px;
                                margin: 0 7px;
                                border-radius: 100%;
                                background: #757575;
                            }
                        }
                    }
                }
                .job-description {
                    h3 {
                        font-weight: 500;
                        font-size: ${variables.job_container_subheading};
                        margin: 20px 0;
                    }
                    p {
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 30px;
                        margin: 0 0 35px 0;
                    }
                }
                .btn {
                    background-color: #00E1F8;
                    border-radius: 40px;
                    padding: ${variables.btn_padding_y} ${variables.btn_padding_x};
                    font-weight: 600;
                    letter-spacing: 0.5px;
                    font-size: ${variables.btn_text};
                    color: #ffffff;
                    -webkit-transition: 0.3s;
                    transition: 0.3s;
                    svg {
                        margin: 0 0 0 30px;
                    }
                    &:hover {
                        background-color: #1F2121;
                    }
                }
            }
        }
    }

    .card-container-2 {
        width: 100%;
        position: absolute;
        z-index: 10;
        bottom: -370px;
        left: 0;
        .card {
            max-width: 870px;
            background: #F6FAFF;
            box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.14);
            border: none;
            border-radius: 25px;
            padding: 40px 70px;
            h2 {
                font-size: ${variables.m_heading2};
                width: 68%;
                margin: 0 auto;
            }
            p {
                font-size: ${variables.l_paragraph};
                font-weight: 400;
                margin: 5% 0 0 0;  
                a {
                    color: #000;
                    text-underline-offset: 5px;
                    text-decoration: underline;
                }              
            }            
        }
    }

    @media screen and (min-width: 1793px) {
        margin-top: -135px;
        padding-bottom: 400px;
        .container {
            max-width: ${variables.container_size_1920};
        } 
        .heading {
            h2 {
                font-size: ${variables.m_heading_1920};
                margin-bottom: 24px;
            }
            p {
                font-size: ${variables.l_paragraph_1920};
                line-height: 29px;
                width: 34%;
            }
        } 
        .job-list-container {
            .job-filter-container {
                .job-filter {
                    margin-left: 17px; 
                    select {
                        font-size: ${variables.l_paragraph_1920};
                        padding: 20px 60px 20px 25px;
                    }
                    img {
                        width: 16px;
                        right: 25px;
                    }
                }
            }
            .no-result {
                    padding-top: 80px;   
            }
            .job-list {
                li {
                    margin: 80px 0 0 0;
                    padding: 53px 61px;
                    .job-name {
                        .image-container {
                            width: 94px;
                            height: 84px;
                            margin: 0 34px 0 0;
                            img {
                                width: 60%;
                                top: 10px;
                            }
                        }
                        .name {
                            position: relative;
                            top: -9px;
                            h2 {
                                font-size: ${variables.job_container_heading_1920};
                            }
                            p {
                                font-size: 20px;
                                .circle {
                                    width: 8px;
                                    height: 8px;
                                    margin: 0 9px;
                                }
                            }
                        }
                    }
                    .job-description {
                        h3 {
                            font-size: ${variables.job_container_subheading_1920};
                        }
                        p {
                            font-size: 23px;
                            line-height: 39px;
                            margin: 0 0 40px 0;
                        }
                    }
                    .btn {
                        padding: ${variables.btn_padding_y_1920} ${variables.btn_padding_x_1920};
                        font-size: ${variables.btn_text_1920};
                    }
                }
            }
        }
        .card-container-2 {
            bottom: -470px;
            .card {
                box-sizing: border-box;
                max-width: 1054px;
                padding: 50px 36px;
                h2 {
                    font-size: ${variables.m_heading2_1920};
                }
                p{
                    font-size: ${variables.l_paragraph_1920};              
                }
            }
        }
    }

    @media screen and (min-width: 3000px) {
        margin: -200px 0 0;
    }

    @media screen and (max-width: 1792px) and (min-width: 1601px) {
        .container {
            max-width: ${variables.container_size_1792};
        }
        .heading {
            h2 {
                font-size: ${variables.m_heading_1792};
            }
            p {
                font-size: ${variables.l_paragraph_1792};
                line-height: 25px;
            }
        }
        .job-list-container {
            .job-filter-container {
                .job-filter {
                    margin-left: 17px; 
                    select {
                        font-size: ${variables.l_paragraph_1792};
                        padding: 17px 57px 17px 22px;
                    }
                    img {
                        width: 16px;
                        right: 22px;
                    }
                }
            }
            .no-result {
                    padding-top: 80px;    
            }
            .job-list {
                li {
                    margin: 70px 0 0 0;
                    padding: 43px 51px;
                    .job-name {
                        .image-container {
                            width: 84px;
                            height: 74px;
                            margin: 0 28px 0 0;
                            img {
                                width: 59%;
                                top: 10px;
                            }
                        }
                        .name {
                            position: relative;
                            top: -4px;
                            h2 {
                                font-size: ${variables.job_container_heading_1792};
                            }
                            p {
                                font-size: 18px;
                                .circle {
                                    width: 8px;
                                    height: 8px;
                                    margin: 0 9px;
                                }
                            }
                        }
                    }
                    .job-description {
                        h3 {
                            font-size: ${variables.job_container_subheading_1792};
                        }
                        p {
                            font-size: 20px;
                            line-height: 33px;
                        }
                    }
                    .btn {
                        padding: ${variables.btn_padding_y_1792} ${variables.btn_padding_x_1792};
                        font-size: ${variables.btn_text_1792};
                    }
                }
            }
        }
        .card-container-2 {
            bottom: -410px;
            .card {
                max-width: 900px;
                h2 {
                    font-size: ${variables.m_heading2_1792};
                    width: 77%;
                }   
                p {
                    font-size: ${variables.l_paragraph_1792};
                }             
            }
        }
    }

    @media screen and (max-width: 1441px) {
        .container {
            max-width: ${variables.container_size_1440};
        }
        .heading {
            h2 {
                font-size: ${variables.m_heading_1440};
            }
            p {
                font-size: ${variables.l_paragraph_1440};
                line-height: 21px;
            }
        }
        .job-list-container {
            .job-filter-container {
                .job-filter {
                    border-radius: 12px;
                    select {
                        font-size: ${variables.l_paragraph_1440}; 
                        padding: 13px 48px 13px 18px;
                    }
                    img {
                        right: 18px;
                    }
                }
            }
            .no-result {
                    padding-top: 50px;   
            }
            .job-list {
                li {
                    margin: 50px 0 0 0;
                    .job-name {
                        .image-container {
                            width: 70px;
                            height: 60px;
                        }
                        .name {
                            h2 {
                                font-size: ${variables.m_heading_1440};
                            }
                            p {
                                font-size: 15px;
                            }
                        }
                    }
                    .job-description {
                        h3 {
                            font-size: ${variables.s_heading_1440};
                        }
                        p {
                            font-size: 17px;
                            line-height: 28px;
                            margin: 0 0 30px 0;
                        }
                    }
                    .btn {                        
                        font-size: ${variables.btn_text_1440};
                    }
                }
            }
        }
        .card-container-2 {
            .card {
                max-width: 800px;
                h2 {
                    font-size: ${variables.m_heading2_1440};
                }   
                p {
                    font-size: ${variables.l_paragraph_1440};
                }             
            }
        }
    }

    @media screen and (max-width: 1367px) {
        .container {
            max-width: ${variables.container_size_1366};
        }
        .heading {
            h2 {
                font-size: ${variables.m_heading_1366};
            }
            p {
                font-size: ${variables.l_paragraph_1366};
                line-height: 20px;
            }
        }
        .job-list-container {
            .job-filter-container {
                .job-filter {
                    select {
                        font-size: ${variables.l_paragraph_1366}; 
                        padding: 11px 46px 11px 16px;
                    }
                    img {
                        width: 13px;
                        right: 16px;
                    }
                }
            }
            .no-result {
                    padding-top: 30px;
            }
            .job-list {
                li {
                    padding: 30px;
                    .job-name {
                        .image-container {
                            width: 65px;
                            height: 55px;
                            img {
                                top: 7px;
                            }
                        }
                        .name {
                            h2 {
                                font-size: ${variables.m_heading_1366};
                            }
                            p {
                                font-size: 13px;
                            }
                        }
                    }
                    .job-description {
                        h3 {
                            font-size: ${variables.s_heading_1366};
                            margin: 15px 0;
                        }
                        p {
                            font-size: 16px;
                            line-height: 27px;
                            margin: 0 0 20px 0;
                        }
                    }
                    .btn {
                        padding: ${variables.btn_padding_y_1366} ${variables.btn_padding_x_1366};
                        font-size: ${variables.btn_text_1366};
                    }
                }
            }
        }
        .card-container-2 {
            .card {
                max-width: 700px;
                padding: 30px 50px;
                h2 {
                    font-size: ${variables.m_heading2_1366};
                }   
                p {
                    font-size: ${variables.l_paragraph_1366};
                }             
            }
        }
    }

    @media screen and (max-width: 1100px) {
        padding: 100px 0 200px;
        .container {
            max-width: ${variables.container_size_1100};
        }
        .heading {
            h2 {
                font-size: ${variables.m_heading_1100};
            }
            p {
                font-size: ${variables.l_paragraph_1100};
            }
        }
        .job-list-container {
            .job-filter-container {
                .job-filter {
                    select {
                        font-size: ${variables.l_paragraph_1100};
                    }
                }
            }
            .no-result {
                    padding-top: 20px;    
            }
            .job-list {
                li {                    
                    .job-name {
                        .name {
                            h2 {
                                font-size: ${variables.m_heading_1100};
                            }
                        }
                    }
                    .job-description {
                        h3 {
                            font-size: ${variables.s_heading_1100};
                        }
                        p {
                            font-size: 15px;
                            line-height: 26px;
                        }
                    }
                    .btn {
                        font-size: ${variables.btn_text_1100};
                    }
                }
            }
        }
        .card-container-2 {
            bottom: -270px;
            .card {
                max-width: 600px;
                padding: 30px 40px;                
                h2 {
                    font-size: ${variables.m_heading2_1100};
                }   
                p {
                    font-size: ${variables.l_paragraph_1100};
                }            
            }
        }
    }

    @media screen and (max-width: 900px) {
        .container {
            max-width: ${variables.container_size_800};
        }
        .heading {
            h2 {
                font-size: ${variables.m_heading_800};
            }
            p {
                font-size: ${variables.l_paragraph_800};
                width: 60%;
            }
        }
        .job-list-container {
            .job-filter-container {
                justify-content: center;
                .job-filter {
                    border-radius: 7px;
                    select {
                        font-size: ${variables.l_paragraph_800};
                        padding: 5px 40px 5px 10px; 
                    }
                    img {
                        width: 10px;
                    }
                }
            }
            .no-result {
                    padding-top: 20px;   
            }
            .job-list {
                li {    
                    border-radius: 20px;   
                    padding: 20px;             
                    .job-name {
                        .image-container {
                            width: 60px;
                            height: 50px;
                            border-radius: 10px;
                        }
                        .name {
                            h2 {
                                font-size: ${variables.m_heading_800};
                            }
                            p {
                                font-size: 12px;
                                .circle {
                                    width: 5px;
                                    height: 5px;
                                    margin: 0 5px;
                                }
                            }
                        }
                    }
                    .job-description {
                        h3 {
                            font-size: ${variables.s_heading_800};
                        }
                        p {
                            font-size: 13px;
                            line-height: 22px;
                        }
                    }
                    .btn {
                        padding: ${variables.btn_padding_y_800} ${variables.btn_padding_x_800};
                    }
                }
            }
        }
        .card-container-2 {
            .card {
                max-width: 500px;
                h2 {
                    font-size: ${variables.m_heading2_800};
                }   
                p {
                    font-size: ${variables.l_paragraph_800};
                }           
            }
        }
    }

    @media screen and (max-width: 767px) {
        padding: 70px 0 200px;
        .container {
            max-width: ${variables.container_size_500};
        }
        .heading {
            h2 {
                font-size: ${variables.m_heading_500};
            }
            p {
                font-size: ${variables.l_paragraph_500};
                width: 90%;
                line-height: 16px;
            }
        }
        .job-list-container {
            .job-filter-container {
                .job-filter {
                    margin-top: 20px;
                    select {
                        font-size: ${variables.l_paragraph_500};
                    }
                }
            }
            .no-result {
                    padding-top: 30px;   
            }
            .job-list {
                li {  
                    margin: 30px 0 0 0;                  
                    .job-name {
                        .image-container {
                            margin-bottom: 10px;
                            margin-right: 10px;
                        }
                        .name {
                            h2 {
                                font-size: ${variables.m_heading_500};
                            }
                        }
                    }
                    .job-description {
                        h3 {
                            font-size: ${variables.s_heading_500};
                            margin: 10px 0;
                        }
                        p {
                            font-size: 12px;
                            line-height: 19px;
                        }
                    }
                    .btn {
                        font-size: ${variables.btn_text_500};
                    }
                }
            }
        }
        .card-container-2 {
            .card {
                max-width: 300px;
                padding: 30px;
                h2 {
                    font-size: ${variables.m_heading2_500};
                }   
                p {
                    font-size: ${variables.l_paragraph_500};
                }          
            }
        }
    }
`;
