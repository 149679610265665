import styled from "styled-components";
import variables from '../../../Style-js/variable';

export const BannerSection = styled.section`
    margin: 80px 0 0 0; 
    background: #464965;
    padding: 8% 0 9%;
    h1 {
        color: #fff;
        font-weight: 700;
        font-size: 58px;
    }
    h2 {
        font-weight: 400;
        font-size: 35px;
        letter-spacing: 0.5px;
        margin: 1% 0 0 0;
        color: rgba(255, 255, 255, 0.47);
    }
    .container {
        max-width: ${variables.container_size};
    } 

    @media screen and (min-width: 1793px) {
        margin: 94px 0 0 0;
        .container {
            max-width: ${variables.container_size_1920};
        }  
        h1 {
            font-size: 78px;
        }
        h2 {
            font-size: 48px;
        }
    }

    @media screen and (max-width: 1792px) and (min-width: 1601px) {
        .container {
            max-width: ${variables.container_size_1792};
        }
        h1 {
            font-size: 64px;
        }
        h2 {
            font-size: 39px;
        }
    }

    @media screen and (max-width: 1441px) {
        .container {
            max-width: ${variables.container_size_1440};
        }
        h1 {
            font-size: 49px;
        }
        h2 {
            font-size: 29.5px;
        }
    }

    @media screen and (max-width: 1367px) {
        margin: 75px 0 0 0;
        .container {
            max-width: ${variables.container_size_1366};
        }
        h1 {
            font-size: 45px;
        }
        h2 {
            font-size: 27px;
        }
    }

    @media screen and (max-width: 1100px) {
        .container {
            max-width: ${variables.container_size_1100};
        }
        h1 {
            font-size: 40px;
        }
        h2 {
            font-size: 24px;
        }
    }

    @media screen and (max-width: 900px) {
        .container {
            max-width: ${variables.container_size_800};
        }
        h1 {
            font-size: 35px;
        }
        h2 {
            font-size: 20px;
        }
    }

    @media screen and (max-width: 767px) {
        padding: 8% 0 25%;
        .container {
            max-width: ${variables.container_size_500};
        }
        h1 {
            font-size: 30px;
        }
        h2 {
            font-size: 18px;
        }
    }
`;

export const SectionFirst = styled.section`
    margin: -5% 0 0 0; 
    padding: 150px 0 200px;
    background: #F6FAFF;
    transform: skew(0deg,-5deg);
    .section-lining {
        position: absolute;
        width: 35%;
        .dotted {
            width: 100%;
            border-top: 2.5px dashed white;
        }
    }
    .lining-after {
        bottom: 0;
        right: 0;
        background-color: rgba(0,0,0,0.67);
        padding: 10px 0px 10px 30px;
    }
    .skew-inner-container {
        transform: skew(0deg,5deg);
    }
    .container {
        max-width: ${variables.container_size};
    } 
    h3 {
        font-weight: bold;
        font-size: 49px;
        margin: 0 0 2% 0;
    }
    p, li {
        font-weight: 400;
        font-size: 20px;
        opacity: 0.5;
    }
    .text-section {
        margin: 5% 0 0 0;
        &:first-child {
            margin: 0;
        }
    }
    p {
        line-height: 35px;
        margin: 0 0 1% 0;
    }
    .mustnotlist {
        p {
            opacity: 1;
            text-decoration: underline;
        }
        li {
            img {
                position: relative;
                width: 20px;
                top: 7px;
                margin: 0 30px 0 0;
            }
            margin: 0 0 10px 0;
        }
    }
    .security-section {
        margin: 7% 0 0 0;
    }

    @media screen and (min-width: 1793px) {
        .container {
            max-width: ${variables.container_size_1920};
        }  
        h3 {
            font-size: 64px;
        }
        p, li {
            font-size: 25px;
        }
        p {
            line-height: 45px;
        }
        .mustnotlist {            
            li {
                margin: 0 0 20px 0;
                img {
                    width: 30px;
                    top: 6px;
                    margin: 0px 50px 0 0;
                }
            }
        }
    }

    @media screen and (max-width: 1792px) and (min-width: 1601px) {
        .container {
            max-width: ${variables.container_size_1792};
        }
        h3 {
            font-size: 57px;
        }
        p, li {
            font-size: 22px;
        }
        p {
            line-height: 40px;
        }
        .mustnotlist {            
            li {
                margin: 0 0 15px 0;
                img {
                    width: 25px;
                    top: 6px;
                    margin: 0px 40px 0 0;
                }
            }
        }
    }

    @media screen and (max-width: 1441px) {
        .container {
            max-width: ${variables.container_size_1440};
        }
        h3 {
            font-size: 45px;
        }
        p, li {
            // font-size: 29px;
        }
        p {
            // line-height: 38px;
        }
        .mustnotlist {            
            li {
                // margin: 0 0 15px 0;
                img {
                    // width: 23px;
                    // top: 12px;
                    // margin: 0px 33px 0 0;
                }
            }
        }
    }

    @media screen and (max-width: 1367px) {
        .container {
            max-width: ${variables.container_size_1366};
        }
        h3 {
            font-size: 41px;
        }
        p, li {
            font-size: 18px;
        }
        p {
            line-height: 30px;
            margin: 0 0 1.5% 0;
        }
        .mustnotlist {            
            li {
                img {
                    width: 17px;
                    margin: 0px 25px 0 0;
                    top: 5px;
                }
            }
        }
    }

    @media screen and (max-width: 1100px) {
        padding: 100px 0 150px;
        .container {
            max-width: ${variables.container_size_1100};
        }
        h3 {
            font-size: 36px;
        }
        // p, li {
        //     font-size: 24px;
        // }
        // p {
        //     line-height: 33px;
        // }
        // .mustnotlist {            
        //     li {
        //         margin: 0px 0 10px 0;
        //         img {
        //             width: 18px;
        //             margin: 0px 26px 0 0;
        //             top: 9px;
        //         }
        //     }
        // }
    }

    @media screen and (max-width: 900px) {
        .container {
            max-width: ${variables.container_size_800};
        }
        h3 {
            font-size: 30px;
        }
        p, li {
            font-size: 16px;
        }
        p {
            line-height: 25px;
            margin-bottom: 2%;
        }
        .mustnotlist {
            li {
                img {
                    top: 4px;
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        padding: 70px 0 100px;
        .container {
            max-width: ${variables.container_size_500};
        }
        h3 {
            font-size: 25px;
        }
        p, li {
            font-size: 15px;
        }
        p {
            line-height: 23px;
            margin-bottom: 3%;
        }
        .mustnotlist {
            ul {
                padding: 0;
            }
            li {
                img {
                    width: 15px;
                    margin: 0px 14px 0 0;
                    // top: 5px;
                }
            }
        }
    }
`;
