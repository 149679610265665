import styled from "styled-components";
import variables from '../../../Style-js/variable';

export const BannerSection = styled.section`
    margin: 80px 0 0 0;
    padding: 0 0 0;
    .container {
        max-width: ${variables.container_size};
    }
    .back {
        margin: 0;
        padding: 50px 0;
        a {
            // color: #1F2121;
            color: #00E1F8;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 0.5px;
            svg {
                margin-right: 10px;
                width: 19px;
                position: relative;
                top: -2px;
            }
        }
    }
    h1 {
        text-transform: capitalize;
        font-size: 38px;
        line-height: 50px;
        padding-bottom: 15px;
        span {
            font-weight: 600;
        }
    }
    p {
        font-size: 18px;
        letter-spacing: 4px;
        margin: 2% 0;
        .bold {
            font-weight: 700;
        }
    }
    img {
        width: 100%;
        border-radius: 25px;
    }     

    @media screen and (min-width: 1793px) {
        margin: 94px 0 0 0;
        .container {
            max-width: ${variables.container_size_1920};
        } 
        .back {
            a {
                font-size: 20px;
            }
        }
        h1 {
            font-size: 50px;
            line-height: 68px;
            padding-bottom: 15px;
        } 
        p {
            font-size: 22px;
        }
    }

    @media screen and (max-width: 1792px) and (min-width: 1601px) {
        .container {
            max-width: ${variables.container_size_1792};
        }
        .back {
            a {
                font-size: 20px;
            }
        }
        h1 {
            font-size: 42px;
            line-height: 55px;
            padding-bottom: 15px;
        } 
        p {
            font-size: 20px;            
        }
    }

    @media screen and (max-width: 1441px) {
        .container {
            max-width: ${variables.container_size_1440};
        }
        h1 {
            font-size: 35px;
            line-height: 45px;
            padding-bottom: 15px;
        } 
        p {
            font-size: 16px;            
        }
    }

    @media screen and (max-width: 1367px) {
        margin: 75px 0 0 0;
        .container {
            max-width: ${variables.container_size_1366};
        }
        .back {
            a {
                font-size: 17px;
            }
        }
        h1 {
            font-size: 32px;
            line-height: 42px;
            padding-bottom: 15px;
        } 
        p {
            font-size: 14px;            
        }
    }

    @media screen and (max-width: 1100px) {
        .container {
            max-width: ${variables.container_size_1100};
        }
        .back {
            padding: 30px 0;
            a {
                font-size: 16px;
            }
        }
        h1 {
            font-size: 28px;
        } 
        p {
            font-size: 13px;            
        }
    }

    @media screen and (max-width: 900px) {
        .container {
            max-width: ${variables.container_size_800};
        }
        h1 {
            font-size: 25px;
            padding-bottom: 10px;
        } 
        p {
            font-size: 12px;            
        }
    }

    @media screen and (max-width: 767px) {
        .container {
            max-width: ${variables.container_size_500};
        }
        .back {
            a {
                font-size: 15px;
            }
        }
    }
`;

export const SectionFirst = styled.section`
    margin: 0 0 0 0;
    padding: 0 0 150px 0;
    .container {
        max-width: ${variables.container_size};
    } 
    .writtenby {
        font-size: 18px;
        letter-spacing: 4px;
        margin: 6% 0 0;
        .bold {
            font-weight: 700;
        }
    }
    .content {
        p,ul {
            font-size: 20px;
            font-weight: 300;
            margin: 0 0 0;
            // margin: 4% 0 0 0;
            line-height: 40px;
            .bold {
                font-weight: 600;
            }
        }
        ul li {
            margin-bottom: 10px;
        }
        h3 {
            margin: 4% 0 0 0;
            font-weight: 600;
            color: ${variables.cyan};
            font-size: 35px;
        }
    } 

    @media screen and (min-width: 1793px) {
        .container {
            max-width: ${variables.container_size_1920};
        }  
        .writtenby {
            font-size: 22px;
        }
        .content {
            p,ul {
                font-size: 28px;
                line-height: 56px;
            }
            h3 {
                font-size: 46px;
            }
        }
    }

    @media screen and (max-width: 1792px) and (min-width: 1601px) {
        .container {
            max-width: ${variables.container_size_1792};
        }
        .writtenby {
            font-size: 20px;
        }
        .content {
            p,ul {
                font-size: 24px;
                line-height: 46px;
            }
            h3 {
                font-size: 40px;
            }
        }
    }

    @media screen and (max-width: 1441px) {
        .container {
            max-width: ${variables.container_size_1440};
        }
        .writtenby {
            font-size: 16px;
        }
        .content {
            p,ul {
                font-size: 18px;
                line-height: 35px;
            }
            h3 {
                font-size: 32px;
            }
        }
    }

    @media screen and (max-width: 1367px) {
        .container {
            max-width: ${variables.container_size_1366};
        }
        .writtenby {
            font-size: 14px;
        }
        .content {
            p,ul {
                font-size: 16px;
                line-height: 33px;
            }
            h3 {
                font-size: 28px;
            }
        }
    }

    @media screen and (max-width: 1100px) {
        padding: 0 0 100px 0;
        .container {
            max-width: ${variables.container_size_1100};
        }
    }

    @media screen and (max-width: 900px) {
        .container {
            max-width: ${variables.container_size_800};
        }
    }

    @media screen and (max-width: 767px) {
        .container {
            max-width: ${variables.container_size_500};
        }
        .writtenby {
            font-size: 13px;
        }
        .content {
            p,ul {
                font-size: 15px;
                line-height: 30px;
            }
            ul {
                padding: 0;
            }
            h3 {
                font-size: 20px;
            }
        } 
    }
`;

export const SectionSecond = styled.section`
    background: #F6FAFF;
    padding: 150px 0 300px;
    position: relative;
    transform: skew(0deg, -5deg);
    .skew-inner-container {
        transform: skew(0deg, 5deg);
    }
    .section-lining {        
        position: absolute;
        width: 35%;        
        .dotted {
            width: 100%;
            border-top: 2.5px dashed white;
        }
    }
    .lining-after {
        background: rgba(0, 0, 0, 0.67);
        bottom: 0;
        right: 0;
        padding: 10px 0px 10px 30px;
    }
    .container {
        max-width: ${variables.container_size};
    }
    .heading {
        font-size: 30px;
        font-weight: 400;
        .bold {
            font-weight: 700;
        }
    }     
    .blog-list {
        margin: 5% 0 0 0; 
        padding: 0;
        list-style-type: none;   
        // justify-content: space-between;             
        li {
            width: 29%;
            background: #FFFFFF;
            box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.08);
            border-radius: 20px;
            margin-right: 82px;
            .image-container {
                height: 250px;
                margin: 2%;
                position: relative;
                border-radius: 20px;
                background-size: auto 100%;
                background-position: center top;
            }
            .content {
                padding: 7% 7% 7%;
            }
            h3 {
                font-size: 20px;
                font-weight: 700;
                margin: 0 0 4%;
            }
            p {
                font-size: ${variables.btn_text};
                font-weight: 300;
                line-height: 26px;
                margin: 0 0 6%;
            }
            .btn {
                font-size: ${variables.btn_text};
                font-weight: 500;
                height: 30px;
                width: 130px;
                border: 1px solid #000000;
                box-sizing: border-box;
                border-radius: 5px;
                padding: 0;
                &:hover {
                    color: #fff;
                    background-color: #00E1F8;
                    border: none;
                }
            }
            &:last-child {
                margin-right: 0; 
            }
            &.box-lg-screen-spacing {
                margin: 0 0 5% 0;
            }
        }
    }

    @media screen and (min-width: 1793px) {
        .container {
            max-width: ${variables.container_size_1920};
        } 
        .heading {
            font-size: 40px;
        } 
        .blog-list {
            li {
                margin-right: 108px;
                border-radius: 25px;
                .image-container {
                    height: 280px;
                    border-radius: 25px;
                }
                h3 {
                    font-size: 26px;
                }
                p {
                    font-size: ${variables.btn_text_1920};
                    line-height: 32px;
                }
                .btn {
                    font-size: ${variables.btn_text_1920};
                    height: 38px;
                    width: 158px;
                }
            }
        } 
    }

    @media screen and (max-width: 1792px) and (min-width: 1601px) {
        .container {
            max-width: ${variables.container_size_1792};
        }
        .heading {
            font-size: 35px;
        } 
        .blog-list {
            li {
                margin-right: 95px;
                border-radius: 25px;
                .image-container {
                    height: 270px;
                    border-radius: 25px;
                }
                h3 {
                    font-size: 26px;
                }
                p {
                    font-size: ${variables.btn_text_1792};
                    line-height: 30px;
                }
                .btn {
                    font-size: ${variables.btn_text_1792};
                    height: 35px;
                    width: 140px;
                }
            }
        } 
    }

    @media screen and (max-width: 1441px) {
        .container {
            max-width: ${variables.container_size_1440};
        }
        .heading {
            font-size: 27px;
        } 
        .blog-list {
            li {
                margin-right: 76px;
                .image-container {
                    height: 240px;
                }
                h3 {
                    font-size: 19px;
                }
                p {
                    line-height: 25px;
                    font-size: ${variables.btn_text_1440};
                }
                .btn {
                    height: 27px;
                    width: 120px;
                    font-size: ${variables.btn_text_1440};
                }
            }
        }
    }

    @media screen and (max-width: 1367px) {
        .container {
            max-width: ${variables.container_size_1366};
        }
        .heading {
            font-size: 25px;
        } 
        .blog-list {
            li {
                margin-right: 69px;
                border-radius: 15px;
                .image-container {
                    height: 230px;
                    border-radius: 15px;
                }
                h3 {
                    font-size: 17px;
                }
                p {
                    line-height: 24px;
                    font-size: ${variables.btn_text_1366};
                }
                .btn {
                    height: 25px;
                    width: 110px;
                    font-size: ${variables.btn_text_1366};
                }
            }
        }
    }

    @media screen and (max-width: 1100px) {
        padding: 100px 0 200px;
        .container {
            max-width: ${variables.container_size_1100};
        }
        .blog-list {
            li {
                width: 31%;
                margin-right: 33px;
                .image-container {
                    height: 220px;
                }
            }
        }
    }

    @media screen and (max-width: 900px) {
        .container {
            max-width: ${variables.container_size_800};
        }
        .heading {
            font-size: 20px;
        }
        .blog-list {
            margin: 0 auto 0;
            width: 100%;
            li {
                margin-top: 50px;
                margin-right: 39px;
                width: 47%;
                .image-container {
                    height: 200px;
                }
                h3 {
                    font-size: 16px;
                }
                p {                    
                    font-size: ${variables.btn_text_1100};
                    line-height: 22px;
                }
                .btn {
                    height: 23px;
                    width: 100px;
                    font-size: ${variables.btn_text_1100};
                }
                &:nth-child(even) {
                    margin-right: 0;
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        padding: 70px 0 200px;
        .container {
            max-width: ${variables.container_size_500};
        }
        .heading {
            font-size: 18px;
            text-align: center;
        }
        .blog-list {
            max-width: 300px;
            li {
                margin-top: 30px !important;
                margin-right: 0;
                width: 100%;
                .image-container {
                    height: 180px;
                }
                h3 {
                    font-size: 15px;
                }
                p {
                    line-height: 20px;
                    font-size: ${variables.btn_text_500};
                }
                .btn {
                    border-radius: 3px;
                    height: 20px;
                    width: 90px;
                    font-size: ${variables.btn_text_500};
                }
            }
        }
    }
`;

