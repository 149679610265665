import { useParams } from 'react-router-dom';
import { SectionOne } from './SectionOne';

export function MessageSent() {
    const { message } = useParams();

    return (
        <div>
            <SectionOne param={message} />
        </div>
    )

}

export default MessageSent;
