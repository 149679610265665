import styled from "styled-components";
import variables from '../../../Style-js/variable';
import banner from '../../../assets/About_Us/1.webp';

export const BannerSection = styled.section`    
    margin-top: 80px;
    overflow: hidden;
    padding: 150px 0 calc(5% + 150px);
    position: relative;
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${banner});
    background-size: cover;    
    background-position: center 71%;
    .container {
        position: relative;
        height: 100%;
        max-width: ${variables.container_size};
    } 
    .banner {
        width: 100%;
        color: white;
        h1 {
            font-weight: 600;
            letter-spacing: 0.5px;
            font-size: ${variables.l_heading};
        }
        h2 {            
            font-weight: 500;
            letter-spacing: 1px;
            font-size: ${variables.s_heading};
        }
    }

    @media screen and (min-width: 1793px) {        
        margin-top: 94px;
        .container {
            max-width: ${variables.container_size_1920};
        }  
        .banner {
            h1 {
                font-size: ${variables.l_heading_1920};
            }
            h2 {
                font-size: ${variables.s_heading_1920};
            }
        }
    }

    @media screen and (max-width: 1792px) and (min-width: 1601px) {
        .container {
            max-width: ${variables.container_size_1792};
        }
        .banner {
            h1 {
                font-size: ${variables.l_heading_1792};
            }
            h2 {
                font-size: ${variables.s_heading_1792};
            }
        } 
    }

    @media screen and (max-width: 1441px) {
        .container {
            max-width: ${variables.container_size_1440};
        }
        .banner {
            h1 {
                font-size: ${variables.l_heading_1440};
            }
            h2 {
                font-size: ${variables.s_heading_1440};
            }
        } 
    }

    @media screen and (max-width: 1367px) {        
        margin-top: 75px;
        .container {
            max-width: ${variables.container_size_1366};
        }
        .banner {
            h1 {
                font-size: ${variables.l_heading_1366};
            }
            h2 {
                font-size: ${variables.s_heading_1366};
            }
        }
    }

    @media screen and (max-width: 1100px) {  
        padding: 50px 0 calc(5% + 100px);      
        .container {
            max-width: ${variables.container_size_1100};
        }
        .banner {
            h1 {
                font-size: ${variables.l_heading_1100};
            }
            h2 {
                font-size: ${variables.s_heading_1100};
            }
        }
    }

    @media screen and (max-width: 900px) {
        .container {
            max-width: ${variables.container_size_800};
        }
        .banner {
            h1 {
                font-size: ${variables.l_heading_800};
            }
            h2 {
                font-size: ${variables.s_heading_800};
            }
        }
    }

    @media screen and (max-width: 767px) {
        height: auto;
        .container {
            max-width: ${variables.container_size_500};
        }
        .banner {
            h1 {
                font-size: ${variables.l_heading_500};
            }
            h2 {
                font-size: ${variables.s_heading_500};
            }
        }
    }
`;

export const SectionFirst = styled.section`
    margin: -84px 0 0 0;
    position: relative;
    z-index: 1;
    overflow: hidden;
    transform: skew(0deg, -5deg);
    padding: 100px 0 180px;
    background: #fff;
    .skew-inner-container {
        transform: skew(0deg, 5deg);
    }
    .section-lining {
        position: absolute;
        width: 35%;
        z-index: 3;
        .dotted {
            width: 100%;
            border-top: 2.5px dashed white;
        }
    }
    .lining-before {
        top: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.67);
        padding: 10px 0px 10px 30px;
    }
    .container {
        max-width: ${variables.container_size};
    }
    .about-row {
        margin-top: 8%;
    }
    .box {
        width: 200px;
        font-size: 35px;
        line-height: 40px;
        background-color: #91F0FC;
        font-weight: 500;
        float: left;
        border-radius: 20px;
        padding: 25px;
        margin-bottom: 30px;
        box-shadow: rgba(0, 0, 0, 0.3) 0.4px 0.4px 30px -4px;
    }
    .text {        
        clear: left;
        font-size: 40px;
        line-height: 50px;
        margin: 0 auto;
        width: 58%;
    }
    .description {
        margin: 2% auto 0;
        font-size: 20px;
        width: 58%;
    }
    
    @media screen and (min-width: 1793px) {
        margin-top: -135px;
        padding: 100px 0 235px;        
        .container {
            max-width: ${variables.container_size_1920};
        } 
        .box {
            width: 250px;
            font-weight: 500;
            font-size: 48px;
            line-height: 55px;
        }
        .text {
            font-size: 55px;
            line-height: 67px;            
        }
        .description {
            font-size: 30px;            
        }
    }

    @media screen and (min-width: 3000px) {
        margin: -200px 0 0;
        padding: 100px 0 300px;
    }

    @media screen and (max-width: 1792px) and (min-width: 1601px) {
        .container {
            max-width: ${variables.container_size_1792};
        }
        .box {
            width: 220px;
            font-size: 40px;
            line-height: 45px;
        }
        .text {
            font-size: 45px;
            line-height: 55px;
        }
    }

    @media screen and (max-width: 1441px) {
        .container {
            max-width: ${variables.container_size_1440};
        } 
        .box {
            width: 190px;
            font-size: 33px;
            line-height: 37px;
        }    
        .text {
            font-size: 38px;
            line-height: 48px;
        }
        .description {
            font-size: 20px;
        }
    }

    @media screen and (max-width: 1367px) {
        .container {
            max-width: ${variables.container_size_1366};
        }
        .box {
            width: 180px;
            font-size: 30px;
            line-height: 34px;
            padding: 20px;
        }
        .text {
            font-size: 35px;
            line-height: 45px;
        }
        .description {
            font-size: 18px;
        }
    }

    @media screen and (max-width: 1100px) {
        .container {
            max-width: ${variables.container_size_1100};
        }
        .box {
            width: 170px;
            font-size: 28px;
            line-height: 32px;
        }
        .text {
            font-size: 33px;
            line-height: 43px;
        }
    }

    @media screen and (max-width: 900px) {
        padding: 100px 0;
        .container {
            max-width: ${variables.container_size_800};
        }
        .box {
            width: 160px;
            font-size: 25px;
            line-height: 29px;
        }
        .text {
            font-size: 30px;
            line-height: 40px;
        }
        .description {
            font-size: 16px;
        }
    }

    @media screen and (max-width: 767px) {
        padding: 50px 0 calc(84px + 30px);
        .section-lining {
            width: 50%;
        }
        .container {
            max-width: ${variables.container_size_500};
        }
        .box {
            float: unset;
            margin: 0 auto 20px; 
        }
        .text {
            font-size: 20px;
            line-height: 30px;
            width: 100%;
        }
        .description {
            width: 100%;
            font-size: 13px;
            margin-top: 18px;
        }
        .about-row:first-child {
            margin: 0;
        }
    }
`;

export const SectionSecond = styled.section`
    padding: 100px 0 230px;
    transform: skew(0deg, -5deg);
    margin: -84px 0 0 0;
    position: relative;
    z-index: 2;
    .skew-inner-container {
        transform: skew(0deg, 5deg);
    }
    .section-lining {
        position: absolute;
        width: 35%;
        .dotted {
            width: 100%;
            border-top: 2.5px dashed white;
        }
    }
    .lining-before {
        top: 0;
        left: 0;
        background: #00E1F8;
        padding: 10px 30px 10px 0px;        
    }
    .container {
        max-width: ${variables.container_size};
    } 
    h2 {
        font-weight: 500;
        font-size: ${variables.m_heading};
        margin: 0px 0 1.3%;
    }
    .sub-heading {
        font-weight: 300;
        font-size: ${variables.l_paragraph};
        margin: 0 auto;
        width: 40%;
        line-height: 24px;
    }
    .box-container {
        justify-content: space-between;
        width: 100%;
        margin: 10% auto 0;
        .box {
            background: #FFFFFF;
            box-shadow: rgba(0,0,0,0.3) 0.4px 0.4px 30px -4px;     
            border-radius: 15px;
            width: 22%;
            padding: 3%;
            transition: 300ms;
            text-align: center;
            .image-container {                
                margin: 0 auto;
                img {
                    width: 95px;
                    border-radius: 15px;
                    background: #fff;
                }
            }
            h3 {
                font-weight: 600;
                line-height: 20px;
                margin: 12% 0 5.5%;
                font-size: ${variables.l_paragraph};
            }
            p {
                margin: 0;
                line-height: 18px;
                font-size: ${variables.btn_text};
            }
            &:hover {
                background: #91F0FC;
            }
        }
    }

    @media screen and (min-width: 1793px) {
        margin-top: -135px;
        padding: 150px 0 270px;    
        .container {
            max-width: ${variables.container_size_1920};
        }  
        h2 {
            font-size: ${variables.m_heading_1920};
            margin-bottom: 24px;
        }
        .sub-heading {
            font-size: ${variables.l_paragraph_1920};
            line-height: 29px;
            width: 34%;
        }
        .box-container {
            // width: 91%;
            .box {
                .image-container {
                    img {
                        width: 120px;
                    }
                }
                h3 {
                    font-size: ${variables.l_paragraph_1920};
                    line-height: 25px;
                    margin-bottom: 6%;
                }
                p {
                    font-size: ${variables.btn_text_1920};
                    line-height: 22px;
                }
            }
        }
    }

    @media screen and (min-width: 3000px) {
        margin-top: -200px;
        padding: 150px 0 180px;
    }

    @media screen and (max-width: 1792px) and (min-width: 1601px) {
        padding: 150px 0 250px;  
        .container {
            max-width: ${variables.container_size_1792};
        }
        h2 {
            font-size: ${variables.m_heading_1792};
        }
        .sub-heading {
            font-size: ${variables.l_paragraph_1792};
            line-height: 25px;
        }
        .box-container {
            // width: 94%;
            .box {
                padding: 2.5%;
                .image-container {
                    img {
                        width: 110px;
                    }
                }
                h3 {
                    font-size: ${variables.l_paragraph_1792};
                    line-height: 23px;
                }
                p {
                    font-size: ${variables.btn_text_1792};
                    line-height: 20px;
                }
            }
        }
    }

    @media screen and (max-width: 1441px) {
        .container {
            max-width: ${variables.container_size_1440};
        }
        h2 {
            font-size: ${variables.m_heading_1440};
        }
        .sub-heading {
            font-size: ${variables.l_paragraph_1440};
            line-height: 21px;
        }
        .box-container {
            .box {
                h3 {
                    line-height: 19px;
                    font-size: ${variables.l_paragraph_1440};
                }
                p {
                    line-height: 17px;
                    font-size: ${variables.btn_text_1440};
                }
            }
        }
    }

    @media screen and (max-width: 1367px) {
        padding: 150px 0 200px;  
        .container {
            max-width: ${variables.container_size_1366};
        }
        h2 {
            font-size: ${variables.m_heading_1366};
        }
        .sub-heading {
            font-size: ${variables.l_paragraph_1366};
            line-height: 20px;
        }
        .box-container {
            .box {
                .image-container {
                    img {
                        width: 85px;
                    }
                }
                h3 {
                    line-height: 18px;
                    font-size: ${variables.l_paragraph_1366};
                }
                p {
                    line-height: 16px; 
                    font-size: ${variables.btn_text_1366};
                }
            }
        }
    }

    @media screen and (max-width: 1100px) {
        padding: 100px 0 130px;
        .container {
            max-width: ${variables.container_size_1100};
        }
        h2 {
            font-size: ${variables.m_heading_1100};
        }
        .sub-heading {
            font-size: ${variables.l_paragraph_1100};
            line-height: 19px;
        }
    }

    @media screen and (max-width: 900px) {
        padding: 100px 0;
        .container {
            max-width: ${variables.container_size_800};
        }
        h2 {
            font-size: ${variables.m_heading_800};
        }
        .sub-heading {
            font-size: ${variables.l_paragraph_800};
            width: 60%;
        }
        .box-container {
            margin-top: 0;
            max-width: 600px;
            .box {
                width: 47%;                
                margin-top: 50px;
                .image-container {
                    img {
                        width: 80px;
                    }
                }
                h3 {
                    margin: 5% 0;
                    line-height: 17px;
                    font-size: ${variables.l_paragraph_800};
                }
                p {
                    line-height: 18px;
                    font-size: ${variables.btn_text_1100};
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        padding: 70px 0 100px;        
        .section-lining {
            width: 50%;
        }
        .container {
            max-width: ${variables.container_size_500};
        }
        h2 {
            font-size: ${variables.m_heading_500};
        }
        .sub-heading {
            font-size: ${variables.l_paragraph_500};
            line-height: 16px;
            width: 100%;
        }
        .box-container {
            max-width: 300px;
            .box {
                width: 100%;
                margin-top: 30px;
                padding: 20px;
                h3 {
                    margin: 4% 0;
                    line-height: 16px;
                    font-size: ${variables.l_paragraph_500};
                }
                p {
                    line-height: 17px;
                    font-size: ${variables.btn_text_500};
                }
            }
        }        
    }
`;

export const SectionThird = styled.section`
    position: relative;
    .container {
        max-width: ${variables.container_size};
    }

    .card-container-2 {
        width: 100%;
        position: absolute;
        z-index: 10;
        bottom: -80px;
        left: 0;
        .card {
            max-width: 870px;
            background: #F6FAFF;
            box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.14);
            border: none;
            border-radius: 25px;
            padding: 40px 70px;
            h2 {
                font-size: ${variables.m_heading2};
                margin: auto;
            }
            p {
                font-size: ${variables.l_paragraph};
                font-weight: 400;
                margin: 15px 0 0 0;                
            }
            form {
                margin: 3% 0 0 0;
                .btn {
                    padding: ${variables.btn_padding_y} ${variables.btn_padding_x};
                    border-radius: 40px;
                    font-weight: 600;
                    font-size: ${variables.btn_text};
                    color: ${variables.white};
                    background: ${variables.cyan};
                    transition: 0.3s;  
                    svg {
                        margin: 0 0 0 50px;
                    }
                    &:hover {
                        background-color: #1F2121;;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 1793px) {
        padding: 20px 0 0 0;
        .container {
            max-width: ${variables.container_size_1920};
        } 
        .card-container-2 {
            bottom: -120px;
            .card {
                box-sizing: border-box;
                max-width: 1054px;
                padding: 50px 36px;
                h2 {
                    font-size: ${variables.m_heading2_1920};
                }
                p{
                    font-size: ${variables.l_paragraph_1920};              
                }
                form {
                    input {
                        max-width: 492px;
                        height: 52px;       
                    }
                    .btn {
                        padding: ${variables.btn_padding_y_1920} ${variables.btn_padding_x_1920};                        
                        font-size: ${variables.btn_text_1920};                        
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1792px) and (min-width: 1601px) {
        padding: 20px 0 0 0;
        .container {
            max-width: ${variables.container_size_1792};
        }
        .card-container-2 {
            bottom: -100px;
            .card {
                max-width: 900px;
                // min-height: 340px;
                h2 {
                    font-size: ${variables.m_heading2_1792};
                    // width: 77%;
                }   
                p {
                    font-size: ${variables.l_paragraph_1792};
                }   
                .btn {
                    padding: ${variables.btn_padding_y_1792} ${variables.btn_padding_x_1792};
                    font-size: ${variables.btn_text_1792};
                }           
            }
        }
    }

    @media screen and (max-width: 1441px) {
        .container {
            max-width: ${variables.container_size_1440};
        }
        h2 {
            font-size: 38px;
        }
        .btn {
            width: 160px;
            height: 35px;
            font-size: 14px;
            padding-top: 7px;
        }
        .card-container-2 {
            .card {
                max-width: 800px;
                h2 {
                    font-size: ${variables.m_heading2_1440};
                }   
                p {
                    font-size: ${variables.l_paragraph_1440};
                }             
            }
        }
    }

    @media screen and (max-width: 1367px) {
        .container {
            max-width: ${variables.container_size_1366};
        }
        .card-container-2 {
            padding: 0;
            .card {
                max-width: 700px;
                padding: 30px 50px;
                h2 {
                    font-size: ${variables.m_heading2_1366};
                }   
                p {
                    font-size: ${variables.l_paragraph_1366};
                }     
                .btn {
                    font-size: ${variables.btn_text_1366};
                    padding: ${variables.btn_padding_y_1366} ${variables.btn_padding_x_1366};
                }        
            }
        }
    }

    @media screen and (max-width: 1100px) {
        // padding: 100px 0 150px;
        padding: 40px 0 0;
        .container {
            max-width: ${variables.container_size_1100};
        }
        h2 {
            font-size: 28px;
        }
        .btn {
            width: 150px;
            height: 32px;
            font-size: 12px;
        }
        .card-container-2 {
            bottom: -70px;
            .card {
                max-width: 600px;
                padding: 30px 40px;                
                h2 {
                    font-size: ${variables.m_heading2_1100};
                }   
                p {
                    font-size: ${variables.l_paragraph_1100};
                }  
                form {
                    .btn {
                        font-size: ${variables.btn_text_1100};
                    }
                }           
            }
        }
    }

    @media screen and (max-width: 900px) {
        .container {
            max-width: ${variables.container_size_800};
        }
        h2 {
            font-size: 25px;
        }
        .card-container-2 {
            .card {
                max-width: 500px;
                h2 {
                    font-size: ${variables.m_heading2_800};
                }   
                p {
                    font-size: ${variables.l_paragraph_800};
                }  
                form {
                    .btn {
                        padding: ${variables.btn_padding_y_800} ${variables.btn_padding_x_800};
                    }
                }           
            }
        }
    }

    @media screen and (max-width: 767px) {
        // padding: 70px 0 0;
        .container {
            max-width: ${variables.container_size_500};
        }
        h2 {
            font-size: 20px;
        }
        .btn {
            width: 140px;
            height: 30px;
            font-size: 11px;
        }
        .card-container-2 {
            .card {
                max-width: 300px;
                padding: 30px;
                h2 {
                    font-size: ${variables.m_heading2_500};
                }   
                p {
                    font-size: ${variables.l_paragraph_500};
                }  
                form {
                    margin: 6% 0 0 0;
                    .btn {
                        width: 140px;
                        height: 30px;
                        padding-top: 4px;
                    }
                }           
            }
        }
    }
`;

