import { Component } from 'react';
import { BannerSection } from './Styled';
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';

export class Banner extends Component {
    render() {
        return (
            <BannerSection>
                <div className="container">
                    <div className="banner text-center">
                        <ScrollAnimation offset={0} duration={0.5} animateIn="animate__fadeInUp" animateOnce={true}>                            
                        <h1>About Us</h1>
                        </ScrollAnimation>
                        <ScrollAnimation offset={0} delay={500} duration={0.5} animateIn="animate__fadeInUp" animateOnce={true}>                            
                        <h2>Shaping The Future Of Mobility</h2>                  
                        </ScrollAnimation>   
                    </div>                     
                </div>
            </BannerSection>
        )
    }
}

export default Banner;