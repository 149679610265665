import { useRoutes } from 'react-router';
// Layouts
import HomeLayout from 'app/Layouts/Home.Layout';
//Views
import HomeView from 'app/Views/Home/HomeView';
import ForMarketersView from 'app/Views/Home/ForMarketersView';
import ForAutomotiveView from 'app/Views/Home/ForAutomotiveView';
import BlogView from 'app/Views/Home/BlogView';
import ContactUsView from 'app/Views/Home/ContactUsView';
import PrivacyPolicyView from 'app/Views/Home/PrivacyPolicyView';
import AboutUsView from 'app/Views/Home/AboutUsView';
import BlogDetailsVew from 'app/Views/Home/BlogDetailsView';
import NewsRoomView from 'app/Views/Home/NewsRoomView';
import ArticleDetails from '../Views/Home/ArticleDetailsView';
import CareersView from 'app/Views/Home/CareersView';
import CareersDetailsView from 'app/Views/Home/CareersDetailsView';
import LegalNoticeView from 'app/Views/Home/LegalNoticeView';
import MessageSentView from 'app/Views/Home/MessageSentView';

const Auth = () => {
  return useRoutes([
    {
      path: '/',
      element: <HomeLayout />,
      children: [
        { path: '/', element: <HomeView /> },
        { path: 'marketer', element: <ForMarketersView /> },
        { path: 'automotive', element: <ForAutomotiveView /> },
        { path: 'blog', element: <BlogView /> },
        { path: 'contact-us', element: <ContactUsView /> },
        { path: 'privacy-policy', element: <PrivacyPolicyView /> },
        { path: 'legal', element: <LegalNoticeView /> },
        { path: 'about-us', element: <AboutUsView /> },
        { path: 'blog/:id', element: <BlogDetailsVew /> },
        { path: 'news-room', element: <NewsRoomView /> },
        { path: 'article/:id', element: <ArticleDetails /> },
        { path: 'career', element: <CareersView /> },
        { path: 'career-details/:id', element: <CareersDetailsView /> },
        { path: 'contact-us/:message', element: <MessageSentView /> },
      ]
    },
  ]);
};

export default Auth;