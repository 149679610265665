import { Component } from 'react';
import { SectionSecond } from './Styled';
import BackendService from '../../../Services/BackendService';
import { Props, States } from '../../../Redux/Models';
export class SectionTwo extends Component<Props, States> {
    public state: States = {
        form: {
            firstName: "",
            lastName: "",
            email: "",
            message: "",
            phone: "",
            company: ""
        },
        errors: {},
        showSuccess: false,
        agree: false
    }


    handleChange = (e: any) => {

        const name = e.target.id;
        const value = e.target.value;
        this.setState({
            form: { ...this.state.form, [name]: value }
        });
    }

    handleTermAndCOnditions = (e: any) => {
        this.setState((prevState: States) => {
            prevState.agree = !prevState.agree;
            return prevState;
        });
    }

    handleSubmit = (e: any) => {
        e.preventDefault();
        // console.log(this.state)
        const { form } = this.state;
        const errors = this.validate(form);
        console.log(errors)
        if (Object.keys(errors).length === 0) {
            let formData: any = new FormData();
            formData.append('companyField', form.company);
            formData.append('firstNameField', form.firstName);
            formData.append('lastNameField', form.lastName);
            formData.append('emailField', form.email);
            formData.append('phoneNumberField', form.phone);
            formData.append('messageField', form.message);
            BackendService.postContactForm(formData).then(resp => {
                if (resp.isSuccess()) {
                    this.setState({
                        form: {
                            firstName: "",
                            lastName: "",
                            email: "",
                            message: "",
                            phone: "",
                            company: ""
                        },
                        // showSuccess: true,
                        agree: false
                    });
                    window.location.href = "contact-us/success";
                } else {
                    window.location.href = "contact-us/error";

                }
                // setTimeout(() => {
                //     this.setState({
                //         showSuccess: false
                //     });
                // }, 10000);
            }).catch(err => {
                window.location.href = "contact-us/error";
            })
        }
    }

    validate = (values: States) => {
        const errors: any = {};
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const phn_regex = /(?:\(?\+\d{2}\)?\s*)?\d+(?:[ -]*\d+)*$/;
        if (!values.firstName) {
            errors.firstName = "First name is required";
        }
        if (!values.lastName) {
            errors.lastName = "Last name is required";
        }
        if (!values.email) {
            errors.email = "Email is required";
        }
        else if (!regex.test(values.email)) {
            errors.email = "Email is not valid";
        }
        if (!values.message) {
            errors.message = "This field is required";
        }
        // if(!values.phone) {
        //     errors.phone = "Phone number is required";
        // }
        // if(!values.company) {
        //     errors.company = "Company name is required";
        // }
        // else if(values.phone.length < 10){
        //     errors.phone = "Phone number should be minimum 10 digit";
        // }
        // else if(!phn_regex.test(values.phone)) {
        //     errors.phone = "Phone number is not valid";
        // }

        this.setState({ errors: errors });
        return errors;
    }


    render() {
        const { form, showSuccess, errors, agree } = this.state;
        return (
            <SectionSecond>
                <div className="container skew-inner-container">
                    <div className="heading text-center">
                        <h2>Contact Us</h2>
                        <p>For further questions, please email <br /> <a href="mailto:info@4screen.com">info@4screen.com</a> or contact us using the form below.</p>
                    </div>

                    <form className="d-flex flex-wrap align-items-start" onSubmit={this.handleSubmit}>
                        {/* {showSuccess ? (<div className="alert alert-success form-group text-area" role="alert" >
                            Thanks for contacting us! We will get back to you within 24 hours.
                        </div>) : (<></>)} */}
                        <div className="form-group">
                            <label htmlFor="name">First Name *</label>
                            <input type="text"
                                className="form-control"
                                id="firstName"
                                placeholder="First Name"
                                value={form.firstName}
                                onChange={(e) => this.handleChange(e)} />
                            <p>{errors?.firstName}</p>
                        </div>
                        <div className="form-group">
                            <label htmlFor="name">Last Name *</label>
                            <input type="text"
                                className="form-control"
                                id="lastName"
                                placeholder="Last Name"
                                value={form.lastName}
                                onChange={(e) => this.handleChange(e)} />
                            <p>{errors?.lastName}</p>
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email *</label>
                            <input type="text"
                                className="form-control"
                                id="email"
                                placeholder="Email"
                                value={form.email}
                                onChange={(e) => this.handleChange(e)} />
                            <p>{errors?.email}</p>
                        </div>
                        <div className="form-group">
                            <label htmlFor="phone">Phone</label>
                            <input type="text"
                                className="form-control"
                                id="phone"
                                placeholder="Phone"
                                value={form.phone}
                                onChange={(e) => this.handleChange(e)} />
                            <p>{errors?.phone}</p>
                        </div>
                        <div className="form-group fullwidth">
                            <label htmlFor="company">Company</label>
                            <input type="text"
                                className="form-control"
                                id="company"
                                placeholder="Company"
                                value={form.company}
                                onChange={(e) => this.handleChange(e)} />
                            <p>{errors?.company}</p>
                        </div>
                        <div className="form-group text-area">
                            {/* <label htmlFor="message">How may we assist you?</label> */}
                            <label htmlFor="message">Your Message *</label>
                            <textarea className="form-control"
                                id="message"
                                placeholder="Insert text here"
                                value={form.message}
                                onChange={(e) => this.handleChange(e)}></textarea>
                            <p>{errors?.message}</p>
                        </div>
                        <div className="terms-agree">
                            <label className="check-container">
                                <input type="checkbox" name="" id="" checked={agree} onChange={(e) => this.handleTermAndCOnditions(e)} />
                                <span className="checkmark d-block"></span>
                            </label>
                            <p>I have read the information on the <a target="_blank" href="/privacy-policy">privacy policy</a> and agree to the storage of my data.</p>
                        </div>
                        <div className="btn-container text-center">
                            <button className='contact-us-btn' disabled={!agree}>Send Message
                                <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.03516 6.4314C6.48287 6.4314 6.03516 6.87911 6.03516 7.4314C6.03516 7.98368 6.48287 8.4314 7.03516 8.4314L7.03516 6.4314ZM8.37202 8.1385C8.76255 7.74798 8.76255 7.11481 8.37202 6.72429L2.00806 0.360328C1.61754 -0.0301964 0.984371 -0.0301964 0.593847 0.360328C0.203322 0.750852 0.203322 1.38402 0.593847 1.77454L6.2507 7.4314L0.593846 13.0882C0.203322 13.4788 0.203322 14.1119 0.593846 14.5025C0.98437 14.893 1.61754 14.893 2.00806 14.5025L8.37202 8.1385ZM7.03516 8.4314L7.66491 8.4314L7.66491 6.4314L7.03516 6.4314L7.03516 8.4314Z" fill="white" />
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>
                <div className="section-lining lining-after"><div className="dotted"></div></div>
            </SectionSecond>
        )
    }
}

export default SectionTwo;