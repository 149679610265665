import React, { Component } from 'react'
import { HeaderPart } from '../Helpers/Styled';
import logo from '../assets/header/logo.png'
import {Navbar, NavbarBrand, NavLink, NavItem, Collapse, Nav} from 'reactstrap';
import { Props, States } from '../Redux/Models';

class Header extends Component<Props, States> {
    constructor(props:any) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false,
        };
    }
    public render() {
        return (
            <HeaderPart>
                <Navbar expand="md">
                    <div className="container">
                        <NavbarBrand className="custom-logo d-block" href="/">
                            <img src={logo} alt="Logo"/>
                        </NavbarBrand>

                        <div className="ham-toggle">
                        <input type="checkbox" onClick={this.toggle}/>
                        <span className="cross"></span>
                        <span className="cross"></span>
                        <span className="cross"></span>
                        </div>

                        <Collapse isOpen={this.state.isOpen} navbar>
                            <Nav className="ml-auto" navbar >
                                <NavItem className="pr-4">
                                    <NavLink href="/">
                                        Home
                                    </NavLink>
                                </NavItem>
                                <NavItem className="pr-4">
                                    <label htmlFor="drop-1" className="drop-trigger-container">
                                        <NavLink className="drop-toggle">
                                            Products
                                        </NavLink>
                                        <input id='drop-1' className="trigger-check" type="checkbox"/>
                                        <ul className="custom-dropdown-menu">
                                            <li className="custom-dropdown-item">
                                                <a className="drop-item" href="/marketer">Marketing</a>
                                            </li>
                                            <li className="custom-dropdown-item">
                                                <a className="drop-item" href="/automotive">Automotive</a>
                                            </li>                                            
                                        </ul>
                                    </label>
                                </NavItem>
                                <NavItem className="pr-4">
                                    <label htmlFor="drop-2" className="drop-trigger-container">
                                        <NavLink className="drop-toggle">
                                            Company
                                        </NavLink>
                                        <input id='drop-2' className="trigger-check" type="checkbox"/>
                                        <ul className="custom-dropdown-menu">
                                            <li className="custom-dropdown-item">
                                                <a className="drop-item" href="/about-us">About Us</a>
                                            </li>
                                            <li className="custom-dropdown-item">
                                                <a className="drop-item" href="/news-room">Newsroom</a>
                                            </li>
                                            <li className="custom-dropdown-item">
                                                <a className="drop-item" href="/blog">Blog</a>
                                            </li>
                                        </ul>
                                    </label>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="/career">
                                        Careers
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <div className="contact-us-link ml-auto">
                                <a href="/contact-us" className="btn contact-us-btn btn-color">Contact Us 
                                    <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.03516 6.4314C6.48287 6.4314 6.03516 6.87911 6.03516 7.4314C6.03516 7.98368 6.48287 8.4314 7.03516 8.4314L7.03516 6.4314ZM8.37202 8.1385C8.76255 7.74798 8.76255 7.11481 8.37202 6.72429L2.00806 0.360328C1.61754 -0.0301964 0.984371 -0.0301964 0.593847 0.360328C0.203322 0.750852 0.203322 1.38402 0.593847 1.77454L6.2507 7.4314L0.593846 13.0882C0.203322 13.4788 0.203322 14.1119 0.593846 14.5025C0.98437 14.893 1.61754 14.893 2.00806 14.5025L8.37202 8.1385ZM7.03516 8.4314L7.66491 8.4314L7.66491 6.4314L7.03516 6.4314L7.03516 8.4314Z" fill="white"/>
                                    </svg>
                                </a>
                            </div>
                        </Collapse>
                    </div>
                </Navbar>
            </HeaderPart>
        )
    }
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}

export default Header;