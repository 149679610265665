import { SvgIconComponent } from "@mui/icons-material";
import { UserModel } from "./User.Models";

export class InitialAuthStateModel {
    [key: string]: any;
    public token: string;
    public isAuthenticated: boolean;
    public isExpired: boolean;
    public user: UserModel
}

export class InitialConfigStateModel {
    [key: string]: any;
    public isToggled: boolean;
}

export abstract class BaseStateModel {
    public errors = {
        has: (e: string): boolean => {
            return false;
        },
        first: (e: string): string => {
            return '';
        }
    };
}

export class DataTableHeaderModel {
    public label: string;
    public key: string;
    public isSort: boolean;
    public style?: {
        [key: string]: any;
    };
}

type Color = 'inherit' | 'primary' | 'secondary' | 'default';
type Direction = 'asc' | 'desc';

export class DataTableActionModel {
    public icon: SvgIconComponent;
    public key: string;
    public color?: Color;
    public tooltip?: string;
    public match?: any;
}

class Status {
    public key: string;
    public label: string;
    public width?: string;
}

export class DataTableModel {
    [key: string]: any;
    public request: Function;
    public status?: Status;
    public actionWidth?: string;
    public actions?: Array<DataTableActionModel>;
    public headers: Array<DataTableHeaderModel>;
    public pagination?: boolean;
    public limit?: number
    public active?: string
    public direction?: Direction
}

export class StateModel {
    public Auth: InitialAuthStateModel;
    public Config: InitialConfigStateModel;
}

export abstract class BaseModel {
    [key: string]: any;
    public createdAt?: string;
    public updatedAt?: string;
    public deletedAt?: string;
}


export class BlogModel {
    public id: string;
    public content: string;
    public cover: any;
    public date: string;
    public nextActionType: string;
    public published_at: string;
    public shortDescription: string;
    public title: string;
    public created_at: string;
    public updated_at: string;
}

export class ArticleModel {
    public id: string;
    public content: string;
    public cover: any;
    public date: string;
    public nextActionType: string;
    public published_at: string;
    public shortDescription: string;
    public title: string;
    public created_at: string;
    public updated_at: string;
    public isExternalArticle: boolean;
    public externalUrl: string;
}

export class JobFilterModel {
    public id: string;
    public title: string;
    public published_at: string;
    public created_at: string;
    public updated_at: string;
}

export class JobModel {
    public id: string;
    public title: string;
    public department: any;
    public shortDescription: string;
    public description: string;
    public position_types: any;
    public locations: any;
    public published_at: string;
    public created_at: string;
    public updated_at: string;
}