import { Http, CancelTokenSource, CancelTokenStatic } from '../Http';

export default abstract class BaseService{
    public Http = Http;
    public httpRequest:Function;
    public static cancelToken?:CancelTokenStatic;
    public static source?:CancelTokenSource;
    constructor(){
        this.httpRequest = (params:Object) => {
            const $d = new Promise((resolve, reject) => {
                Http(params)
                    .then(res => {
                        return resolve(res.data);
                    })
                    .catch(err => {
                        console.error(err);
    
                        //const data = getErrorData(err);
    
                        return reject(err);
                    });
            });
            return $d;
        };
    }

    /*
	GET request
    */
    private getAjax = ( url:String, params:Object = {} ) => {
        return this.httpRequest({
            method: 'GET',
            url: url,
            params: params
        });
    };

    /*
        POST request
    */
    private postAjax = ( url:String, params:Object = {} ) => {
        return this.httpRequest({
            method: 'POST',
            url: url,
            data: params
        });
    };

    /*
        PATCH request
    */
    private patchAjax = ( url:String, params:Object = {}) => {
        return this.httpRequest({
            method: 'PATCH',
            url: url,
            data: params
        });
    };

    /*
        DELETE request
    */
    private deleteAjax = (url:String) => {
        return this.httpRequest({
            method: 'DELETE',
            url: url
        });
    }; 

    public static initCancelToken(){
        this.cancelToken = Http.CancelToken;
        this.source = this.cancelToken.source();
    }

    public static cancelRequestIfPending(){
        this.source?.cancel();
    } 




}