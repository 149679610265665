import {Component} from 'react';
import { SectionThird } from './Styled';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators} from 'reactstrap';
import bg_image1 from '../../../assets/Marketeer/V.webp';
import bg_image2 from '../../../assets/Marketeer/W.webp';
import bg_image3 from '../../../assets/Marketeer/X.webp';
import bg_image4 from '../../../assets/Marketeer/Y.webp';

export class SectionThree extends Component<{}, { activeIndex: any }> {
    constructor(props: any) {
      super(props);
      this.state = {
        activeIndex: 0
      };
    }
    slides:any={};
    slideItems:any = [
        {
          altText: 'Slide 1',
          key: 1,
          heading: "Branded Pin",
          description: "Like a store sign, Branded Pins inform and remind customers that your business is on or near their route.",
          imgsrc: bg_image1
        },
        {
          altText: 'Slide 2',
          key: 2,
          heading: "Search",
          description: "Translate driver interests and intentions into navigations to your location.",
          imgsrc: bg_image2
        },
        {
          altText: 'Slide 3',          
          key: 3,
          heading: "Recommendation",
          description: "Recommend your location as the perfect destination for drivers’ needs.",
          imgsrc: bg_image3
        },
        {
          altText: 'Slide 4',
          key: 4,
          heading: "In-Car Offer",
          description: "Showcase your current offers for maximum attention and engagement.",
          imgsrc: bg_image4
        }
    ]
    render() {
        this.slides = this.slideItems.map((item:any,index:number) => {
            return (              
              <CarouselItem key={item.key}>   
              <div className="image-container"><img src={item.imgsrc} alt="" /></div> 
                <div className="container-wrapper">
                  <div className="container">
                    <div className="right">
                      <h2>{item.heading}</h2>
                      <h3>{item.description}</h3>
                    </div> 
                  </div>           
                </div>
              </CarouselItem>                          
            );
        });
        return (
            <SectionThird>
                <div id="marketer-ad-format-section">
                    <Carousel
                      activeIndex={this.state.activeIndex}
                      next={this.next}
                      previous={this.previous}
                      slide={false}
                      fade={true}
                      className="carousel-fade"
                      touch={true}
                    >
                      <CarouselIndicators
                        activeIndex={this.state.activeIndex}
                        items={this.slideItems}
                        onClickHandler={this.goToIndex}
                      />
                      {this.slides}
                      <CarouselControl
                        direction="prev"
                        directionText="Previous"
                        onClickHandler={this.previous}
                      />
                      <CarouselControl
                        direction="next"
                        directionText="Next"
                        onClickHandler={this.next}
                      />
                    </Carousel>
                </div>
                {/* <div className="container-xxx">
                    <div className="right">
                        <h2>Looking For Traffic?</h2>
                        <h3>Deliver a remarkable first impressions in an innovative way.</h3>
                        <p>Like a store sign, Branded Pins inform and remind customers that your business is on or near their route.</p>
                    </div>
                </div> */}
            </SectionThird>
        )
    }
    next = () => {
        if(this.state.activeIndex !== this.slideItems.length-1){
            this.setState({ activeIndex: this.state.activeIndex + 1 })
        }else{
            this.setState({ activeIndex: 0 })
        }
    };

    previous = () => {
        if(this.state.activeIndex !== 0){
            this.setState({ activeIndex: this.state.activeIndex - 1 })
        }
    };

    goToIndex = (index:any) => {
        if(index !== this.slideItems.length){
            this.setState({ activeIndex: index });
        }
    };
}

export default SectionThree;