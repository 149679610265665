import { Component } from 'react';
import { SectionSecond } from './Styled';
import down from '../../../assets/common-icons/select-dropdown.png';
// import { JobFilterModel, Props, States , LooseObject, JobModel } from '../../../Redux/Models';
import { JobFilterModel, Props, States, LooseObject, JobModel } from '../../../Redux/Models';
import BackendService from '../../../Services/BackendService';
import { Link } from 'react-router-dom';

export class SectionTwo extends Component<Props, States>{
    public state: States = {
        locations: [],
        location: '',
        departments: [],
        department: '',
        positions: [],
        position: '',
        jobs: [],
        loading: false


    }

    componentDidMount() {
        this.getLocations();
        this.getDepartments();
        this.getPositions();
        this.getJobs();
        window.scrollTo(0, 0);
    }

    getLocations() {
        BackendService.getLocations().then(resp => {
            if (resp.isSuccess()) {
                this.setState((prevState: States) => {
                    prevState.locations = resp.data;
                    return prevState;
                })
            }
        })
    }

    getDepartments() {
        BackendService.getDepartments().then(resp => {
            if (resp.isSuccess()) {
                this.setState((prevState: States) => {
                    prevState.departments = resp.data;
                    return prevState;
                })
            }
        })
    }

    getPositions() {
        BackendService.getPositions().then(resp => {
            if (resp.isSuccess()) {
                this.setState((prevState: States) => {
                    prevState.positions = resp.data;
                    return prevState;
                });
            }
        })
    }

    getJobs() {
        const { location, department, position } = this.state;
        const filerParams: LooseObject = {};
        if (location) {
            filerParams['locations.title'] = location;
        }
        if (department) {
            filerParams['department.title'] = department;
        }
        if (position) {
            filerParams['position_types.title'] = position;
        }
        this.setState({ loading: true });
        BackendService.getJobs(filerParams).then(resp => {
            if (resp.isSuccess()) {
                this.setState((prevState: States) => {
                    prevState.jobs = resp.data;
                    prevState.loading = false;
                    return prevState;
                })
            }
        })

    }


    changeFilter = (e: any) => {
        const key = e.target.id;
        const value = e.target.value;
        this.setState({ [key]: value });
        setTimeout(() => {
            this.getJobs();
        }, 3);
    }

    render() {
        const { locations, departments, positions, jobs, loading } = this.state;
        return (
            <SectionSecond>
                <div className="container skew-inner-container">
                    <div className="heading text-center">
                        <h2>All Open Positions</h2>
                        <p>Interested in joining our team? <br /> We’d love to meet you!</p>
                    </div>
                    <div className="job-list-container">
                        <div className="job-filter-container d-flex flex-wrap justify-content-start">
                            <div className="job-filter">
                                <select name="" id="location" onChange={this.changeFilter}>
                                    <option value="">Location</option>
                                    {locations.map((location: JobFilterModel) => {
                                        return <option key={location.id} value={location.title}>{location.title}</option>
                                    })}
                                </select>
                                <img src={down} alt="" />
                            </div>
                            <div className="job-filter">
                                <select name="" id="department" onChange={this.changeFilter}>
                                    <option value="">Department</option>
                                    {departments.map((department: JobFilterModel) => {
                                        return <option key={department.id} value={department.title}>{department.title}</option>
                                    })}
                                </select>
                                <img src={down} alt="" />
                            </div>
                            <div className="job-filter">
                                <select name="" id="position" onChange={this.changeFilter}>
                                    <option value="">Position</option>
                                    {positions.map((position: JobFilterModel) => {
                                        return <option key={position.id} value={position.title}>{position.title}</option>
                                    })}
                                </select>
                                <img src={down} alt="" />
                            </div>
                        </div>
                        {jobs.length ? (
                            <ul className="job-list">
                                {jobs.map((job: JobModel) => {
                                    return <li key={job.id} className='job-li'>
                                                {/* <div className="job-name d-flex flex-wrap">                                                    
                                                    <div className="name">
                                                        <h2>{job.department.title}</h2>
                                                        <p><span className="text">{job.position_type.title}</span> <span className="d-inline-block circle"></span> <span className="text">{job.location.title}</span></p>
                                                    </div>
                                                </div>
                                                <div className="job-description">
                                                    <h3>{job.title}</h3>
                                                    <p>{job.shortDescription}</p>
                                                </div>
                                                <Link to={`/career-details/`+job.id} className="btn d-inline-block">
                                                    Apply Here 
                                                        <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.03516 6.4314C6.48287 6.4314 6.03516 6.87911 6.03516 7.4314C6.03516 7.98368 6.48287 8.4314 7.03516 8.4314L7.03516 6.4314ZM8.37202 8.1385C8.76255 7.74798 8.76255 7.11481 8.37202 6.72429L2.00806 0.360328C1.61754 -0.0301964 0.984371 -0.0301964 0.593847 0.360328C0.203322 0.750852 0.203322 1.38402 0.593847 1.77454L6.2507 7.4314L0.593846 13.0882C0.203322 13.4788 0.203322 14.1119 0.593846 14.5025C0.98437 14.893 1.61754 14.893 2.00806 14.5025L8.37202 8.1385ZM7.03516 8.4314L7.66491 8.4314L7.66491 6.4314L7.03516 6.4314L7.03516 8.4314Z" fill="white"></path></svg>
                                                </Link>
                                            </li> */}
                                        <div className="job-name d-flex flex-wrap">
                                            <div className="name">
                                                <h2>{job.title}</h2>
                                                {/* <h2>{job.department.title}</h2> */}
                                                <p><span className="text">{job.position_types.title}</span>
                                                    {job.locations.map((location: any) => {
                                                        return <><span className="d-inline-block circle"></span> <span className="text">{location.title}</span></>
                                                    })}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="job-description">
                                            <h3>{job.department.title}</h3>
                                            {/* <h3>{job.title}</h3> */}
                                            <p>{job.shortDescription}</p>
                                        </div>
                                        <Link to={`/career-details/` + job.id} className="btn d-inline-block">
                                            Apply Here
                                            <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.03516 6.4314C6.48287 6.4314 6.03516 6.87911 6.03516 7.4314C6.03516 7.98368 6.48287 8.4314 7.03516 8.4314L7.03516 6.4314ZM8.37202 8.1385C8.76255 7.74798 8.76255 7.11481 8.37202 6.72429L2.00806 0.360328C1.61754 -0.0301964 0.984371 -0.0301964 0.593847 0.360328C0.203322 0.750852 0.203322 1.38402 0.593847 1.77454L6.2507 7.4314L0.593846 13.0882C0.203322 13.4788 0.203322 14.1119 0.593846 14.5025C0.98437 14.893 1.61754 14.893 2.00806 14.5025L8.37202 8.1385ZM7.03516 8.4314L7.66491 8.4314L7.66491 6.4314L7.03516 6.4314L7.03516 8.4314Z" fill="white"></path></svg>
                                        </Link>
                                    </li>
                                })}
                            </ul>) :
                            !loading ?
                                <h2 className='no-result text-center'>Sorry, we couldn't find any results! <span >&#128542;</span></h2> : <></>
                        }

                    </div>
                    <div className="card-container-2">
                        <div className="card text-center mx-auto">
                            <h2>Don’t Find Anything That Suits You but Want to Join?</h2>
                            <p>Send us an email at <a href="mailto:jobs@4screen.com">jobs@4screen.com</a></p>
                        </div>
                    </div>
                </div>
            </SectionSecond>
        )
    }
}

export default SectionTwo;