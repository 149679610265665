import {Component} from 'react';
import {SectionFirst} from './Styled';
import growth1 from '../../../assets/Marketeer/N.png';
import growth2 from '../../../assets/Marketeer/P.png';
import growth3 from '../../../assets/Marketeer/O.png';
import growth4 from '../../../assets/Marketeer/Q.png';


export class SectionOne extends Component {
    render() {
        return (
            <SectionFirst>
                <div className="skew-inner-container container">
                    <div className="heading">
                        <h2>Advertiser Benefits</h2>
                        <p className='sub-heading'>See why the largest brands use 4.screen to reach drivers on a global scale while crafting a personalized experience for them.</p>
                    </div>
                    <div className="right">
                        <div className="box-container d-flex flex-wrap">
                            <div className="box box1">
                                <div className="img-container"><img src={growth1} alt="" /></div>
                                <h3>Reach Drivers in <br/> Real Time</h3>
                                <p>Engage in real time and based on location, with an exclusive and highly-engaged audience.</p>
                            </div>
                            <div className="box box2">
                                <div className="img-container"><img src={growth2} alt="" /></div>
                                <h3>Highly Contextual <br/> Targeting</h3>
                                <p>Leverage the unique set of datapoints of connected cars to create highly contextual and tailored ads for your target group.</p>
                            </div>
                            <div className="box box3">
                                <div className="img-container"><img src={growth3} alt="" /></div>
                                <h3>Performance and <br/> Awareness</h3>
                                <p>Multiple ad formats available to increase brand awareness, drive performance and generate store visits.</p>
                            </div>
                            <div className="box box4">
                                <div className="img-container"><img src={growth4} alt="" /></div>
                                <h3>Pricing and <br/> Reporting</h3>
                                <p>Always stay in full control of your campaigns with state-of-the-art performance reporting and transparent pricing.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </SectionFirst>
        )
    }
}

export default SectionOne;