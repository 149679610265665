import { ComponentType } from 'react';
import { CancelTokenSource } from '../../Http';

export class Props {
    [key: string]: any;
}

export class States {
    [key: string]: any;
}

export class Routes {
    public path: string;
    public exact?: boolean;
    public name:string;
    public component:ComponentType;
}

export class Response<T>{
    public data: T;
    public status: number;
    public message: string;
    public success: boolean;
    public constructor(params = {}) {
        Object.assign(this, params);
    }

    public getStatus(): number {
        return this.status;
    }

    public isSuccess(): boolean {
        return this.success;
    }

    public getData(): T {
        return this.data;
    }
}

export class DataList<T extends {}> {
    public items:Array<T>;
    public total:number;
    public hasItem:boolean;
}

export class ResponseList<T extends {}>{
    public data:DataList<T>;
    public status: number;
    public message: string;
    public success: boolean;
    public constructor(params = {}) {
        Object.assign(this, params);
    }

    public getStatus(): number {
        return this.status;
    }

    public isSuccess(): boolean {
        return this.success;
    }

    public getItems(): Array<T> {
        return this.data.items;
    }

    public getTotal(): number {
        return this.data.total;
    }

    public getData(): DataList<T> {
        return this.data;
    }
}

export class Options {
    constructor(params:Options={}){
        Object.assign(this,params);
    }
    [key: string]: any;
    public root?:string
    public sources?:CancelTokenSource
}

export {
    LoadUserModel,
    UserModel,
    AuthUserModel
} from './User.Models';

export {
    InitialAuthStateModel,
    BaseStateModel,
    StateModel,
    DataTableActionModel,
    DataTableHeaderModel,
    DataTableModel,
    BlogModel,
    ArticleModel,
    JobFilterModel,
    JobModel
} from './Base.Models';

export interface LooseObject {
    [key: string]: any
 }
