import { Component } from 'react';
import { BannerSection } from './Styled';
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';

export class Banner extends Component {
  render() {
    return (
      <BannerSection>
        <div className="container d-flex flex-wrap">
          <div className="left">
            <ScrollAnimation offset={0} duration={1} animateIn="animate__fadeInUp" animateOnce={true}>                          
            <h1>Newsroom</h1>
            </ScrollAnimation>
          </div>
        </div>
      </BannerSection>
    )
  }
}

export default Banner;