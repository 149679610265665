import styled from "styled-components";
import variables from "../../Style-js/variable";

export const Title = styled.h3`
`;

export const HeaderPart = styled.header`
box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
background: #fff;
position: fixed;
width: 100%;
top: 0;
left: 0;
z-index: 50;
.container {
    max-width: ${variables.container_size};
}
width: 100%;
height: 80px;
background: ${variables.white};
backdrop-filter: blur(50px);   
.navbar {
    position: relative;
    z-index: 10;
}
.custom-logo {
    width: 110px;
}
.custom-logo img {
    width: 100%;
}
nav {    
    height: inherit;    
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
}
.navbar-toggler {
    border: none;
}
// Hamburger Menu Starts
.ham-toggle {
    position: relative;
    display: none;
}
input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;
    cursor: pointer;
    opacity: 0; 
    z-index: 2;
    -webkit-touch-callout: none;
}
.cross {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: #cdcdcd;
    border-radius: 3px;
    z-index: 1;
    // transform-origin: 4px 0px;
    transform-origin: 27px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;
}
.cross:first-child {
    transform-origin: 0% 0%;
}
.cross:nth-last-child(2) {
    // transform-origin: 0% 100%;
    transform-origin: 50% 0%;
}
input:checked ~ .cross {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #232323;
}
input:checked ~ .cross:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
}
input:checked ~ .cross:nth-last-child(2){
    transform: rotate(-45deg) translate(0, -1px);
}
// Hamburger Menu Ends
.nav-item {
    position: relative;
    cursor: pointer !important;
    .nav-link {
        white-space: nowrap;
    }
    trigger-check {
        width: 0;
        height: 0;
        position: absolute;
    }
    .custom-dropdown-menu {
        border: none;
        border-top: 5px solid ${variables.cyan};
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
        padding: 0;
        list-style-type: none;
        background-color: #fff;
        border-radius: .25rem;
        position: absolute;
        top: 100%;
        min-width: 200px;        
        left: 0;
        display: none;
    }    
    .custom-dropdown-item {
        padding: 0;
        &:active {
            background: none;
        }
    }
    .drop-item {
        display: block;
        text-align: center;
        padding-top: 15px;
        padding-bottom: 15px;       
        background: none;   
        &:hover {
            background: #F0F7FF;
        }
    }
    .nav-link:hover ~ .custom-dropdown-menu,
    .custom-dropdown-menu:hover {
        display: block;
    }   
    .drop-trigger-container {
        margin: 0;
        cursor: pointer;
    } 
    .drop-toggle::after {
        display: inline-block;
        margin-left: .255em;
        vertical-align: .255em;
        content: "";
        border-top: .3em solid;
        border-right: .3em solid transparent;
        border-bottom: 0;
        border-left: .3em solid transparent;
    }
    .nav-link,
    .drop-item {
        color: #1F2023;
        font-size: ${variables.navigation_link};
        letter-spacing: 0.5px; 
        font-weight: 600;
        background: none;
        border: none;
        &:hover {
            text-decoration: none;
            font-weight: 700;
        }       
    }
}
.contact-us-btn{
    background-color: ${variables.cyan};
    border-radius: 40px;    
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-size: ${variables.btn_text};
    color: ${variables.white};  
    white-space: nowrap;
    transition: 0.3s;  
    padding: ${variables.btn_padding_y} ${variables.btn_padding_x};
    svg {
        margin: 0 0 0 30px;
    }
    &:hover {
        background-color: #1F2121;;
    }
}

@media screen and (min-width: 1793px) {
    height: 94px;
    .container {
        max-width: ${variables.container_size_1920};
    }
    .custom-logo {
        width: 128px;
    }
    .nav-item {
        .nav-link {
            font-size: ${variables.navigation_link_1920};            
        }
    }
    .contact-us-btn{
        padding: ${variables.btn_padding_y_1920} ${variables.btn_padding_x_1920};
        font-size: ${variables.btn_text_1920};
    }
}

@media screen and (max-width: 1792px) and (min-width: 1601px) {
    height: 82px;
    .container {
        max-width: ${variables.container_size_1792};
    }
    .custom-logo {
        width: 115px;
    }
    .nav-item {
        .nav-link {
            font-size: ${variables.navigation_link_1792};
        }
    }
    .contact-us-btn{
        padding: ${variables.btn_padding_y_1792} ${variables.btn_padding_x_1792};
        font-size: ${variables.btn_text_1792};
    }
}

@media screen and (max-width: 1441px) {
    height: 75px;
    .container {
        max-width: ${variables.container_size_1440};
    }
    .custom-logo {
        width: 100px;
    }
    .nav-item {
        .nav-link {
            font-size: ${variables.navigation_link_1440};
        }
    }
    .contact-us-btn {
        font-size: ${variables.btn_text_1440};
    }
}

@media screen and (max-width: 1367px) {
    height: 75px;
    .container {
        max-width: ${variables.container_size_1366};
    }
    .custom-logo {
        width: 100px;
    }
    .nav-item {
        .nav-link,
        .drop-item {
            font-size: ${variables.navigation_link_1366};
        }
    }
    .contact-us-btn{
        padding: ${variables.btn_padding_y_1366} ${variables.btn_padding_x_1366};
        font-size: ${variables.btn_text_1366};
    }
}

@media screen and (max-width: 1100px) {
    .container {
        max-width: ${variables.container_size_1100};
    }
    .contact-us-btn{        
        font-size: ${variables.btn_text_1100};
    }
}

@media screen and (max-width: 900px) {
    .container {
        max-width: ${variables.container_size_800};
    }
    .custom-logo img {
        width: 90%;
    }   
    .contact-us-btn {
        padding: ${variables.btn_padding_y_800} ${variables.btn_padding_x_800};
    }
    .nav-item {
        padding-right: 10px !important;
    }
}

@media screen and (max-width: 767px) {
    .container {
        max-width: ${variables.container_size_500};
    }
    .ham-toggle {
        display: block;
    }
    .drop-toggle::after {
        font-size: 20px;
        vertical-align: 0;
        float: right;
    }
    .navbar-collapse {        
        background-color: #fff;
        position: absolute;
        width: 100%;
        top: 75px;
        left: 0;
    }
    .navbar-nav {
        padding: 13px 30px 0;
    }
    .contact-us-link {
        padding: 10px 30px 20px;
    }
    .contact-us-btn {
        padding: 0;
        height: auto;
        // font-size: ${variables.btn_text_500};
        background: none;
        text-align: left; 
        color: ${variables.cyan};
        path {
            fill: ${variables.cyan};
        }
        svg {
            width: 7px;
        }
        &:hover {
            background: none;
        }
        &:focus {
            box-shadow: none;
        }
    }
    .nav-item {
        .nav-link,
        .drop-item {
            padding: 10px 0;
        }
        .custom-dropdown-menu {
            background: none;
            border: none;
            position: static;
            box-shadow: none;
            border-radius: 0;
            border-left: 1px solid ${variables.cyan};
        }
    }    
    .nav-item .drop-item {
        text-align: left;
        padding-left: 20px;
    }
    .drop-trigger-container {
        width: 100%;
        display: block;
    }
    .nav-link:hover ~ .custom-dropdown-menu, .custom-dropdown-menu:hover {
        display: none !important;
    }
    .trigger-check:checked ~ .custom-dropdown-menu {
        display: block !important;
    }
}
`;

export const FooterPart = styled.section`
    .container {
        max-width: ${variables.container_size};
    }
    position: relative;
    * {
        box-sizing: border-box;
    }
    .container {
        padding-top: 100px;
    }
    .card-container {
        width: 100%;
        position: absolute;
        z-index: 10;
        top: -100px;
        left: 0;
        .card {
            max-width: 870px;
            background: #F6FAFF;
            box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.14);
            border: none;
            border-radius: 25px;
            padding: 40px 70px;
            h2 {
                font-size: ${variables.m_heading2};
            }
            p{
                font-size: ${variables.l_paragraph};
                font-weight: 400;
                margin: 15px 0 0 0;                
            }
            form {
                margin: 3% 0 0 0;                
                .btn {                    
                    border-radius: 40px;
                    font-weight: 600;
                    font-size: ${variables.btn_text};
                    color: ${variables.white};
                    background: ${variables.cyan};                    
                    transition: 0.3s; 
                    padding: ${variables.btn_padding_y} ${variables.btn_padding_x};
                    svg {
                        margin: 0 0 0 30px;
                    }
                    &:hover {
                        background-color: #1F2121;;
                    }
                }
            }
        }
    }
    .list-group-container {
        justify-content: space-between;
        padding: 30px 0 0 0;
        ul {
            list-style-type: none;
            padding: 0;
            li {
                font-size: ${variables.navigation_link};
                font-weight: 400;
                margin: 0 0 10px 0;
                &.heading {
                    font-weight: 600;
                    margin: 0 0 15px 0;
                }
                a {
                    color: #000;
                    text-underline-offset: 7px;
                }                
            }
            .social {
                a {
                    display: inline-block;
                    width: 19px;
                    margin: 0 0 0 20px;
                    img {
                        width: 100%;
                    }                    
                }
                a:first-child {
                    margin: 0;
                }
            }
        }
    }
    .bottom {
        margin: 30px 0 0 0;
        padding: 30px 0 10px 0;
        font-size: ${variables.xs_text};
        border-top: 1px solid #000;
        .footer-logo {
            height: 40px;
        }
        .terms {
            // margin: 0 0 0 50px;
            a{
                color: #212529;
                text-decoration: none;
            }
        }
        .en {
            img {
                width: 13px;
            }
        }
    }

    @media screen and (min-width: 1793px) {
        .container {
            max-width: ${variables.container_size_1920};
        }
        .card-container {
            top: -130px;
            .card {
                box-sizing: border-box;
                max-width: 1054px;
                padding: 50px 36px;
                h2 {
                    font-size: ${variables.m_heading2_1920};
                }
                p{
                    font-size: ${variables.l_paragraph_1920};              
                }
                form {
                    .btn {                         
                        padding: ${variables.btn_padding_y_1920} ${variables.btn_padding_x_1920};                        
                        font-size: ${variables.btn_text_1920};                        
                    }
                }
            }
        } 
        .list-group-container {
            padding: 60px 0 0 0;
            ul {
                li {
                    font-size: ${variables.navigation_link_1920};
                    margin: 0 0 20px 0;
                    &.heading {
                        margin: 0 0 25px 0;
                    }
                }
                .social {
                    a {
                        width: 25px;
                        margin: 0 0 0 25px;                 
                    }
                }
            }
        }   
        .bottom {
            margin: 50px 0 0 0;
            padding: 50px 0 20px 0;
            font-size: ${variables.xs_text_1920};
            .footer-logo {
                height: 60px;
            }
            .en {
                img {
                    width: 16px;
                }
            }
        }    
    }

    @media screen and (max-width: 1792px) and (min-width: 1601px) {
        .container {
            max-width: ${variables.container_size_1792};
        }
        .card-container {
            top: -110px;
            .card {
                max-width: 900px;
                h2 {
                    font-size: ${variables.m_heading2_1792};
                }   
                p {
                    font-size: ${variables.l_paragraph_1792};
                }   
                form {
                    .btn {
                        padding: ${variables.btn_padding_y_1792} ${variables.btn_padding_x_1792};
                        font-size: ${variables.btn_text_1792};
                    } 
                }         
            }
        }
        .list-group-container {
            padding: 50px 0 0 0;
            ul {
                li {
                    font-size: ${variables.navigation_link_1792};
                }
            }
        } 
        .bottom {
            margin: 40px 0 0 0;
            padding: 40px 0 15px 0;
            font-size: ${variables.xs_text_1792};
            .footer-logo {
                height: 50px;
            }
            .en {
                img {
                    width: 15px;
                }
            }
        }    
    }

    @media screen and (max-width: 1440px) {
        .container {
            max-width: ${variables.container_size_1440};
        }
        .card-container {
            .card {
                max-width: 800px;
                h2 {
                    font-size: ${variables.m_heading2_1440};
                }   
                p {
                    font-size: ${variables.l_paragraph_1440};
                }
                form {
                    .btn {
                        font-size: ${variables.btn_text_1440};
                    }             
                }
            }
        }
        .list-group-container {
            ul {
                li {
                    font-size: ${variables.navigation_link_1440};
                }
            }
        }
        .bottom {
            font-size: ${variables.xs_text_1440};
            .en {
                img {
                    width: 13px;
                    position: relative;
                    top: -2px;
                }
            }
        }  
    }

    @media screen and (max-width: 1367px) {
        .container {
            max-width: ${variables.container_size_1366};
        }
        .card-container {
            .card {
                max-width: 700px;
                padding: 30px 50px;
                h2 {
                    font-size: ${variables.m_heading2_1366};
                }   
                p {
                    font-size: ${variables.l_paragraph_1366};
                } 
                form {  
                    .btn {
                        padding: ${variables.btn_padding_y_1366} ${variables.btn_padding_x_1366};
                        font-size: ${variables.btn_text_1366};
                    } 
                }         
            }
        }
        .list-group-container {
            ul {
                li {
                    font-size: ${variables.navigation_link_1366};
                }
                .social {
                    a {
                        width: 16px;                
                    }
                }
            }
        }
        .bottom {
            font-size: ${variables.xs_text_1366};
        }  
    }

    @media screen and (max-width: 1100px) {
        .container {
            max-width: ${variables.container_size_1100};
        }
        .card-container {
            top: -80px;
            .card {
                max-width: 600px;
                padding: 30px 40px;
                h2 {
                    font-size: ${variables.m_heading2_1100};
                }   
                p {
                    font-size: ${variables.l_paragraph_1100};
                }  
                form {
                    .btn {
                        font-size: ${variables.btn_text_1100};
                    }
                }           
            }
        }
        .list-group-container {
            padding: 0;
        }
        .bottom {
            .footer-logo {
                height: 30px;
            }
        }
    }

    @media screen and (max-width: 900px) {
        .container {
            max-width: ${variables.container_size_800};
        }
        .card-container {
            top: -70px;
            .card {
                max-width: 500px;
                h2 {
                    font-size: ${variables.m_heading2_800};
                }   
                p {
                    font-size: ${variables.l_paragraph_800};
                }  
                form {                    
                    .btn {
                        padding: ${variables.btn_padding_y_800} ${variables.btn_padding_x_800};
                    }
                }           
            }
        }
        .list-group-container {
            justify-content: flex-start;
            ul {
                width: 33%;   
                margin-bottom: 50px;             
                li {
                    font-size: ${variables.navigation_link_800};
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        .container {
            max-width: ${variables.container_size_500};
        }
        .card-container {
            top: -60px;
            .card {
                max-width: 300px;
                padding: 30px;
                h2 {
                    font-size: ${variables.m_heading2_500};
                }   
                p {
                    font-size: ${variables.l_paragraph_500};
                }  
                form {                   
                    .btn {
                        font-size: ${variables.btn_text_500};
                    }
                }           
            }
        }
        .list-group-container {
            margin: 20px 0 0 0;
            justify-content: center;
            text-align: center;
            ul {
                // width: 100%;  
                margin-bottom: 10px; 
                li.heading {
                    margin-bottom: 10px;
                }
                .social {
                    justify-content: center;
                }
            }
        }
        .bottom {
            margin: 0;
            padding-top: 10px;
            .footer-logo {
                height: 30px
            }
            .center-sm {
                text-align: center !important;
                margin-bottom: 10px;
            }
        } 
    }
`;