import { BaseModel } from "./Base.Models";

export class UserModel extends BaseModel {
    public constructor(params = {}){
        super();
        Object.assign(this, params);
    }
    public firstName?: string;
    public lastName?: string;

}

export class AuthUserModel extends UserModel {
    public constructor(params = {}){
        super(params)
        Object.assign(this, params);
    }
}

export class LoadUserModel {
    public token: string;
    public user: UserModel
}