import styled from "styled-components";
import variables from '../../../Style-js/variable';
import bg_image1 from '../../../assets/Marketeer/V.webp';
import bg_image2 from '../../../assets/Marketeer/W.webp';
import bg_image3 from '../../../assets/Marketeer/X.webp';
import bg_image4 from '../../../assets/Marketeer/Y.webp';
import leftarr from '../../../assets/Marketeer/left-arrow.png';
import rightarr from '../../../assets/Marketeer/right-arrow.png';

export const HomeBanner = styled.section`
    .container {
        max-width: ${variables.container_size};
    }
    padding: 100px 0 200px 0;
    margin: 80px 0 0 0;
    position: relative;
    .skewed-container {
        background: #D1F6FE;
        width: 100%;
        height: 55px;
        position: absolute;
        left: 0;
        z-index: 1;
        bottom: 55px;
        transform: skew(0deg, -5deg);
        .skewed-container-inner {
            transform: rotate(0deg);
            display: flex;
            justify-content: center;
            padding: 0 2% 0 2%; 
            p {
                font-weight: 900;
                font-size: 35px;
                color: #000;                
                span {
                    font-size: 13px;
                }
                &.middle {
                    margin: 0 10%;
                }
            }           
        }
    }
    .image-container {
        width: 48%;
        img {
            width: 100%;
        }
    }

    @media screen and (min-width: 1793px) {
        margin: 94px 0 0 0;
        .container {
            max-width: ${variables.container_size_1920};
        }
        .skewed-container {
            height: 65px;
            bottom: 65px;
            .skewed-container-inner {
                p {
                    font-size: 48px;               
                }
            }
        }
    }

    @media screen and (max-width: 1792px) and (min-width: 1601px) {
        .container {
            max-width: ${variables.container_size_1792};
        }
        .skewed-container {
            height: 60px;
            bottom: 60px;
            .skewed-container-inner {
                p {
                    font-size: 43px;
                    span {
                        font-size: 14px;
                    }                    
                }
            }
        }
    }


    @media screen and (max-width: 1441px) {
        .container {
            max-width: ${variables.container_size_1440};
        }
        .skewed-container {
            height: 50px;
            bottom: 50px;
            .skewed-container-inner {
                p {
                    font-size: 33px;                   
                }
            }
        }
    }

    @media screen and (max-width: 1366px) {
        margin: 75px 0 0 0;
        .container {
            max-width: ${variables.container_size_1366};
        }
        .skewed-container {
            height: 45px;
            bottom: 45px;
            .skewed-container-inner {
                p {
                    font-size: 30px;         
                    span {
                        font-size: 12px;
                    }          
                }
            }
        }
        .skewed-container .skewed-container-inner p.middle {
            margin: 0px 1%;
        }
    }

    @media screen and (max-width: 1100px) {
        .container {
            max-width: ${variables.container_size_1100};
        }
    }

    @media screen and (max-width: 900px) {
        background-size: 100%;
        padding-top: 70px;
        .container {
            max-width: ${variables.container_size_800};
        }
        // &::before {
        //     display: block;
        //     content: "";
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 100%;
        //     background: rgba(0,0,0,0.6);
        // }
        .skewed-container {
            height: 29px;
            .skewed-container-inner {
                p {
                    font-size: 16px;
                    &.middle {
                        margin: 0 20px;
                    }
                }
            }
        }
        .image-container {
            width: 50%;
            // width: 100%;
            // position: absolute;
            // left: 0;
            // top: 70px;
            // z-index: -1;
            // text-align: center;
            // img {
            //     width: 90%;
            //     max-width: 600px;
            // }
        }
    }

    @media screen and (max-width: 767px) {
        padding: 50px 0 0 0;    
        .container {
            max-width: ${variables.container_size_500};
        }
        .skewed-container {
            height: 31px;
            .skewed-container-inner {
                padding: 0;
                p {
                    display: none;
                }
            }
        }
        .image-container {
            width: 100%;
            padding-bottom: 95px;
            margin-top: 20px;
        }
    }
`;

export const HomeBannerText = styled.div`
    position: relative;
    width: 49%;
    z-index: 1;
    h1 {
        font-weight: 600;    
        font-size: ${variables.l_heading};
        line-height: 55px;       
    }
    p,li {
        font-weight: 500;
        font-size: ${variables.l_paragraph};
        line-height: 35px;
    }
    p,ul { 
        margin: 3% 0 0 0;     
    }
    .btn {
        background-color: ${variables.cyan};
        padding: 0;
        margin: 4% 0 0;
        font-weight: 600;
        border-radius: 40px;
        letter-spacing: 0.5px;
        color: ${variables.white};
        padding: ${variables.btn_padding_y} ${variables.btn_padding_x};
        font-size: ${variables.btn_text};
        transition: 0.3s;  
        svg {
            margin: 0 0 0 30px;
        }
        &:hover {
            background-color: #1F2121;
            color: #ffffff;
        }
    }

    @media screen and (min-width: 1793px) {
        h1 {
            font-size: ${variables.l_heading_1920};
            line-height: 73px;
        }
        p,li {
            font-size: ${variables.l_paragraph_1920};
            line-height: 46px;
        }
        .btn {
            padding: ${variables.btn_padding_y_1920} ${variables.btn_padding_x_1920};
            font-size: ${variables.btn_text_1920};
        }
    }

    @media screen and (max-width: 1792px) and (min-width: 1601px) {
        h1 {
            font-size: ${variables.l_heading_1792};
            line-height: 63px;
        }
        p,li {
            font-size: ${variables.l_paragraph_1792};
            line-height: 40px;
        }
        .btn {
            padding: ${variables.btn_padding_y_1792} ${variables.btn_padding_x_1792};
            font-size: ${variables.btn_text_1792};
        }
    }

    @media screen and (max-width: 1441px) {
        h1 {
            font-size: ${variables.l_heading_1440};
            line-height: 50px;
        }
        p,li {            
            font-size: ${variables.l_paragraph_1440};
            line-height: 33px;
        }
        .btn {
            font-size: ${variables.btn_text_1440};
        }
    }

    @media screen and (max-width: 1367px) {
        h1 {
            font-size: ${variables.l_heading_1366};
            line-height: 45px;
        }
        p,li {
            font-size: ${variables.l_paragraph_1366};
        }
        .btn {
            padding: ${variables.btn_padding_y_1366} ${variables.btn_padding_x_1366};
            font-size: ${variables.btn_text_1366};
        }
    }

    @media screen and (max-width: 1100px) {
        .container {
            max-width: ${variables.container_size_1100};
        }
        h1 {
            font-size: ${variables.l_heading_1100};
            line-height: 40px;
        }
        p,li {
            font-size: ${variables.l_paragraph_1100};
            line-height: 30px;
        }
        .btn {
            font-size: ${variables.btn_text_1100};
        }
    }

    @media screen and (max-width: 900px) {        
        // color: white;
        width: 50%;
        // width: 70%;
        .container {
            max-width: ${variables.container_size_800};
        }
        h1 {
            font-size: ${variables.l_heading_800};
            line-height: 35px;
        }
        p,li {
            font-size: ${variables.l_paragraph_800};
            line-height: 27px;
        }
        .btn {
            padding: ${variables.btn_padding_y_800} ${variables.btn_padding_x_800};
        }
    }

    @media screen and (max-width: 767px) {    
        width: 100%;    
        .container {
            max-width: ${variables.container_size_500};
        }                
        text-align: center;
        h1 {
            width: 100%;
            max-width: 300px;
            margin: 0 auto;
            font-size: ${variables.l_heading_500};
            line-height: 30px;
        }
        ul,p {
            padding: 0;
            width: 100%;
            margin: 20px auto 0;
            max-width: 300px; 
            font-size: ${variables.l_paragraph_500};
        }
        ul {
            display: none;
        }
        li {
            margin: 10px auto 0;
        }
        .btn {            
            font-size: ${variables.btn_text_500};
            // margin-bottom: 95px;
            // margin-bottom: 20px;
        }
    }
`;

export const SectionFirst = styled.section`
    margin: -55px 0 0 0;    
    padding: 150px 0 0;
    transform: skew(0deg, -5deg);
    background: #F6FAFF;
    .skew-inner-container {
        transform: skew(0deg, 5deg);
    }
    .container {
        max-width: ${variables.container_size};
    }
    .heading {
        h2 {
            font-weight: 500;
            font-size: ${variables.m_heading};
            text-align: center;
            margin: 0px 0 1.3%;
        }
        p {
            font-weight: 300;
            text-align: center;
            width: 70%;
            margin: 0 auto;
            font-size: ${variables.l_paragraph};
        }
    }
    .right {        
        padding: 7% 0 13% 0;
        .box-container {   
            display: flex;
            justify-content: space-between;
            .box {       
                transition: 0.3s;         
                text-align: center;
                box-sizing: border-box;
                border-radius: 15px;
                background: #fff;  
                width: 23%;
                box-shadow: 0.4px 0.4px 30px -4px rgba(0,0,0,0.3);
                padding: 3%;        
                .img-container {
                    text-align: center;                      
                    img {
                        width: 95px;
                        background: #fff;
                        border-radius: 15px;
                    }                  
                }                
                h3 {
                    font-weight: 600;
                    line-height: 20px;
                    margin: 12% 0 5.5%;
                    font-size: ${variables.l_paragraph};
                }
                p {
                    font-weight: 300;
                    margin: 0;
                    line-height: 18px;
                    font-size: ${variables.btn_text};
                }                                                             
                &:hover {
                    background: #91F0FC;
                }
            }
        }
    }

    @media screen and (min-width: 1793px) {
        margin-top: -65px;
        .container {
            max-width: ${variables.container_size_1920};
        }
        .heading {
            h2 {
                font-size: ${variables.m_heading_1920};
                margin-bottom: 24px;
            }
            p {
                // font-size: ${variables.l_paragraph_1920};
                font-size: 30px;
            }
        }
        .right {
            .box-container {                              
                .box {
                    .img-container {
                        img {
                            width: 120px;
                        }
                    }
                    h3 {
                        font-size: ${variables.l_paragraph_1920};
                        line-height: 25px;
                        margin-bottom: 6%;
                    }
                    p {
                        font-size: ${variables.btn_text_1920};
                        line-height: 22px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1792px) and (min-width: 1601px) {
        margin-top: -60px;
        .container {
            max-width: ${variables.container_size_1792};
        }
        .heading {
            h2 {
                font-size: ${variables.m_heading_1792};
            }
            p {
                font-size: ${variables.l_paragraph_1792};
            }
        }
        .right {
            .box-container {
                .box {
                    .img-container {
                        img {
                            width: 110px;
                        }
                    }
                    h3 {
                        font-size: ${variables.l_paragraph_1792};
                        line-height: 23px;
                    }
                    p {
                        font-size: ${variables.btn_text_1792};
                        line-height: 20px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1441px) {
        margin-top: -50px;
        container {
            max-width: ${variables.container_size_1440};
        }
        .heading {
            h2 {
                font-size: ${variables.m_heading_1440};
            }
            p {
                font-size: ${variables.l_paragraph_1440};
            }
        }
        .right {
            .box-container {
                .box {
                    h3 {
                        line-height: 19px;
                        font-size: ${variables.l_paragraph_1440};
                    }
                    p {
                        line-height: 17px;
                        font-size: ${variables.btn_text_1440};
                        // font-size: ${variables.btn_text_1440};
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1367px) {
        margin-top: -46px;
        .container {
            max-width: ${variables.container_size_1366};
        }
        .heading {
            h2 {
                font-size: ${variables.m_heading_1366};
            }
            p {
                font-size: ${variables.l_paragraph_1366};
            }
        }
        .right {
            .box-container {
                .box {
                    .img-container {
                        img {
                            width: 85px;
                        }
                    }
                    h3 {
                        line-height: 18px;
                        font-size: ${variables.l_paragraph_1366};
                    }
                    p {
                        line-height: 16px; 
                        font-size: ${variables.btn_text_1366};
                    }
                }
            }
        }
        
    }   

    @media screen and (max-width: 1100px) {
        padding: 100px 0 0;
        .container {
            max-width: ${variables.container_size_1100};
        }
        .heading {
            h2 {
                font-size: ${variables.m_heading_1100};
            }
            p {
                font-size: ${variables.l_paragraph_1100};
            }
        }
    }

    @media screen and (max-width: 900px) {
        .container {
            max-width: ${variables.container_size_800};
        }
        .heading {
            h2 {
                font-size: ${variables.m_heading_800};
            }
            p {
                font-size: ${variables.l_paragraph_800};
            }
        }
        .right {
            width: 100%;
            .box-container {                
                .box {
                    width: 45%;
                    padding: 3%;
                    margin-bottom: 30px;
                    .img-container {
                        img {
                            width: 80px;
                        }
                    }
                    h3 {
                        font-size: ${variables.l_paragraph_800};
                        line-height: 16px;
                    }
                    p {
                        font-size: ${variables.btn_text_1100};
                        line-height: 15px;
                    }
                }                
            }
        }
    }

    @media screen and (max-width: 767px) {
        padding: 70px 0;
        .container {
            max-width: ${variables.container_size_500};
        }
        .heading {
            h2 {
                font-size: ${variables.m_heading_500};
            }
            p {
                font-size: ${variables.l_paragraph_500};
            }
        }
        .right {
            float: unset;
            max-width: 300px;
            margin: 0 auto;
            .box-container {
                box-shadow: none;
                .box {
                    margin: 30px 0 0 0;
                    width: 100%;
                    padding: 20px;
                }
                h3 {
                    font-size: ${variables.l_paragraph_500};
                }
                p {
                    font-size: ${variables.btn_text_500};
                }
            }
        }
    }
`;

export const SectionSecond = styled.section`
    margin: 0 0 0 0;
    padding: 150px 0;
    transform: skew(0deg, -5deg);
    position: relative;
    z-index: 2;
    background-color: #fff;
    .section-lining {
        background: #00E1F8;
        position: absolute;
        width: 35%;        
        .dotted {
            width: 100%;
            border-top: 2.5px dashed white;
        }
    }
    .lining-before {
        top: 0;
        left: 0;
        padding: 10px 30px 10px 0px;
    }
    .lining-after {
        bottom: 0;
        right: 0;
        padding: 10px 0px 10px 30px;
    }
    .container {
        max-width: ${variables.container_size};
        justify-content: space-between;
    }
    .skew-container-inner {
        transform: skew(0deg, 5deg);
    }
    .left {
        width: 65%;
        .box-container {            
            border-radius: 8px;
            .box {
                box-shadow: 0.4px 0.4px 30px -4px rgba(0,0,0,0.3);
                background: #F6FAFF;
                box-sizing: border-box;
                padding: 3%;
                width: 48%;  
                margin-bottom: 4%;
                border-radius: 15px;
                .text {
                    justify-content: space-between;
                    margin: 0 0 3% 0;
                    h3 {
                        text-align: center;
                        font-size: ${variables.l_paragraph};
                        margin: 0;
                        font-weight: 600;
                    }
                }
                .image-container {
                    img {
                        width: 100%;
                    }              
                }
            }
        }
    }
    .right {
        width: 30%;
        h2 {
            font-size: ${variables.m_heading};
        }
        p {
            font-size: ${variables.l_paragraph};
            line-height: 23px;
            font-weight: 300;
            margin: 7% 0;
        }
        .btn {
            background: ${variables.cyan};
            color: #fff;
            border-radius: 40px;
            font-weight: 600;
            font-size: ${variables.btn_text};
            padding: ${variables.btn_padding_y} ${variables.btn_padding_x};
            transition: 0.3s;  
            svg {
                margin: 0 0 0 30px;
            }
            &:hover {
                background-color: #1F2121;
            }
        }
    }

    @media screen and (min-width: 1793px) {
        padding: 200px 0;
        .container {
            max-width: ${variables.container_size_1920};
        }
        .left {
            width: 63%;
            .box-container {
                .box {                    
                    .text {
                        h3 {
                            font-size: ${variables.l_paragraph_1920};
                        }
                    }
                }
            }
        }
        .right {
            width: 29%;
            h2 {
                font-size: ${variables.m_heading_1920};                
            }
            p {
                font-size: ${variables.l_paragraph_1920};
                line-height: 29px;
                width: 74%;
            }
            .btn {
                font-size: ${variables.btn_text_1920};
                padding: ${variables.btn_padding_y_1920} ${variables.btn_padding_x_1920};
            }
        }
    }

    @media screen and (max-width: 1792px) and (min-width: 1601px) {
        padding: 170px 0;
        .container {
            max-width: ${variables.container_size_1792};
        }
        .left {
            .box-container {
                .box {
                    .text {
                        h3 {
                            font-size: ${variables.l_paragraph_1792};
                        }
                        .growthSvg {
                            width: 40px;
                        }
                    }
                }
            }
        }
        .right {
            h2 {
                font-size: ${variables.m_heading_1792};
            }
            p {
                font-size: ${variables.l_paragraph_1792};
                line-height: 26px;
            }
            .btn {
                font-size: ${variables.btn_text_1792};
                padding: ${variables.btn_padding_y_1792} ${variables.btn_padding_x_1792};
            }
        }
    }

    @media screen and (max-width: 1441px) {
        .container {
            max-width: ${variables.container_size_1440};
        }
        .left {
            .box-container {
                .box {
                    .text {
                        h3 {
                            font-size: ${variables.l_paragraph_1440};
                        }
                        .growthSvg {
                            
                        }
                    }
                }
            }
        }
        .right {
            h2 {
                font-size: ${variables.m_heading_1440};
            }
            p {
                font-size: ${variables.l_paragraph_1440};
            }
            .btn {
                font-size: ${variables.btn_text_1440};
            }
        }
    }

    @media screen and (max-width: 1367px) {
        .container {
            max-width: ${variables.container_size_1366};
        }
        .left {
            .box-container {
                .box {
                    .text {
                        h3 {
                            font-size: ${variables.l_paragraph_1366};
                        }
                        .growthSvg {
                            width: 30px;
                        }
                    }
                }
            }
        }
        .right {
            h2 {
                font-size: ${variables.m_heading_1366};
            }
            p {
                font-size: ${variables.l_paragraph_1366};
                line-height: 20px;
            }
            .btn {
                font-size: ${variables.btn_text_1366};
                padding: ${variables.btn_padding_y_1366} ${variables.btn_padding_x_1366};
            }
        }
    }

    @media screen and (max-width: 1100px) {
        padding: 100px 0;
        .container {
            max-width: ${variables.container_size_1100};
        }
        .right {
            width: 32%;
            h2 {
                font-size: ${variables.m_heading_1100};
            }
            p {
                font-size: ${variables.l_paragraph_1100};
            }
            .btn {
                font-size: ${variables.btn_text_1100};
            }
        }
    }

    @media screen and (max-width: 900px) {
        .container {
            max-width: ${variables.container_size_800};
        }
        .left {
            width: 100%;
            order: 2;
            .box-container {
                .box {
                    .text {
                        h3 {
                            font-size: 13px;
                        }
                        .growthSvg {
                            width: 25px;
                        }
                    }
                }
            }
        }
        .right {
            order: 1;
            width: 50%;
            margin-bottom: 30px;
            h2 {
                font-size: ${variables.m_heading_800};
            }
            p {
                font-size: ${variables.l_paragraph_800};
                margin: 10% 0;
            }
            .btn {
                padding: ${variables.btn_padding_y_800} ${variables.btn_padding_x_800};
            }
        }
    }

    @media screen and (max-width: 767px) {
        padding: 70px 0;
        .section-lining  {
            width: 50%;
        }
        .container {
            max-width: ${variables.container_size_500};
        }
        .left {
            width: 100%;            
            order: 2;
            max-width: 300px;
            margin: 0 auto;
            .box-container {
                box-shadow: none;
                .box {
                    box-shadow: 0.4px 0.4px 30px -4px rgba(0,0,0,0.3);
                    margin-top: 30px; 
                    width: 100%;
                    // border: 2px solid #75CEFA !important;
                    // border-radius: 8px !important;
                }
            }
        }
        .right {
            width: 100%;
            text-align: center;
            order: 1;
            h2 {
                font-size: ${variables.m_heading_500};
            }
            p {
                font-size: ${variables.l_paragraph_500};
                margin: 5% 0;
            }
            .btn {
                font-size: ${variables.btn_text_500};
                height: ${variables.l_btn_h_500};
                width: ${variables.l_btn_w_500};
            }
        }
    }
`;

export const SectionThird = styled.section`    
    bachground: #000;
    // height: 100vh;
    position: relative;
    background-repeat: no-repeat;
    color: white;
    margin: -5% 0 0 0;
    .container {
        max-width: ${variables.container_size};
        position: relative;
        overflow: auto;
    }    
    .carousel {
        // position: absolute;
        width: 100%;
        // height: 100%;     
        // top: 0;
        // left: 0;   
    }
    .carousel-indicators {
        display: none;
    }
    .carousel-control-prev, 
    .carousel-control-next  {
        opacity: 1;
    }
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        background-repeat: no-repeat;
        background-size: cover;
        width: 50px;
        height: 50px;
    }
    .carousel-control-prev-icon {
        background-image: url(${leftarr});        
    }
    .carousel-control-next-icon {
        background-image: url(${rightarr});        
    }
    .image-container {
        width: 100%;
        height: 100%;
        img {
            width: 100%;
        }
    }
    .container-wrapper {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        padding: calc(5% + 50px) 0; 
    }
    .carousel-inner {
        width: 100%;
        // height: 100%;
        .carousel-item {  
            width: 100%;
            // height: 100%;          
            background-size: cover !important;
            background-repeat: no-repeat;
            background-color: #000;       
            
            opacity: 0;
            top: 0;
            left: 0;
            width: 100%;
            display: block;
            position: absolute;
            transition: opacity 1s ease-in-out;

            &:first-of-type {
            position: relative;
            }
        }

        .active {
            opacity: 1;
        }
    }
    .right {
        width: 40%;
        float: right;
        h2 {
            font-size: ${variables.m_heading};
            line-height: 45px;
        }
        h3 {
            font-size: ${variables.l_paragraph};
            line-height: 40px;
            margin: 2% 0 4% 0;
        }
    }

    @media screen and (min-width: 1793px) {
        .container {
            max-width: ${variables.container_size_1920};
        }
        .carousel-control-prev-icon,
        .carousel-control-next-icon {
            height: 60px;
            width: 60px;
        }
        .right {
            margin: 9% 0 0 0;
            // width: 37%;
            width: 42%;
            h2 {
                font-size: ${variables.m_heading_1920};
                // line-height: 55px;
                line-height: 68px;
            }
            h3 {
                font-size: ${variables.l_paragraph_1920};
                line-height: 55px;
                margin: 3% 0 4% 0;
            }
        }
    }

    @media screen and (max-width: 1792px) and (min-width: 1601px) {
        .container {
            max-width: ${variables.container_size_1792};
        }
        .carousel-control-prev-icon,
        .carousel-control-next-icon {
            height: 60px;
            width: 60px;
        }
        .right {
            width: 42%;
            h2 {
                font-size: ${variables.m_heading_1792};
                line-height: 60px;
            }
            h3 {
                font-size: ${variables.l_paragraph_1792};
                line-height: 50px;
                margin: 3% 0 4% 0;
            }
        }
    }

    @media screen and (max-width: 1441px) {
        .container {
            max-width: ${variables.container_size_1440};
        }
        .right {
            h2 {
                font-size: ${variables.m_heading_1440};
            }
            h3 {
                font-size: ${variables.l_paragraph_1440};
                line-height: 38px;
            }
        }
    }

    @media screen and (max-width: 1367px) {
        .container {
            max-width: ${variables.container_size_1366};
        }
        .carousel-control-prev-icon,
        .carousel-control-next-icon {
            height: 40px;
            width: 40px;
        }        
        .right {
            h2 {
                font-size: ${variables.m_heading_1366};
            }
            h3 {
                font-size: ${variables.l_paragraph_1366};
                line-height: 35px;
            }
        }
    }

    @media screen and (max-width: 1100px) {
        .container {
            max-width: ${variables.container_size_1100};
        }
        .right {
            h2 {
                font-size: ${variables.m_heading_1100};
            }
            h3 {
                font-size: ${variables.l_paragraph_1100};
                line-height: 30px;
            }
        }
    }

    @media screen and (max-width: 900px) {     
        .container {
            max-width: ${variables.container_size_800};
        }
        &::before {
            display: block;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.6);
            z-index: 1;
        }
        .container-wrapper {
            z-index: 2;
        }
        .right {
            width: 60%;
            h2 {
                font-size: ${variables.m_heading_800};
            }
            h3 {
                font-size: ${variables.l_paragraph_800};
                line-height: 25px;
            }
        }
        .carousel-inner {
            .carousel-item {
                background-position: right center !important;
            }
        }
    }

    @media screen and (max-width: 767px) {
        .container {
            max-width: ${variables.container_size_500};
        }
        &::before {
            display: none;
        }
        .carousel-control-prev,
        .carousel-control-next {
            align-items: flex-end;
            padding-bottom: 12%;
            .carousel-control-prev-icon,
            .carousel-control-next-icon {
                height: 30px;
                width: 30px;
            }
        }
        .carousel-inner .carousel-item {
            background-position: 72% !important;
            position: relative;
            // background-position: 71% !important;
            &:nth-child(1) {
                background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${bg_image1});
            }
            &:nth-child(2) {
                background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${bg_image2});
            }
            &:nth-child(3) {
                background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${bg_image3});
            }
            &:nth-child(4) {
                background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${bg_image4});
            }
        }
        .image-container {
            display: none;                    
        }
        .container-wrapper {
            position: relative;
            left: 0;
            top: 0;
            z-index: 1;
            // padding: calc(5% + 50px) 0 80%;
            // padding: calc(5% + 100px) 0;
            height: 440px;
            // height: 300px;
        }
        .right {
            width: 100%;
            float: none;
            h2 {
                font-size: ${variables.m_heading_500};
            }
            h3 {
                font-size: ${variables.l_paragraph_500};
            }
        }
    }
`;

export const SectionFourth = styled.section`
    position: relative;
    z-index: 2;    
    margin: 0 0 0 0;
    transform: skew(0deg, -5deg);
    padding: 150px 0 300px;
    background: #fff;
    // margin: -84px 0 0 0;
    margin: -5% 0 0 0;
    .skew-container-inner {
        transform: skew(0deg, 5deg);
    }
    .clearFix::after {
        content: "";
        display: block;
        clear: both;
    }
    .section-lining {
        background: rgba(0, 0, 0, 0.67);
        position: absolute;
        width: 35%;  
        z-index: -1;      
        .dotted {
            width: 100%;
            border-top: 2.5px dashed white;
        }
    }
    .lining-before {
        top: 0;
        left: 0;
        padding: 10px 30px 10px 0px;
    }
    .lining-after {
        bottom: 0;
        right: 0;
        padding: 10px 0px 10px 30px;
    }
    .container {
        max-width: ${variables.container_size};
    }
    .left {
        width: 32%;
        float: left;        
        h2 {
            font-size: ${variables.m_heading};
        }
        h3 {
            font-size: ${variables.s_heading};
            line-height: 45px;
            margin: 2% 0 6% 0;
        }
        p,li {
            font-size: ${variables.l_paragraph};
            font-weight: 400;
            line-height: 35px;
            width: 119%;
        }
        li {
            margin-bottom: 3%;
        }
    }
    .right {
        float: right;
        width: 55%;
        img {
            width: 100%;
        }
    }
    .card-container-2 {
        width: 100%;
        position: absolute;
        z-index: 10;
        bottom: -370px;
        left: 0;
        .card {
            max-width: 870px;
            background: #F6FAFF;
            box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.14);
            border: none;
            border-radius: 25px;
            padding: 40px 70px;
            h2 {
                font-size: ${variables.m_heading2};
                width: 68%;
                margin: 0 auto;
            }
            p {
                font-size: ${variables.l_paragraph};
                font-weight: 400;
                margin: 15px 0 0 0;                
            }
            form {
                margin: 35px 0 0 0;
                .btn {
                    padding: ${variables.btn_padding_y} ${variables.btn_padding_x};
                    border-radius: 40px;
                    font-weight: 600;
                    font-size: ${variables.btn_text};
                    color: ${variables.white};
                    background: ${variables.cyan};
                    transition: 0.3s;  
                    svg {
                        margin: 0 0 0 30px;
                    }
                    &:hover {
                        background-color: #1F2121;
                    }
                }
            }
        }
    }
    
    @media screen and (min-width: 1793px) { 
        padding: 200px 0 400px;
        // margin: -135px 0 0 0;
        .container {
            max-width: ${variables.container_size_1920};
        }
        .left {
            width: 34%;
            h2 {
                font-size: ${variables.m_heading_1920};
                line-height: 55px;
            }
            h3 {
                font-size: ${variables.s_heading_1920};
                line-height: 55px;
                margin: 4% 0 5% 0;
            }
            p,li {
                font-size: ${variables.l_paragraph_1920};
                line-height: 40px;
            }
        }
        .card-container-2 {
            bottom: -470px;
            .card {
                box-sizing: border-box;
                max-width: 1054px;
                padding: 50px 36px;
                h2 {
                    font-size: ${variables.m_heading2_1920};
                }
                p{
                    font-size: ${variables.l_paragraph_1920};              
                }
                form {
                    .btn {
                        padding: ${variables.btn_padding_y_1920} ${variables.btn_padding_x_1920};                      
                        font-size: ${variables.btn_text_1920};
                    }
                }
            }
        }
    }

    // @media screen and (min-width: 3000px) {
    //     margin: -200px 0 0;
    // }

    @media screen and (max-width: 1792px) and (min-width: 1601px) { 
        padding-top: 170px;
        .container {
            max-width: ${variables.container_size_1792};
        }
        .left {
            width: 33%;     
            h2 {
                font-size: ${variables.m_heading_1792};
                line-height: 50px;
            }
            h3 {
                font-size: ${variables.s_heading_1792};
                line-height: 50px;
                margin: 2% 0 5% 0;
            }
            p,li {
                font-size: ${variables.l_paragraph_1792};
                line-height: 37px;
            }
        }
        .card-container-2 {
            bottom: -370px;
            .card {
                max-width: 900px;
                h2 {
                    font-size: ${variables.m_heading2_1792};
                    width: 77%;
                }   
                p {
                    font-size: ${variables.l_paragraph_1792};
                }  
                form {
                    .btn {
                        padding: ${variables.btn_padding_y_1792} ${variables.btn_padding_x_1792};
                    }
                }           
            }
        }
    }

    @media screen and (max-width: 1441px) { 
        .container {
            max-width: ${variables.container_size_1440};
        }
        .left {
            width: 31%;
            h2 {
                font-size: ${variables.m_heading_1440};
            }
            h3 {
                font-size: ${variables.s_heading_1440};
                line-height: 38px;
            }
            p,li {
                font-size: ${variables.l_paragraph_1440};
                line-height: 30px;
                width: 127%;
            }
        }
        .right {
            width: 57%;
        }
        .card-container-2 {
            .card {
                max-width: 800px;
                h2 {
                    font-size: ${variables.m_heading2_1440};
                }   
                p {
                    font-size: ${variables.l_paragraph_1440};
                }             
            }
        }
    }

    @media screen and (max-width: 1367px) { 
        .container {
            max-width: ${variables.container_size_1366};
        }
        .left {
            width: 32%;
            h2 {
                font-size: ${variables.m_heading_1366};
            }
            h3 {
                font-size: ${variables.s_heading_1366};
                line-height: 35px;
            }
            p,li {
                font-size: ${variables.l_paragraph_1366};
                line-height: 30px;
            }
        }
        .right {
            width: 56%;
        }
        .card-container-2 {
            .card {
                max-width: 700px;
                padding: 30px 50px;
                h2 {
                    font-size: ${variables.m_heading2_1366};
                }   
                p {
                    font-size: ${variables.l_paragraph_1366};
                }
                form {
                    .btn {
                        padding: ${variables.btn_padding_y_1366} ${variables.btn_padding_x_1366};
                    }
                }              
            }
        }
    }

    @media screen and (max-width: 1100px) {
        padding: 100px 0 200px;
        .container {
            max-width: ${variables.container_size_1100};
        }
        .left {
            h2 {
                font-size: ${variables.m_heading_1100};
            }
            h3 {
                font-size: ${variables.s_heading_1100};
                line-height: 28px;
            }
            p,li {
                font-size: ${variables.l_paragraph_1100};
                line-height: 28px;
            }
        }
        .card-container-2 {
            bottom: -270px;
            .card {
                max-width: 600px;
                padding: 30px 40px;                
                h2 {
                    font-size: ${variables.m_heading2_1100};
                }   
                p {
                    font-size: ${variables.l_paragraph_1100};
                } 
                form {
                    .btn {
                        font-size: ${variables.btn_text_1100};
                    }
                }           
            }
        }
    }

    @media screen and (max-width: 900px) {
        .container {
            max-width: ${variables.container_size_800};
        }
        .left {
            width: 38%;
            h2 {
                font-size: ${variables.m_heading_800};
            }
            h3 {
                font-size: ${variables.s_heading_800};
                line-height: 26px;
            }
            ul {
                padding-left: 20px;
            }
            p,li {
                font-size: ${variables.l_paragraph_800};
                line-height: 25px;
                width: 100%;
            }
        }
        .card-container-2 {
            .card {
                max-width: 500px;
                h2 {
                    font-size: ${variables.m_heading2_800};
                }   
                p {
                    font-size: ${variables.l_paragraph_800};
                }  
                form {
                    .btn {
                        padding: ${variables.btn_padding_y_800} ${variables.btn_padding_x_800};
                    }
                }           
            }
        }
    }

    @media screen and (max-width: 767px) {
        padding: 70px 0 200px;
        .section-lining {
            width: 50%;
        }
        .container {
            max-width: ${variables.container_size_500};
        }
        .left {
            float: unset;
            width: 100%;
            h2 {
                font-size: ${variables.m_heading_500};
            }
            h3 {
                font-size: ${variables.s_heading_500};
                line-height: 24px;
            }
            p,li {
                font-size: ${variables.l_paragraph_500};
                line-height: 22px;                
            }
        }
        .right {
            float: unset;
            width: 100%;
            margin: 20px auto 0;
        }
        .card-container-2 {
            .card {
                max-width: 300px;
                padding: 30px;
                h2 {
                    font-size: ${variables.m_heading2_500};
                }   
                p {
                    font-size: ${variables.l_paragraph_500};
                }  
                form {
                    .btn {
                        font-size: ${variables.btn_text_500};
                    }
                }           
            }
        }
    }
`;