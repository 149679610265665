import { connect } from 'react-redux';
import { Props } from './Redux/Models';
import { BrowserRouter as Router } from "react-router-dom";
import { StateModel } from './Redux/Models/Base.Models';
import { ThemeProvider} from '@mui/material';
import { PrimaryTheme } from './Theme';
import Auth from './Routes/Auth';

const App = (props:Props) => {
  return (
    <ThemeProvider theme={PrimaryTheme}>
        <Router>
          <Auth/>
        </Router>
    </ThemeProvider>
  )
}

const mapStateToProps = (state:StateModel) => {
  return {
      isAuthenticated: state.Auth.isAuthenticated,
      isExpired: state.Auth.isExpired,
      user: state.Auth.user
  };
};

export default connect(mapStateToProps)(App);


