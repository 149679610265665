import styled from "styled-components";
import variables from '../../../Style-js/variable';

export const BannerSection = styled.section`
    margin: 80px 0 0 0;
    background: #F6FAFF;
    padding: 0 0 calc(5% + 40px);
    .container {
        max-width: ${variables.container_size};
    } 
    .back {
        margin: 0;
        padding: 50px 0;
        a {
            // color: #1F2121;
            color: #00E1F8;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 0.5px;
            svg {
                margin-right: 10px;
                width: 19px;
                position: relative;
                top: -2px;
            }
        }
    }
    h1 {
        font-weight: 700;
        font-size: 35px;
    }
    h2 {
        font-weight: 400;
        font-size: 27px;
        margin: 20px 0 0 0;
    }
    button {        
        background: ${variables.cyan};
        border-radius: 40px;
        color: ${variables.white} !important;
        font-weight: 600;
        font-size: ${variables.btn_text};
        margin-top: 50px;
        letter-spacing: 0.5px;
        transition: 0.3s;  
        padding: ${variables.btn_padding_y} ${variables.btn_padding_x};
        svg {
            margin: 0 0 0 30px;
        }
        &:hover {
            background-color: #1F2121;
        }   
    }
    
    @media screen and (min-width: 1793px) {
        margin: 94px 0 0 0;
        .container {
            max-width: ${variables.container_size_1920};
        } 
        .back {
            a {
                font-size: 20px;
            }
        }
        h1 {
            font-size: 48px;
        }
        h2 {
            font-size: 36px;
            margin: 30px 0 0 0;           
        } 
        button {
            font-size: ${variables.btn_text_1920};
            padding: ${variables.btn_padding_y_1920} ${variables.btn_padding_x_1920};
            margin-top: 70px;
        }
    }

    @media screen and (max-width: 1792px) and (min-width: 1601px) {
        .container {
            max-width: ${variables.container_size_1792};
        }
        .back {
            a {
                font-size: 20px;
            }
        }
        h1 {
            font-size: 40px;
        }
        h2 {
            font-size: 32px;
            margin: 25px 0 0 0;          
        } 
        button {
            font-size: ${variables.btn_text_1792};
            padding: ${variables.btn_padding_y_1792} ${variables.btn_padding_x_1792};
            margin-top: 60px;
        }
    }

    @media screen and (max-width: 1441px) {
        .container {
            max-width: ${variables.container_size_1440};
        }
        h1 {
            font-size: 32px;
        }
        h2 {
            font-size: 25px;        
        } 
        button {            
            font-size: ${variables.btn_text_1440};
        }
    }

    @media screen and (max-width: 1367px) {
        margin: 75px 0 0 0;
        .container {
            max-width: ${variables.container_size_1366};
        }
        .back {
            a {
                font-size: 17px;
            }
        }
        h1 {
            font-size: 30px;
        }
        h2 {
            font-size: 23px;
            margin: 15px 0 0 0;
        }
        button {
            padding: ${variables.btn_padding_y_1366} ${variables.btn_padding_x_1366};
            font-size: ${variables.btn_text_1366};
            margin-top: 40px;
        }
    }

    @media screen and (max-width: 1100px) {
        .container {
            max-width: ${variables.container_size_1100};
        }
        .back {
            padding: 30px 0;
            a {
                font-size: 16px;
            }
        }
        h1 {
            font-size: 28px;
        }
        h2 {
            font-size: 21px;
        }
        button {
            font-size: ${variables.btn_text_1100};
        }
    }

    @media screen and (max-width: 900px) {
        .container {
            max-width: ${variables.container_size_800};
        }
        h1 {
            font-size: 25px;
        }
        h2 {
            font-size: 20px;
        }
        button {
            padding: ${variables.btn_padding_y_800} ${variables.btn_padding_x_800};
        }
    }

    @media screen and (max-width: 767px) {
        .back {
            a {
                font-size: 15px;
            }
        }
        .container {
            max-width: ${variables.container_size_500};
        }
        h1 {
            font-size: 20px;
        }
        h2 {
            font-size: 17px;
        }
        button {
            letter-spacing: 0.5px;
            font-size: ${variables.btn_text_500};
        }
    }
`;

export const SectionFirst = styled.section`
    padding: 150px 0 200px;
    background: #fff;
    margin: -5% 0 0 0;
    position: relative;
    z-index: 2;
    transform: skew(0deg, -5deg);
    .skew-inner-container {
        transform: skew(0deg, 5deg);                
    }
    .section-lining {
        z-index: -1;
        position: absolute;
        width: 35%;          
        .dotted {
            width: 100%;
            border-top: 2.5px dashed white;
        }
    }
    .lining-after {
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.67);
        padding: 10px 0px 10px 30px;
    }
    .container {
        max-width: ${variables.container_size};
    } 
    // .description {
    //     h3 {
    //         font-size: 30px;
    //         font-weight: 700;
    //         margin-bottom: 60px;
    //     }
    //     p {
    //         font-size: 24px;
    //         margin-top: 40px;
    //         line-height: 43px;
    //     }
    // }

    .description {
        strong {
            font-size: 24px;
        }
        p {
            font-size: 24px;
            margin-top: 40px;
            line-height: 43px;
        }
        ul {
            font-size: 24px;
            line-height: 43px;
            li {                 
                margin-bottom: 15px;                
            }           
        }
    }

    .responsibility {
        h3 {
            font-size: 30px;
            font-weight: 700;
            margin-bottom: 60px;
            margin: 70px 0 40px;
        }
        p {
            font-size: 24px;
        }
        ul {
            list-style-type: none;
            margin-top: 50px;
            li {
                line-height: 43px; 
                margin-bottom: 15px;
                img { 
                    width: 25px;
                    margin-right: 40px;
                    position: relative;
                    top: 11px;
                }
            }           
        }
    }
    .card-container-2 {
        width: 100%;
        position: absolute;
        z-index: 10;
        // bottom: -420px;
        bottom: -260px;
        left: 0;
        .card {
            max-width: 870px;
            background: #F6FAFF;
            box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.14);
            border: none;
            border-radius: 25px;
            padding: 40px 70px;
            h2 {
                font-size: ${variables.m_heading2};
                width: 68%;
                margin: auto;
            }
            p {
                font-size: ${variables.l_paragraph};
                font-weight: 400;
                margin: 15px 0 0 0;                
            }
            form {
                margin: 35px 0 0 0;
                .btn {
                    border-radius: 40px;
                    font-weight: 600;
                    font-size: ${variables.btn_text};
                    color: ${variables.white};
                    background: ${variables.cyan};
                    transition: 0.3s;  
                    padding: ${variables.btn_padding_y} ${variables.btn_padding_x};
                    svg {
                        margin: 0 0 0 30px;
                    }
                    &:hover {
                        background-color: #1F2121;;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 1793px) {
        .container {
            max-width: ${variables.container_size_1920};
        }  
        // .description {
        //     h3 {
        //         font-size: 36px;
        //         margin-bottom: 80px;
        //     }
        //     p {
        //         font-size: 28px;
        //         line-height: 56px;
        //         margin-top: 60px;
        //     }
        // }

        .description {
            strong {
                font-size: 28px;
            }
            p {
                font-size: 28px;
                line-height: 56px;
                margin-top: 60px;
            }
            ul {
                font-size: 28px;
                line-height: 56px;
                li {
                    margin-bottom: 20px;
                }           
            }
        }

        .responsibility {
            h3 {
                font-size: 36px;
                margin: 80px 0 50px;
            }
            p {
                font-size: 28px;
                line-height: 56px;
            }
            ul {
                margin-top: 60px;
                li {
                    margin-bottom: 20px;
                    img { 
                        top: 14px;
                        width: 30px;
                        margin-right: 50px;
                    }
                }           
            }
        }
        .card-container-2 {
            bottom: -320px;
            .card {
                box-sizing: border-box;
                max-width: 1054px;
                // min-height: 372px;
                padding: 50px 36px;
                h2 {
                    font-size: ${variables.m_heading2_1920};
                }
                p{
                    font-size: ${variables.l_paragraph_1920};              
                }
                form {
                    .btn {
                        padding: ${variables.btn_padding_y_1920} ${variables.btn_padding_x_1920};                        
                        font-size: ${variables.btn_text_1920};                        
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1792px) and (min-width: 1601px) {
        .container {
            max-width: ${variables.container_size_1792};
        }
        // .description {
        //     h3 {
        //         font-size: 33px;
        //         margin-bottom: 70px;
        //     }
        //     p {
        //         font-size: 26px;
        //         line-height: 50px;
        //         margin-top: 50px;
        //     }
        // } 

        .description {
            strong {
                font-size: 26px;
            }
            p {
                font-size: 26px;
                line-height: 50px;
                margin-top: 50px;
            }
            ul {
                font-size: 26px;
                line-height: 50px;
                li {
                    margin-bottom: 20px;
                }           
            }
        }

        .responsibility {
            h3 {
                font-size: 33px;
            }
            p {
                font-size: 26px;
                line-height: 50px;
            }
            ul {
                margin-top: 60px;
                li {
                    margin-bottom: 20px;
                    img { 
                        top: 14px;
                        width: 30px;
                        margin-right: 50px;
                    }
                }           
            }
        }
        .card-container-2 {
            bottom: -300px;
            .card {
                max-width: 900px;
                // min-height: 340px;
                h2 {
                    font-size: ${variables.m_heading2_1792};
                    width: 77%;
                }   
                p {
                    font-size: ${variables.l_paragraph_1792};
                }   
                form {
                    .btn {
                        padding: ${variables.btn_padding_y_1792} ${variables.btn_padding_x_1792};                        
                        font-size: ${variables.btn_text_1792};                        
                    }
                }          
            }
        }       
    }

    @media screen and (max-width: 1441px) {
        .container {
            max-width: ${variables.container_size_1440};
        }
        // .description {
        //     h3 {
        //         margin-bottom: 50px;
        //         font-size: 27px;
        //     }
        //     p {
        //         font-size: 22px;
        //         line-height: 40px;
        //     }
        // }

        .description {
            strong {
                font-size: 22px;
            }
            p {
                font-size: 22px;
                line-height: 40px;
            }
            ul {
                font-size: 22px;
                line-height: 40px;
            }
        }

        .responsibility {
            h3 {
                font-size: 27px;
            }
            p {
                font-size: 22px;
                line-height: 40px;
            }
        }
        .card-container-2 {
            bottom: -290px;
            .card {
                max-width: 800px;
                h2 {
                    font-size: ${variables.m_heading2_1440};
                }   
                p {
                    font-size: ${variables.l_paragraph_1440};
                }    
                form {
                    .btn {                        
                        font-size: ${variables.btn_text_1440};                        
                    }
                }         
            }
        }
    }

    @media screen and (max-width: 1367px) {
        .container {
            max-width: ${variables.container_size_1366};
        }
        // .description {
        //     h3 {
        //         margin-bottom: 40px;
        //         font-size: 24px;
        //     }
        //     p {
        //         font-size: 20px;
        //         line-height: 37px;
        //     }
        // }

        .description {
            strong {
                font-size: 20px;
            }
            p {
                font-size: 20px;
                line-height: 37px;
            }
            ul {
                font-size: 20px; 
                line-height: 37px;
            }
        }

        .responsibility {
            h3 {
                font-size: 24px;
                margin: 60px 0 30px;
            }
            p {
                font-size: 20px;
                line-height: 37px;
            }
            ul {
                margin-top: 40px;
                li {                    
                    img { 
                        width: 20px;
                        top: 9px;
                        margin-right: 30px;
                    }
                }           
            }
        }
        .card-container-2 {
            .card {
                max-width: 700px;
                padding: 30px 50px;
                h2 {
                    font-size: ${variables.m_heading2_1366};
                }   
                p {
                    font-size: ${variables.l_paragraph_1366};
                } 
                form {
                    .btn {
                        padding: ${variables.btn_padding_y_1366} ${variables.btn_padding_x_1366};                        
                        font-size: ${variables.btn_text_1366};                        
                    }
                }            
            }
        }
    }

    @media screen and (max-width: 1100px) {
        padding: 100px 0 130px;
        .container {
            max-width: ${variables.container_size_1100};
        }
        // .description {
        //     h3 {
        //         font-size: 22px;
        //     }
        //     p {
        //         font-size: 18px;
        //         line-height: 34px;
        //         margin-top: 25px;
        //     }
        // }

        .description {
            strong {
                font-size: 18px;
            }
            p {
                font-size: 18px;
                line-height: 34px;
                margin-top: 25px;
            }
            ul {
                font-size: 18px;
                line-height: 34px;
            }
        }

        .responsibility {
            h3 {
                font-size: 22px;
            }
            p {
                font-size: 18px;
                line-height: 34px; 
            }
        }
        .card-container-2 {
            bottom: -200px;
            .card {
                max-width: 600px;
                padding: 30px 40px;
                h2 {
                    font-size: ${variables.m_heading2_1100};
                }   
                p {
                    font-size: ${variables.l_paragraph_1100};
                }  
                form {
                    .btn {
                        font-size: ${variables.btn_text_1100};
                    }
                }           
            }
        }
    }

    @media screen and (max-width: 900px) {
        .container {
            max-width: ${variables.container_size_800};
        }
        // .description {
        //     h3 {
        //         font-size: 20px;
        //     }
        //     p {
        //         font-size: 16px;
        //         line-height: 30px;
        //     }
        // }

        .description {
            strong {
                font-size: 16px;
            }
            p {
                font-size: 16px;
                line-height: 30px;
            }
            ul {
                font-size: 16px;
                line-height: 30px;
                li {
                    margin-bottom: 10px;
                }           
            }
        }

        .responsibility {
            h3 {
                font-size: 20px;
                margin: 50px 0 30px;
            }
            p {
                font-size: 16px;
                line-height: 30px;
            }
            ul {
                margin-top: 30px;
                li {
                    margin-bottom: 10px;
                    img { 
                        width: 18px;
                        top: 7px; 
                        margin-right: 20px;
                    }
                }           
            }
        }
        .card-container-2 {
            .card {
                max-width: 500px;
                h2 {
                    font-size: ${variables.m_heading2_800};
                }   
                p {
                    font-size: ${variables.l_paragraph_800};
                }  
                form {
                    .btn {
                        padding: ${variables.btn_padding_y_800} ${variables.btn_padding_x_800};
                    }
                }           
            }
        }
    }

    @media screen and (max-width: 767px) {
        padding: 70px 0 100px;
        .container {
            max-width: ${variables.container_size_500};
        }
        .section-lining {
            width: 50%;
        }
        // .description {
        //     h3 {
        //         font-size: 18px;
        //         margin-bottom: 30px;
        //     }
        //     p {
        //         font-size: 15px;
        //         line-height: 27px;
        //         margin-top: 20px;
        //     }
        // }

        .description {
            strong {
                font-size: 15px;
            }
            p {
                font-size: 15px;
                line-height: 27px;
                margin-top: 20px;
            }
            ul {
                font-size: 15px;
                line-height: 27px;
                padding: 0;
                li {
                    margin-bottom: 0px;
                }           
            }
        }

        .responsibility {
            h3 {
                font-size: 18px;
                margin: 30px 0 20px;
            }
            p {
                font-size: 15px;
                line-height: 27px; 
            }
            ul {
                padding: 0;
                li {
                    margin-bottom: 0px;
                }           
            }
        }
        .card-container-2 {
            .card {
                max-width: 300px;
                padding: 30px;
                h2 {
                    font-size: ${variables.m_heading2_500};
                }   
                p {
                    font-size: ${variables.l_paragraph_500};
                }  
                form {
                    .btn {
                        font-size: ${variables.btn_text_500};
                    }
                }           
            }
        }
    }
`;
