import { Component } from 'react';
import { SectionFirst } from './Styled';
import bullet from '../../../assets/common-icons/list-bullet.png';

export class SectionOne extends Component {
    render() {
        return (
            <SectionFirst>
                <div className="container skew-inner-container">
                    <div className="text-section">
                        <p>4.screen GmbH<br />
                        Sailerstraße 17<br />
                        80809 München<br /><br />

                        Phone: +49 89 / 23 512 824<br />
                        E-Mail: info@4screen.com<br /><br />

                        Managing directors authorized to represent the company:<br />
                        Fabian Beste, Simon Hecker, Christoph Mahlert<br /><br />

                        Registration court: Amtsgericht München<br />
                        Registration number: HRB 259171<br />
                        VAT number: DE334377104</p>                        
                    </div>

                    <div className="text-section">
                        <h3>Exclusion of liability:</h3>
                        <p>We make no guarantee that the content and data are correct, complete or up to date. We hold no liability, especially not for possible damages or consequences, that result from usage of the content offered on this site. 4.screen GmbH is not responsible for content of webpages that are referenced directly or indirectly by links on this website. The penal and civil responsibility for these sites is fully held by the respective site owners.</p>
                    </div>

                    <div className="text-section security-section">
                        <h3>Copyright:</h3>
                        <p>The company 4.screen GmbH reserves the right to update, change or add any information and data presented on the website without any announcement. Layout as well as text and graphics on all pages on www.4.screen.tech and on any aliased websites are protected by copyright of 4.screen GmbH. Usage of information or data especially of text, text fragments or images requires the explicit written permission by 4.screen GmbH.</p>
                    </div>
                    <div className="text-section security-section">
                        <h3>Picture credits:</h3>
                        <p>&copy; Copyright 4.screen GmbH, München. All rights reserved.</p>
                    </div>
                </div>
                <div className="section-lining lining-after"><div className="dotted"></div></div>
            </SectionFirst>
        )
    }
}

export default SectionOne;