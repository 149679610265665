import {Component} from 'react';
import {SectionThird} from './Styled';
import monetizeImage from '../../../assets/Automotive/J.webp';
import innovationImage from '../../../assets/Automotive/K.webp';
import brandImage from '../../../assets/Automotive/L.webp';

export class SectionThree extends Component {
    render() {
        return (
            <SectionThird>
                <div className="section-lining lining-before"><div className="dotted"></div></div>
                <div className="container skew-inner-container">
                    <div className="f-row d-flex flex-wrap image-right">
                        <div className="text-box">
                            <h2>Direct Monetization</h2>
                            <h3>Monetization of Existing Use Cases</h3>
                            <p>Make it easier for drivers to discover their favorite locations by offering a <span>branded pin</span>.
                                Let them know when and where to get gas, charge or park through in-car <span>recommendations</span>. 
                                Show them the most convenient route with our <span>recommendations</span> integrated into your <span>e-route planner</span>.
                            </p>
                            <a href='/contact-us' className="btn">Book a Demo<svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.03516 6.4314C6.48287 6.4314 6.03516 6.87911 6.03516 7.4314C6.03516 7.98368 6.48287 8.4314 7.03516 8.4314L7.03516 6.4314ZM8.37202 8.1385C8.76255 7.74798 8.76255 7.11481 8.37202 6.72429L2.00806 0.360328C1.61754 -0.0301964 0.984371 -0.0301964 0.593847 0.360328C0.203322 0.750852 0.203322 1.38402 0.593847 1.77454L6.2507 7.4314L0.593846 13.0882C0.203322 13.4788 0.203322 14.1119 0.593846 14.5025C0.98437 14.893 1.61754 14.893 2.00806 14.5025L8.37202 8.1385ZM7.03516 8.4314L7.66491 8.4314L7.66491 6.4314L7.03516 6.4314L7.03516 8.4314Z" fill="white"></path></svg></a>
                        </div>
                    
                        <div className="image-container">
                            <img src={monetizeImage} alt="" />
                        </div>
                    </div>
                    <div className="f-row f-row-swapping d-flex flex-wrap image-left">
                        <div className="image-container swap-order-2">
                            <img src={innovationImage} alt="" />
                        </div>
                        <div className="text-box">
                            <h2>Brand Differentiation</h2>
                            <h3>Sponsored Parking &amp; Charging</h3>                            
                            <p>Explore the endless potential of value-added services for your drivers by providing cross-industry offers. A trip to a new restaurant can be incentivized by <span>free parking</span> paid by the restaurant, and a day at the mall can be made stress free with <span>free charging</span> paid by the store that the driver is visiting.</p>
                            <a href='/contact-us' className="btn">Book a Demo<svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.03516 6.4314C6.48287 6.4314 6.03516 6.87911 6.03516 7.4314C6.03516 7.98368 6.48287 8.4314 7.03516 8.4314L7.03516 6.4314ZM8.37202 8.1385C8.76255 7.74798 8.76255 7.11481 8.37202 6.72429L2.00806 0.360328C1.61754 -0.0301964 0.984371 -0.0301964 0.593847 0.360328C0.203322 0.750852 0.203322 1.38402 0.593847 1.77454L6.2507 7.4314L0.593846 13.0882C0.203322 13.4788 0.203322 14.1119 0.593846 14.5025C0.98437 14.893 1.61754 14.893 2.00806 14.5025L8.37202 8.1385ZM7.03516 8.4314L7.66491 8.4314L7.66491 6.4314L7.03516 6.4314L7.03516 8.4314Z" fill="white"></path></svg></a>
                        </div>
                    </div>
                    <div className="f-row d-flex flex-wrap image-right">
                        <div className="text-box swap-order-1">
                            <h2>Pure Innovation</h2>
                            <h3>Sponsored Autonomous Rides &amp; AR Highlights</h3>
                            <p>Imagine a world where a restaurant <span>sponsor a ride</span> to their location in a robotaxi, or where private self-driving vehicles can decide where to <span>park &amp; charge</span> based on which parking garage offers the lowest rates. Contextual <span>Augmented Reality (AR) elements</span> in the windshield can be used to show passengers the location of their favorite stores and highlight local points of interest. All of this is possible thanks to technology developed by 4.screen.
                            </p>
                            <a href='/contact-us' className="btn">Book a Demo<svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.03516 6.4314C6.48287 6.4314 6.03516 6.87911 6.03516 7.4314C6.03516 7.98368 6.48287 8.4314 7.03516 8.4314L7.03516 6.4314ZM8.37202 8.1385C8.76255 7.74798 8.76255 7.11481 8.37202 6.72429L2.00806 0.360328C1.61754 -0.0301964 0.984371 -0.0301964 0.593847 0.360328C0.203322 0.750852 0.203322 1.38402 0.593847 1.77454L6.2507 7.4314L0.593846 13.0882C0.203322 13.4788 0.203322 14.1119 0.593846 14.5025C0.98437 14.893 1.61754 14.893 2.00806 14.5025L8.37202 8.1385ZM7.03516 8.4314L7.66491 8.4314L7.66491 6.4314L7.03516 6.4314L7.03516 8.4314Z" fill="white"></path></svg></a>
                        </div>
                        <div className="image-container">
                            <img src={brandImage} alt="" />
                        </div>
                    </div>
                </div>
                <div className="section-lining lining-after"><div className="dotted"></div></div>
            </SectionThird>
        )
    }
}

export default SectionThree;