import React, { Component } from 'react';
import { SectionFirst } from './Styled';
import BackendService from '../../../Services/BackendService';
import Configs from '../../../Configs';
import { BlogModel, Props, States } from '../../../Redux/Models';
import { Link } from "react-router-dom";

export class SectionOne extends Component<Props, States> {
    public myRef: React.RefObject<HTMLDivElement>;
    constructor(props: Props) {
        super(props)

        //creates a reference for your element to use
        this.myRef = React.createRef()
    }
    public state: States = {
        blogs: [],
        pageNo: 1,
        total_blogs: 0,
        total_pages: 1
    }


    getTotalPosts() {
        BackendService.getTotalBlogs().then(resp => {

            if (resp.isSuccess()) {
                this.setState((prevState: States) => {
                    var total: any = resp.getData();
                    prevState.total_blogs = resp.getData();
                    prevState.total_pages = Math.floor(total / 6);
                    if (total % 6 !== 0) {
                        prevState.total_pages = prevState.total_pages + 1;
                    }
                    return prevState;

                });
            }
        });

    }
    // http://4screenapi.intellexio.com/blogs?_sort=id:desc&_start=0&_limit=2
    getPosts() {
        let skip = (this.state.pageNo - 1) * 6;
        BackendService.getBlogs({ _sort: 'id:desc', _start: skip, _limit: 6 }).then(resp => {

            if (resp.isSuccess()) {
                this.setState((prevState: States) => {
                    prevState.blogs = resp.getData();
                    return prevState;
                });
            }
        });
    }

    componentDidMount() {
        this.getTotalPosts();
        this.getPosts();
    }

    changePageNo = (e: any) => {
        if (e !== this.state.pageNo) {
            this.setState((prevState: States) => {
                prevState.pageNo = e;
                return prevState;
            });
            setTimeout(() => {
                this.getPosts();
            }, 2);
            this.myRef?.current?.scrollIntoView();
        }
    }

    changePage = (e: any) => {
        const { pageNo, total_pages } = this.state
        if (e === 'prev' && pageNo !== 1) {
            this.setState((prevState: States) => {
                prevState.pageNo = prevState.pageNo - 1;
                return prevState;
            });
            setTimeout(() => {
                this.getPosts();
            }, 2);
            this.myRef?.current?.scrollIntoView();
        }
        if (e === 'next' && pageNo !== total_pages) {
            this.setState((prevState: States) => {
                prevState.pageNo = prevState.pageNo + 1;
                return prevState;
            });
            setTimeout(() => {
                this.getPosts();
            }, 2);
            this.myRef?.current?.scrollIntoView();
        }
    }

    render() {
        const { blogs, pageNo, total_pages } = this.state;
        return (
            <SectionFirst>
                {/* <div className="section-lining lining-before"><div className="dotted"></div></div> */}
                {/* <img src={lines} className="d-block lines-bg" alt="" /> */}
                <div className="container skew-inner-container" ref={this.myRef}>
                    <div className="headings" >
                        <p>OUR BLOGS</p>
                        <h2>Learn More About 4.screen and the In-Car Marketing Industry</h2>
                    </div>
                    <ul className="blog-list d-flex flex-wrap">
                        {blogs.map((blog: BlogModel, index: number) =>
                            <li key={index} className="box-lg-screen-spacing box-md-screen-spacing">
                                <div className="image-container" style={{
                                    backgroundImage: `url(${blog?.cover?.url})`,
                                    backgroundRepeat: "no-repeat",
                                }}>
                                    {/* <img src={ Configs.API_ROOT + blog?.cover?.url } alt=""/> */}
                                </div>
                                <div className="content">
                                    <h3> {blog?.title} </h3>
                                    <p> {blog?.shortDescription} </p>
                                    <Link to={"/blog/" + blog.id}><button className="btn">Read Article</button></Link>
                                </div>
                            </li>
                        )}

                    </ul>
                    <nav className="pagination-container" aria-label="..." >
                        <ul className="pagination justify-content-end">
                            <li className={"page-item" + (pageNo === 1 ? " disabled" : "")} onClick={() => this.changePage('prev')}>
                                <a href="#/" className="page-link no-border">
                                    <svg width="19" height="33" viewBox="0 0 19 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.5 30L3 16.5L16.5 3" stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </a>
                            </li>
                            {(() => {
                                const options = [];

                                for (let i = 1; i <= total_pages; i++) {
                                    options.push(
                                        <li className={"page-item" + (i === pageNo ? " active" : "")} onClick={() => this.changePageNo(i)}>
                                            <a href="#/" className="page-link">{i}</a>
                                        </li>
                                    );
                                }

                                return options;
                            })()}
                            <li className={"page-item" + (pageNo === total_pages ? " disabled" : "")} onClick={() => this.changePage('next')}>
                                <a href="#/" className="page-link no-border">
                                    <svg width="19" height="33" viewBox="0 0 19 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3 3L16.5 16.5L3 30" stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="section-lining lining-after"><div className="dotted"></div></div>
            </SectionFirst>
        )
    }
}

export default SectionOne;