import { Component } from 'react';
import { SectionSecond } from './Styled';
import { BlogModel, Props, States } from '../../../Redux/Models';
import BackendService from '../../../Services/BackendService';
import Configs from '../../../Configs';
import { Link } from 'react-router-dom';

export class SectionTwo extends Component<Props, States> {

    public state: States = {
        articles: []
    }

    getArticles() {
        BackendService.getArticles({ _sort: 'id:desc', _start: 0, _limit: 4 }).then(resp => {

            if (resp.isSuccess()) {
                this.setState((prevState: States) => {
                    prevState.articles = resp.getData();
                    prevState.articles = prevState.articles.filter((article: { id: any; }) => article.id !== this.props.id);
                    return prevState;
                });
            }
        });
    }

    componentDidMount() {
        this.getArticles();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.id !== this.props.id) {
            window.scrollTo(0, 0)
            this.getArticles();
        }
    }

    render() {
        const { articles } = this.state;
        return (
            <SectionSecond>
                <div className="container skew-inner-container">
                    <h2 className="heading">
                        Our Other <span className="bold">Articles</span>
                    </h2>
                    <ul className="blog-list d-flex flex-wrap">
                        {articles.map((article: BlogModel, index: number) =>
                            index < 3 ? (
                                <li key={index}>
                                    <div className="image-container" style={{
                                        backgroundImage: `url(${article?.cover?.url})`,
                                        backgroundRepeat: "no-repeat",
                                    }}>
                                        {/* <img src={ Configs.API_ROOT + article?.cover?.url } alt=""/> */}
                                    </div>
                                    <div className="content">
                                        <h3> {article?.title} </h3>
                                        <p> {article?.shortDescription} </p>
                                        <Link to={"/article/" + article.id}><button className="btn">Read Article</button></Link>
                                    </div>
                                </li>
                            ) : (<></>)
                        )}

                    </ul>
                </div>
                <div className="section-lining lining-after"><div className="dotted"></div></div>
            </SectionSecond>
        )
    }
}

export default SectionTwo;