import { Component } from 'react';
import { Outlet } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';

class HomeLayout extends Component {
    public state:any = { menuOpen: false, fixed: false, open: false };

    public render() {
        return (
            <div>
                <Header/>
                <Outlet/>
                <Footer/>
            </div>
        )
    }
}

export default HomeLayout;
