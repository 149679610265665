import BaseService from './BaseService';
import { Http } from '../Http';
import { Response, BlogModel, ArticleModel, JobFilterModel, JobModel } from '../Redux/Models';
import axios from 'axios';


export default class BackendService extends BaseService {

    public static getTotalBlogs(params: Object = {}): Promise<Response<Number>> {
        return new Promise((resolve, reject) => {
            Http.get('/blogs/count', params).then(resp => {
                let data = new Response<Number>({
                    data: resp?.data,
                    status: resp.status,
                    success: resp.status === 200,
                    message: ''
                });
                return resolve(data);
            }).catch(err => {
                return reject(err);
            });
        });
    }


    public static getBlogs(params: Object = {}): Promise<Response<Array<BlogModel>>> {
        return new Promise((resolve, reject) => {
            Http.get('/blogs', { params }).then(resp => {
                let data = new Response<Array<BlogModel>>({
                    data: resp?.data,
                    status: resp.status,
                    success: resp.status === 200,
                    message: ''
                });
                return resolve(data);
            }).catch(err => {
                return reject(err);
            });
        });
    }


    public static postContactForm(params: Object = {}): Promise<Response<any>> {

        return new Promise((resolve, reject) => {
            axios.post('https://www.4screen.eu/ContactFormMailer/NewContactFormMailer.php', params, { headers: { 'Content-Type': 'multipart/form-data' } }).then(resp => {
                let data = new Response<any>({
                    data: null,
                    status: resp.status,
                    success: resp.status == 200,
                    message: ''
                });
                return resolve(data);
            }).catch(err => {
                return reject(err);
            });
        });
    }

    public static getBlogDetails(id: any): Promise<Response<BlogModel>> {
        return new Promise((resolve, reject) => {
            Http.get('/blogs/' + id).then(resp => {
                let data = new Response<BlogModel>({
                    data: resp?.data,
                    status: resp.status,
                    success: resp.status === 200,
                    message: ''
                });
                return resolve(data);
            }).catch(err => {
                return reject(err);
            });
        });
    }

    public static getTotalArticles(params: Object = {}): Promise<Response<Number>> {
        return new Promise((resolve, reject) => {
            Http.get('/articles/count', params).then(resp => {
                let data = new Response<Number>({
                    data: resp?.data,
                    status: resp.status,
                    success: resp.status === 200,
                    message: ''
                });
                return resolve(data);
            }).catch(err => {
                return reject(err);
            });
        });
    }

    public static getArticles(params: Object = {}): Promise<Response<Array<ArticleModel>>> {
        return new Promise((resolve, reject) => {
            Http.get('/articles', { params }).then(resp => {
                let data = new Response<Array<ArticleModel>>({
                    data: resp?.data,
                    status: resp.status,
                    success: resp.status === 200,
                    message: ''
                });
                return resolve(data);
            }).catch(err => {
                return reject(err);
            });
        });
    }

    public static getArticleDetails(id: any): Promise<Response<ArticleModel>> {
        return new Promise((resolve, reject) => {
            Http.get('/articles/' + id).then(resp => {
                let data = new Response<ArticleModel>({
                    data: resp?.data,
                    status: resp.status,
                    success: resp.status === 200,
                    message: ''
                });
                return resolve(data);
            }).catch(err => {
                return reject(err);
            });
        });
    }


    public static getLocations(): Promise<Response<Array<JobFilterModel>>> {
        return new Promise((resolve, reject) => {
            Http.get('/locations').then(resp => {
                let data = new Response<Array<JobFilterModel>>({
                    data: resp?.data,
                    status: resp.status,
                    success: resp.status === 200,
                    message: ''
                });
                return resolve(data);
            }).catch(err => {
                return reject(err);
            })

        });
    }

    public static getDepartments(): Promise<Response<Array<JobFilterModel>>> {
        return new Promise((resolve, reject) => {
            Http.get('/departments').then(resp => {
                let data = new Response<Array<JobFilterModel>>({
                    data: resp?.data,
                    status: resp.status,
                    success: resp.status === 200,
                    message: ''
                })

                return resolve(data);
            }).catch(err => {
                return reject(err);
            })
        })
    }

    public static getPositions(): Promise<Response<Array<JobFilterModel>>> {
        return new Promise((resolve, reject) => {
            Http.get('/position-types').then(resp => {
                let data = new Response<Array<JobFilterModel>>({
                    data: resp?.data,
                    status: resp.status,
                    success: resp.status === 200,
                    message: ''
                })
                return resolve(data);
            }).catch(err => {
                return reject(err);
            })
        })
    }

    public static getJobs(params: any): Promise<Response<Array<JobModel>>> {
        return new Promise((resolve, reject) => {
            Http.get('/jobs', { params }).then(resp => {
                let data = new Response<Array<JobModel>>({
                    data: resp?.data,
                    status: resp?.status,
                    success: resp?.status === 200,
                    message: '',
                })
                return resolve(data);
            }).catch(err => {
                return reject(err);
            })
        })
    }

    public static getJobDetails(params: any): Promise<Response<JobModel>> {
        return new Promise((resolve, reject) => {
            Http.get('/jobs', { params }).then(resp => {
                let data = new Response<JobModel>({
                    data: resp?.data,
                    status: resp?.status,
                    success: resp?.status === 200,
                    message: '',
                })
                return resolve(data);
            }).catch(err => {
                return reject(err);
            })
        })
    }

}