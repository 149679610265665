const variables = {
    $primary_black: '#1F2121',
    cyan: '#00E1F8',
    white:'#ffffff',
    container_size: '1300px',
    s_btn_w: '169px',
    s_btn_h: '40px',
    l_btn_w: '172px',
    l_btn_h: '40px',
    btn_text: '15px',
    s_paragraph: '17px',
    l_paragraph: '19px',
    l_heading: '52px',
    m_heading:'35px',
    m_heading2: '27px',
    s_heading: '25px',
    navigation_link: '15px',
    xs_text: '14px',
    btn_padding_y: '7px',
    btn_padding_x: '20px',
    job_container_heading: '35px',
    job_container_subheading: '23px',
    
    // 1920px - 1793px
    container_size_1920: '1700px',
    s_btn_w_1920: '179px',
    s_btn_h_1920: '44px',
    l_btn_w_1920: '182px',
    l_btn_h_1920: '44px',
    btn_text_1920: '18px',
    s_paragraph_1920: '22px',
    l_paragraph_1920: '24px',
    l_heading_1920: '72px',
    // m_heading_1920: '55px',
    m_heading_1920: '50px',
    m_heading2_1920: '40px',
    s_heading_1920: '36px',
    navigation_link_1920: '20px',
    xs_text_1920: '16px',
    btn_padding_y_1920: '10px',
    btn_padding_x_1920: '25px',
    job_container_heading_1920: '45px',
    job_container_subheading_1920: '30px',

    // 1792px - 1601px
    container_size_1792: '1500px',
    s_btn_w_1792: '171px',
    s_btn_h_1792: '40px',
    l_btn_w_1792: '175px',
    l_btn_h_1792: '40px',
    btn_text_1792: '16px',
    s_paragraph_1792: '20px',
    l_paragraph_1792: '22px',
    l_heading_1792: '62px',
    m_heading_1792: '40px',
    m_heading2_1792: '35px',
    s_heading_1792: '30px',
    navigation_link_1792: '18px',
    xs_text_1792: '15px',
    btn_padding_y_1792: '8px',
    btn_padding_x_1792: '23px',
    job_container_heading_1792: '40px',
    job_container_subheading_1792: '25px',

    // 1440px
    container_size_1440: '1200px',
    s_btn_w_1440: '160px',
    s_btn_h_1440: '35px',
    l_btn_w_1440: '165px',
    l_btn_h_1440: '35px',
    btn_text_1440: '14px',
    s_paragraph_1440: '16px',
    l_paragraph_1440: '18px',
    l_heading_1440: '45px',
    m_heading_1440: '30px',
    m_heading2_1440: '25px',
    s_heading_1440: '22px',
    navigation_link_1440: '14px',
    xs_text_1440: '13px',    

    // 1366px
    container_size_1366: '1100px',
    s_btn_w_1366: '',
    s_btn_h_1366: '',
    m_btn_1366: '',
    l_btn_w_1366: '160px',
    l_btn_h_1366: '35px',
    btn_text_1366: '13px',
    s_paragraph_1366: '15px',
    l_paragraph_1366: '17px',
    l_heading_1366: '40px',
    m_heading_1366:'29px',
    m_heading2_1366: '23px',
    s_heading_1366: '20px',
    navigation_link_1366: '13px',
    xs_text_1366: '12px',
    btn_padding_y_1366: '6px',
    btn_padding_x_1366: '18px',

    // 1100px
    container_size_1100: '1000px',
    l_heading_1100: '35px',
    l_paragraph_1100: '16px',
    m_heading_1100: '26px',
    l_btn_h_1100: '32px',
    l_btn_w_1100: '150px',
    btn_text_1100: '12px',
    s_paragraph_1100: '14px',
    s_heading_1100: '18px',
    m_heading2_1100: '21px',

    // 800px
    container_size_800: '680px',
    l_heading_800: '30px',
    l_paragraph_800: '15px',
    m_heading_800: '23px',
    s_paragraph_800: '13px',
    s_heading_800: '17px',
    m_heading2_800: '20px',
    navigation_link_800: '12px',
    btn_padding_y_800: '5px',
    btn_padding_x_800: '17px',

    // 500px
    container_size_500: '400px',
    l_heading_500: '25px',
    l_paragraph_500: '14px',    
    btn_text_500: '11px',
    l_btn_h_500: '30px',
    l_btn_w_500: '140px',
    m_heading_500: '20px',
    s_paragraph_500: '12px',
    s_heading_500: '16px',
    m_heading2_500: '19px',
}

export default variables;