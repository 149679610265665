import {Component} from 'react';
import {SectionSecond} from './Styled';
import map1 from '../../../assets/Marketeer/R.webp';
import map2 from '../../../assets/Marketeer/S.webp';
import map3 from '../../../assets/Marketeer/T.webp';
import map4 from '../../../assets/Marketeer/U.webp';

export class SectionTwo extends Component {
    render() {
        return (
            <SectionSecond>
                <div className="section-lining lining-before"><div className="dotted"></div></div>
                <div className="skew-container-inner container d-flex flex-wrap">
                    <div className="left">
                        <div className="box-container d-flex flex-wrap justify-content-between">
                            <div className="box box1">
                                <div className="text">
                                    <h3>Branded Pin</h3>                                   
                                </div>
                                <div className="image-container"><img src={map1} alt=""/></div>
                            </div>
                            <div className="box box2">
                                <div className="text">
                                    <h3>Search</h3>                                   
                                </div>
                                <div className="image-container"><img src={map2} alt=""/></div>
                            </div>
                            <div className="box box3">                                
                                <div className="text">
                                    <h3>Recommendation</h3>
                                </div>
                                <div className="image-container"><img src={map3} alt=""/></div>
                            </div>
                            <div className="box box4">
                                <div className="text">
                                    <h3>In-Car Offer</h3>
                                </div>
                                <div className="image-container"><img src={map4} alt=""/></div>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <h2>Multiple Ad Formats</h2>
                        <p>Build your own creatives with engaging in-car ad units.</p>
                        <p>Multiple, dynamic and interactive ad formats available for both branding and performance campaigns.</p>
                        <button className="btn" onClick={() => this.scrollTosection()}>Read More 
                            <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.03516 6.4314C6.48287 6.4314 6.03516 6.87911 6.03516 7.4314C6.03516 7.98368 6.48287 8.4314 7.03516 8.4314L7.03516 6.4314ZM8.37202 8.1385C8.76255 7.74798 8.76255 7.11481 8.37202 6.72429L2.00806 0.360328C1.61754 -0.0301964 0.984371 -0.0301964 0.593847 0.360328C0.203322 0.750852 0.203322 1.38402 0.593847 1.77454L6.2507 7.4314L0.593846 13.0882C0.203322 13.4788 0.203322 14.1119 0.593846 14.5025C0.98437 14.893 1.61754 14.893 2.00806 14.5025L8.37202 8.1385ZM7.03516 8.4314L7.66491 8.4314L7.66491 6.4314L7.03516 6.4314L7.03516 8.4314Z" fill="white"/>
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="section-lining lining-after"><div className="dotted"></div></div>
            </SectionSecond>
        )
    }
    scrollTosection = () => {
        const el: HTMLElement | null = document.getElementById('marketer-ad-format-section');
        if(el){
            el.scrollIntoView({ behavior: 'smooth' })
        }
        //document.getElementById('row-id').scrollIntoView({ behavior: 'smooth' })
    };
}

export default SectionTwo;