
export enum ConfigActionTypes {
    ToggleMenu = '[Config] ToggleMenu',
    LoadRoles = '[Config] LoadRoles',
    AddRole = '[Config] AddRole',
    UpdateRole = '[Config] UpdateRole',
    DeleteRole = '[Config] DeleteRole',
    LoadPermissions = '[Config] LoadPermissions',
}

export const ToggleMenu = (payload:boolean) => {
    return {
        type:ConfigActionTypes.ToggleMenu,
        payload
    }
}




export const DeleteRole = (payload:string) => {
    return {
        type:ConfigActionTypes.DeleteRole,
        payload
    }
}

export class ConfigActions {
    type:ConfigActionTypes;
    payload: any;
}

