import { useParams } from 'react-router-dom';
import {SectionOne} from './SectionOne';
import {SectionTwo} from './SectionTwo';

export function BlogDetails() {
    const { id }  = useParams();
    
    return (
            <div>
                <SectionOne id={id} />
                <SectionTwo id={id} />
            </div>
        )
    
}

export default BlogDetails;


