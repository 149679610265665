import styled from "styled-components";
import variables from '../../../Style-js/variable';

export const SectionSecond = styled.section`
    margin: -50px 0 0 0;
    background: #F6FAFF;
    transform: skew(0, -5deg);
    padding: 200px 0 100px;
    .skew-inner-container {
        transform: skew(0, 5deg);
    }
    .section-lining {
        position: absolute;
        width: 35%;
        .dotted {
            width: 100%;
            border-top: 2.5px dashed white;
        }
    }
    .lining-after {
        bottom: 0;
        right: 0;
        background-color: rgba(0,0,0,0.67);
        padding: 10px 0px 10px 30px;
    }
    .container {
        max-width: ${variables.container_size};
    } 
    .heading {
        width: 54%;
        margin: 0 auto;
        h2 {
            font-size: ${variables.m_heading};
        }
        p {
            font-weight: 300;
            line-height: 23px;
            margin: 4% 0 8% 0;
            font-size: ${variables.l_paragraph};
        }
    }  
    form {
        justify-content: space-between;
        width: 55%;
        margin: 0 auto;
        .form-group {
            width: 47%;
        }
        .fullwidth {
            width: 100%;
        }
        .text-area {
            width: 100%;
        }
        label {
            font-size: ${variables.btn_text};
            font-weight: 600;
        }
        input,
        textarea {
            box-shadow: rgba(0, 0, 0, 0.3) 0.4px 0.4px 30px -4px;
            font-size: ${variables.btn_text};
            border-radius: 8px;
        }
        input {
            height: 58px;
            padding: 0px 20px;
        }        
        textarea {
            resize: none;
            height: 147px;
            padding: 20px;
        }
        p {
            color: red;
        }
        .btn-container {
            margin-top: 10%;
            width: 100%;
        }
        button {
            width: auto;
            padding: ${variables.btn_padding_y} ${variables.btn_padding_x};
            border: none;
            letter-spacing: 0.5px;            
            background: ${variables.cyan};
            border-radius: 40px;
            font-weight: 600;
            font-size: ${variables.btn_text};
            color: ${variables.white};
            transition: 0.3s;  
            svg {                
                margin-left: 20px;
            }
            &:hover {
                background-color: #1F2121;
            }
        }
        .terms-agree {            
            position: relative;
            padding-left: 30px;
            margin-top: 3%;
            p {
                color: #4D4D4D;
                font-weight: 300;
                font-size: 14px;
                a {
                    font-weight: 400;
                    color: black;
                    text-decoration: underline;
                    text-underline-offset: 3px;
                }
            }
            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;                
            }
            .check-container {
                position: absolute;
                top: 0;
                left: 0;                
                cursor: pointer;
            }
            .checkmark {                               
                height: 20px;
                width: 20px;
                background-color: #fff;
                border: 2px solid lightgray;          
            }
            .check-container:hover input ~ .checkmark {
                background-color: #ccc;
            }
            .check-container input:checked ~ .checkmark {
                background-color: ${variables.cyan};
                border: none;
            }
            .checkmark:after {
                content: "";
                position: absolute;
                display: none;
            }
            .check-container input:checked ~ .checkmark:after {
                display: block;
            }
            .check-container .checkmark:after {
                left: 6.5px;
                top: 3px;
                width: 7px;
                height: 12px;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }  

    @media screen and (min-width: 1793px) {
        .container {
            max-width: ${variables.container_size_1920};
        } 
        .heading {
            h2 {
                font-size: ${variables.m_heading_1920};
            }
            p {
                line-height: 29px;
                font-size: ${variables.l_paragraph_1920};
            }
        }
        form {
            .fullwidthname {
                margin: 0 0 40px 0;
            }
            .text-area {
                margin: 30px 0 0 0;
            }
            label {
                font-size: ${variables.btn_text_1920};
                margin: 0 0 20px 0;
            }
            input,
            textarea {
                font-size: ${variables.btn_text_1920};
                border-radius: 12px;
            }
            input {
                height: 79px;
                padding: 0 25px;
            }
            textarea {
                height: 167px;
                padding: 25px;
            }
            button {
                font-size: ${variables.btn_text_1920};
                padding: ${variables.btn_padding_y_1920} ${variables.btn_padding_x_1920};
                svg {
                    margin-left: 30px;
                }
            }
            .terms-agree p {
                font-size: 18px;
                .check-container {
                    top: 3px;
                }
            }
        }
    }

    @media screen and (max-width: 1792px) and (min-width: 1601px) {
        .container {
            max-width: ${variables.container_size_1792};
        }
        .heading {
            h2 {
                font-size: ${variables.m_heading_1792};
            }
            p {
                line-height: 26px;
                font-size: ${variables.l_paragraph_1792};
            }
        }
        form {
            .form-group {
                margin: 0 0 20px 0;
            }
            label {
                font-size: ${variables.btn_text_1792};                
            }
            input,
            textarea {
                font-size: ${variables.btn_text_1792};
                border-radius: 12px;
            }
            input {
                height: 68px;
            }
            textarea {
                height: 167px;
            }
            .terms-agree p {
                font-size: 15px;
                .check-container {
                    top: 3px;
                }
            }
            button {
                font-size: ${variables.btn_text_1920};
                padding: 10px 25px;
                svg {
                    margin-left: 30px;
                }
            }    
        }
    }

    @media screen and (max-width: 1441px) {
        .container {
            max-width: ${variables.container_size_1440};
        }
        .heading {
            width: 55%;
            h2 {
                font-size: ${variables.m_heading_1440};
            }
            p {
                font-size: ${variables.l_paragraph_1440};
                line-height: 21px;
            }
        }
        form {
            label {
                font-size: ${variables.btn_text_1440};                
            }
            input,
            textarea {
                font-size: ${variables.btn_text_1440};
            }
            input {
                height: 55px;
            }
            textarea {
                height: 140px;
            }
            .terms-agree p {
                font-size: 13px;
            }
        }
    }

    @media screen and (max-width: 1367px) {
        .container {
            max-width: ${variables.container_size_1366};
        }
        .heading {
            width: 57%;
            h2 {
                font-size: ${variables.m_heading_1366};
            }
            p {
                line-height: 20px;
                font-size: ${variables.l_paragraph_1366};
            }
        }
        form {
            label {
                font-size: ${variables.btn_text_1366};                
            }
            input,
            textarea {
                font-size: ${variables.btn_text_1366};
                border-radius: 6px;                
            }
            input {
                height: 50px;
                padding: 0 15px;
            }
            textarea {
                padding: 15px;
                height: 130px;
            }
            button {
                padding: ${variables.btn_padding_y_1366} ${variables.btn_padding_x_1366};
            }
        }
    }

    @media screen and (max-width: 1100px) {
        // padding: 100px 0;
        .container {
            max-width: ${variables.container_size_1100};
        }
        .heading {
            width: 60%;
            h2 {
                font-size: ${variables.m_heading_1100};
            }
            p {
                font-size: ${variables.l_paragraph_1100};
                margin: 2% 0 8% 0;
            }
        }
        form {
            width: 60%;
        }
    }

    @media screen and (max-width: 900px) {
        .container {
            max-width: ${variables.container_size_800};
        }
        .heading {
            width: 60%;
            h2 {
                font-size: ${variables.m_heading_800};
            }
            p {
                font-size: ${variables.l_paragraph_800};
                line-height: 19px;
            }
        }
        form {
            width: 100%;
            .form-group {
                margin-bottom: 0px;
            }
            label {
                font-size: 12px;
            }
            input {
                height: 40px;
            }            
            input,
            textarea {
                font-size: ${variables.btn_text_1100};
            }
            textarea {
                height: 110px;
            }
            .btn-container {
                margin-top: 5%;
            }
            button {
                padding: ${variables.btn_padding_y} ${variables.btn_padding_x};
                font-size: ${variables.btn_text_1100};
            }
        }
    }

    @media screen and (max-width: 767px) {
        padding: 150px 0;
        .section-lining {
            width: 50%;
        }        
        .container {
            max-width: ${variables.container_size_500};
        }
        .heading {
            width: 100%;
            h2 {
                font-size: ${variables.m_heading_500};
            }
            p {
                font-size: ${variables.l_paragraph_500};
                line-height: 16px;
            }
        }
        form {
            .form-group {
                width: 100%;
            }
            input {
                height: 30px;
                padding: 0 10px;
            }
            input,
            textarea {
                font-size: ${variables.btn_text_500};
            }
            textarea {
                height: 80px;
                padding: 10px;
            }
            button {                
                font-size: ${variables.btn_text_500};                
            }
        }
    }
`;

