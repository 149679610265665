import { Component } from 'react';
import { SectionFirst } from './Styled';
import { Props, States } from '../../../Redux/Models';
import BackendService from '../../../Services/BackendService';
import { BannerSection } from './Styled';
import Configs from '../../../Configs';


export class SectionOne extends Component<Props, States>{
    public state: States = {
        blog: {}
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.getBlogDetails();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.id !== this.props.id) {
            window.scrollTo(0, 0)
            this.getBlogDetails();
        }
    }

    getBlogDetails = () => {
        const { id } = this.props
        BackendService.getBlogDetails(id).then(resp => {
            if (resp.isSuccess()) {
                this.setState({ blog: resp.data })
                setTimeout(() => {
                    console.log(this.state);
                }, 5);
            }
        })
    }
    render() {
        const { blog } = this.state;
        return (
            <>
                <BannerSection>
                    <div className="container">
                        <p className="back">
                            <a href="/blog">
                                <svg height="13.466" viewBox="0 0 7.73 13.466" width="7.73" xmlns="http://www.w3.org/2000/svg"><path d="m0 5.315 5.322-5.315s5.307 5.3 5.316 5.315" fill="none" stroke="#1F2121" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" transform="matrix(0 -1 1 0 1 12.052)"/></svg> Back to Blog Home
                            </a>
                        </p>
                        <h1>{blog.title} </h1>
                        {/* <p className="text-right"><span className="bold">CAR</span> | <span className="ago">4 MIN AGO</span></p> */}
                        <img src={blog.cover?.url} alt="" className="d-block" />
                    </div>
                </BannerSection>

                <SectionFirst>
                    <div className="container">
                        {/* <p className="writtenby">
                            WRITTEN BY : <span className="bold">JACK SMITH</span>
                        </p>     */}
                        <div className="content">
                            {blog.content?.indexOf('</') !== -1
                                ? (
                                    <div dangerouslySetInnerHTML={{ __html: blog.content?.replace(/(<? *script)/gi, 'illegalscript') }} >
                                    </div>
                                )
                                : blog?.content
                            }

                        </div>
                    </div>
                </SectionFirst>
            </>
        )
    }
}

export default SectionOne;