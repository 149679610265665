import { Component } from 'react';
import {SectionTwo} from './SectionTwo';

export class ContactUs extends Component {
    render() {
        return (
            <div>
                <SectionTwo/>
            </div>
        )
    }
}

export default ContactUs;
