import styled from "styled-components";
import variables from '../../../Style-js/variable';
import background_third_sec from '../../../assets/Home/C.webp';

export const HomeBanner = styled.section`
    margin-top: 80px;
    .container {
        position: relative;
        max-width: ${variables.container_size};
    }
    position: relative;
    &::after {
        content: "";
        display: block;
        background: #F6FAFF;
        width: 100%;
        height: 300px;
        position: absolute;
        left: 0;
        top: 95%;
        transform: skew(0deg, -5deg);
        z-index: 2;
    }
    .animation {
        width: 100%;
    }

    @media screen and (min-width: 1793px) {
        margin-top: 94px;
        .container {
            max-width: ${variables.container_size_1920};
        }
    }

    @media screen and (max-width: 1792px) and (min-width: 1601px) {
        .container {
            max-width: ${variables.container_size_1792};
        }
    }

    @media screen and (max-width: 1600px) {
        padding: 100px 0;
        .animation {
            width: 85%;
            position: absolute;
            right: 0;
            bottom: 0;
        }
        .container {
            position: static;
        }
    }

    @media screen and (max-width: 1441px) {
        .container {
            max-width: ${variables.container_size_1440};
        }
    }

    @media screen and (max-width: 1366px) {
        margin-top: 75px; 
        .container {
            max-width: ${variables.container_size_1366};
        }        
    }

    @media screen and (max-width: 1100px) {
        .container {
            max-width: ${variables.container_size_1100};
        }
        .animation {
            width: 95%;
        }
    }

    @media screen and (max-width: 900px) {
        .container {
            max-width: ${variables.container_size_800};
        }
        // &::before {
        //     display: block;
        //     content: "";
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 115%;
        //     background: rgba(0,0,0,0.6);
        //     z-index: 1;
        // }
        &::after {
            top: 94%;
        }
        .animation {
            width: 100%;
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: -1;
        }
    }

    @media screen and (max-width: 767px) {
        padding: 50px 0 40%;
        .container {
            max-width: ${variables.container_size_500};
        } 
    }
`;

export const HomeBannerText = styled.div`
   position: absolute;
   z-index: 1; 
   top: 100px;
   left: 0;
   h1 {
    width: 738px;
    font-weight: 600;
    font-size: ${variables.l_heading};
    line-height: 57px; 
    letter-spacing: 0.5px;   
    span {
        color: ${variables.cyan};
    }
   }
   p {
    width: 403px;
    font-weight: 500;
    font-size: ${variables.l_paragraph};
    line-height: 38px;
    margin-top: 50px;
   }
   .btn {        
        background: ${variables.cyan};
        border-radius: 40px;
        color: ${variables.white};
        font-weight: 600;
        font-size: ${variables.btn_text};
        margin-top: 20px;
        letter-spacing: 0.5px;
        transition: 0.3s;
        padding: ${variables.btn_padding_y} ${variables.btn_padding_x};
        svg {
            margin: 0 0 0 30px;
        }
        &:hover {
            background-color: #1F2121;
        }   
    }

   @media screen and (min-width: 1793px) {
        h1 {
            font-size: ${variables.l_heading_1920};
            width: 1017px;
            line-height: 77px;
        }
        p {
            width: 402px;
            font-size: ${variables.l_paragraph_1920};
            width: 509px;
            margin: 70px 0 0 0;
            line-height: 46px;
        }
        .btn {
            padding: ${variables.btn_padding_y_1920} ${variables.btn_padding_x_1920};
            font-size: ${variables.btn_text_1920};
            margin-top: 50px;
        }
    }

    @media screen and (max-width: 1792px) and (min-width: 1601px) {
        h1 {
            font-size: ${variables.l_heading_1792};
            width: 877px;
            line-height: 65px;
        }
        p {
            font-size: ${variables.l_paragraph_1792};
            line-height: 35px;
            width: 470px;
            margin: 70px 0 0 0;
        }
        .btn {
            padding: ${variables.btn_padding_y_1792} ${variables.btn_padding_x_1792};
            font-size: ${variables.btn_text_1792};
            margin-top: 50px;
        }
    }

    @media screen and (max-width: 1600px) {
        position: relative;
        top: 0;
        left: 0;
    }

    @media screen and (max-width: 1441px) {
        h1 {
            font-size: ${variables.l_heading_1440};
            width: 640px;
            line-height: 50px;
        }
        p {
            width: 402px;
            font-size: ${variables.l_paragraph_1440};
            line-height: 33px;
            margin-top: 30px;
        }
        .btn {            
            font-size: ${variables.btn_text_1440};
            margin-top: 20px;
        }
    }

    @media screen and (max-width: 1366px) {
        h1 {
            font-size: ${variables.l_heading_1366};
            width: 570px;
            line-height: 45px;
        }
        p {
            font-size: ${variables.l_paragraph_1366};
            width: 400px;
        }
        .btn {
            padding: ${variables.btn_padding_y_1366} ${variables.btn_padding_x_1366};
            font-size: ${variables.btn_text_1366};
        }
    }

    @media screen and (max-width: 1100px) {        
        h1 {
            font-size: ${variables.l_heading_1100};
            width: 501px;
            line-height: 40px;
        }
        p {
            font-size: ${variables.l_paragraph_1100};
            width: 350px;
            line-height: 32px;
        }
        .btn {            
            font-size: ${variables.btn_text_1100};
        }
    }

    @media screen and (max-width: 900px) {
        // padding: 100px 0 70px;        
        // color: white;
        position: relative;
        left: 0;
        top: 0;
        z-index: 2;
        h1 {
            font-size: ${variables.l_heading_800};
            width: 431px;
            line-height: 35px;
        }
        p {
            font-size: ${variables.l_paragraph_800};
            width: 320px;
            line-height: 31px;
        }
        .btn {
            padding: ${variables.btn_padding_y_800} ${variables.btn_padding_x_800};
        }
    }

    @media screen and (max-width: 767px) {             
        text-align: center;    
        h1 {
            font-size: ${variables.l_heading_500};
            width: 100%;
            max-width: 300px;
            line-height: 30px;
            margin: 0 auto;            
        }
        p {
            font-size: ${variables.l_paragraph_500};
            width: 100%;
            max-width: 300px;
            line-height: 30px;
            margin: 20px auto 0;
        }
        .btn {
            margin-top: 10px;        
            font-size: ${variables.btn_text_500};
        }
    }
`;

export const SectionFirst = styled.section`
    .container {
        max-width: ${variables.container_size};
    }
    position: relative;
    z-index: 2;
    padding: 150px 0 150px;
    background: #F6FAFF;
    transform: skew(0deg, -5deg);
    .skew-inner-container {
        transform: skew(0deg, 5deg);
    }
    .box * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    ul {
        list-style-type: none;
        overflow: hidden;
    }
    .box {
        width: 98%;
        min-height: 530px;
        padding: 60px 70px 60px;
        box-shadow: 0.4px 0.4px 30px -4px rgba(0,0,0,0.3);        
        border-radius: 15px;
        position: relative;
        h2 {
            text-align: center;
            margin-bottom: 30px;
            font-weight: 500;
            font-size: ${variables.m_heading};
        }
        li {            
            font-style: normal;
            font-weight: 500;
            font-size: ${variables.s_paragraph};
            color: #000000;
            margin-bottom: 20px;
            line-height: 30px;
            img {
                width: 12px;
                position: relative;
                top: 9px;
                margin-right: 30px;
            }
        }
        .btn-container {
            position: absolute;
            bottom: 60px;
            left: 0;
            width: 100%;
            text-align: center;
        }
        .btn {
            border: none;
            letter-spacing: 0.5px;            
            background: ${variables.cyan};
            border-radius: 40px;
            font-weight: 600;
            font-size: ${variables.btn_text};
            color: ${variables.white};
            padding: ${variables.btn_padding_y} ${variables.btn_padding_x};
            transition: 0.3s;  
            svg {
                margin: 0 0 0 30px;
            }
            &:hover {
                background-color: #1F2121;
            }
        }        
        
        &.r-box {
            float: right;            
        }

        &.l-box {  
            float: left;                                
        }
        &:hover {
            background: white;
        }
    }  

    @media screen and (min-width: 1793px) {
        padding: 150px 0;
        .container {
            max-width: ${variables.container_size_1920};
        }
        .box {            
            min-height: 600px;
            padding: 70px 80px ;
            h2 {
                font-size: 45px;
                margin-bottom: 40px
            }
            li {
                font-size: ${variables.s_paragraph_1920};
                margin-bottom: 30px;
                img {
                    width: 18px;
                    top: 5px;
                }
            }
            .btn-container {
                bottom: 70px;
            }
            .btn {
                padding: ${variables.btn_padding_y_1920} ${variables.btn_padding_x_1920};
                font-size: ${variables.btn_text_1920};
            }
        }
    }

    @media screen and (max-width: 1792px) and (min-width: 1601px) {
        .container {
            max-width: ${variables.container_size_1792};
        }
        .box {            
            padding: 65px 75px;
            min-height: 550px;
            h2 {
                font-size: 40px;
                margin-bottom: 35px
            }
            li {
                font-size: ${variables.s_paragraph_1792};
                margin-bottom: 25px;
                img {
                    width: 15px;
                    top: 7px;
                }
            }
            .btn-container {
                bottom: 65px;
            }
            .btn {
                padding: ${variables.btn_padding_y_1792} ${variables.btn_padding_x_1792};
                font-size: ${variables.btn_text_1792};
            }
        }
    }

    @media screen and (max-width: 1441px) {
        .container {
            max-width: ${variables.container_size_1440};
        }
        .box {
            h2 {
                font-size: ${variables.m_heading_1440};
            }
            li {
                font-size: ${variables.s_paragraph_1440};
            }
            .btn {                
                font-size: ${variables.btn_text_1440};
            }
        }
    }

    @media screen and (max-width: 1367px) {
        .container {
            max-width: ${variables.container_size_1366};
        }
        .box {
            min-height: 500px;
            padding: 55px 65px;
            h2 {
                font-size: ${variables.m_heading_1366};
            }
            li {
                font-size: ${variables.s_paragraph_1366};
            }
            .btn-container {
                bottom: 55px;
            }
            .btn {
                padding: ${variables.btn_padding_y_1366} ${variables.btn_padding_x_1366};
                font-size: ${variables.btn_text_1366};
            }
        }        
    }

    @media screen and (max-width: 1100px) {        
        padding: 100px 0 100px;
        .container {
            max-width: ${variables.container_size_1100};
        }
        .box {
            width: 100%;
            min-height: 430px;
            h2 {
                font-size: ${variables.m_heading_1100};          
            }
            li {
                font-size: ${variables.s_paragraph_1100};
                line-height: 22px;
                img {
                    width: 9px;
                    top: 7px;
                }
            }
            .btn {                
                font-size: ${variables.btn_text_1100};
            }
        }
    }

    @media screen and (max-width: 900px) {        
        .container {
            max-width: ${variables.container_size_800};
        }
        .box {  
            margin: 0 !important;
            width: 100%;   
            border-radius: 20px;  
            padding: 40px 30px;    
            min-height: 350px;
            h2 {
                font-size: 18px;
                margin-bottom: 20px;
            }
            li {
                font-size: ${variables.s_paragraph_800};
                margin-bottom: 10px;
                img {
                    margin-right: 15px;
                    width: 7px;
                    top: 7px;
                }
            }
            .btn-container {
                bottom: 40px;
            }
            .btn {
                padding: ${variables.btn_padding_y_800} ${variables.btn_padding_x_800};
            }
        }
    }

    @media screen and (max-width: 767px) { 
        padding: 50px 0 80px;       
        .container {
            max-width: ${variables.container_size_500};
        }
        .cloud-container {
            margin-bottom: 20px;
        }
        .box {
            padding: 30px;
            min-height: 0;
            h2 {
                font-size: ${variables.m_heading_500};
            }
            li {
                font-size: ${variables.s_paragraph_500};
            }
            .btn-container {
                margin-top: 10px;
                position: static;
            }
            .btn {                
                font-size: ${variables.btn_text_500};                
            }
            &.r-box {margin-top: 40px !important;} 
        }
    }
`;

export const SectionSecond = styled.section`
    .container {
        max-width: ${variables.container_size};
    }
    padding: 150px 0 150px;
    transform: skew(0deg, -5deg);
    position: relative;
    .section-lining {
        position: absolute;
        width: 35%;        
        .dotted {
            width: 100%;
            border-top: 2.5px dashed white;
        }
    }
    .lining-before {
        top: 0;
        left: 0;
        background-color: #00E1F8;
        padding: 10px 30px 10px 0px;
    }
    .lining-after {
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.67);
        padding: 10px 0px 10px 30px;
    }
    .skew-inner-container {
        transform: skew(0deg, 5deg);                
    }
    * {
        box-sizing: border-box;
    }       
    .left {
        width: 100%;
        img {
            width: 100%;
        }
        
    }
    .right {
        width: 100%;
        h2 {
            font-size: ${variables.m_heading};
            margin-bottom: 20px;
            line-height: 50px;
        }
        h3 {
            color #00E1F8;
            font-size: ${variables.s_heading};
            font-weight: 600;
            margin: 20px 0;
        }
        p {
            font-size: ${variables.l_paragraph};
            line-height: 33px;
            margin-bottom: 20px;
            font-weight: 500;
        }
    }

    @media screen and (min-width: 1793px) {
        .container {
            max-width: ${variables.container_size_1920};
        }
        .right {
            h2 {
                font-size: ${variables.m_heading_1920};
                line-height: 62px;
            }
            h3 {
                font-size: ${variables.s_heading_1920};
                margin: 30px 0;                
            }
            p {
                font-size: ${variables.l_paragraph_1920};
                line-height: 46px;
            }
        }
    }

    @media screen and (max-width: 1792px) and (min-width: 1601px) {
        .container {
            max-width: ${variables.container_size_1792};
        }
        .right {
            h2 {
                font-size: ${variables.m_heading_1792};
                line-height: 55px;
            }
            h3 {
                font-size: ${variables.s_heading_1792};
                margin: 25px 0; 
            }
            p {
                font-size: ${variables.l_paragraph_1792};
                line-height: 40px;
            }
        }
    }

    @media screen and (max-width: 1440px) {
        .container {
            max-width: ${variables.container_size_1440};
        }
        .right {
            h2 {
                font-size: ${variables.m_heading_1440};
            }
            h3 {
                font-size: ${variables.s_heading_1440};
            }
            p {
                font-size: ${variables.l_paragraph_1440};
            }
        }
    }

    @media screen and (max-width: 1367px) {
        .container {
            max-width: ${variables.container_size_1366};
        }
        .right {
            h2 {
                font-size: ${variables.m_heading_1366};
            }
            h3 {
                font-size: ${variables.s_heading_1366};
            }
            p {
                font-size: ${variables.l_paragraph_1366};
            }
        }
    }

    @media screen and (max-width: 1100px) {
        padding: 100px 0;
        .container {
            max-width: ${variables.container_size_1100};
        }
        .right {
            h2 {
                font-size: ${variables.m_heading_1100};
                line-height: 45px;
            }
            h3 {
                font-size: ${variables.s_heading_1100};                
            }
            p {
                font-size: ${variables.l_paragraph_1100};
                line-height: 32px;
            }
        }
    }

    @media screen and (max-width: 900px) {
        .container {
            max-width: ${variables.container_size_800};
        }
        .right {
            width: 100%;
            h2 {
                font-size: ${variables.m_heading_800};
                line-height: 40px;
            }
            h3 {
                font-size: ${variables.s_heading_800};
            }
            p {
                font-size: ${variables.l_paragraph_800};
                line-height: 31px;
                margin-bottom: 15px;
            }
        }
    }

    @media screen and (max-width: 767px) {     
        padding: 70px 0 70px;   
        .section-lining {
            width: 50%;
        }
        .container {
            max-width: ${variables.container_size_500};
        }
        .right {
            h2 {
                font-size: ${variables.m_heading_500};
                line-height: 35px;
            }
            h3 {
                font-size: ${variables.s_heading_500};
            }
            p {
                font-size: ${variables.l_paragraph_500};
                line-height: 30px;
            }
        }
    }
`;

export const SectionThird = styled.section`
    .container {
        max-width: ${variables.container_size};
    }
    * {
        box-sizing: border-box;    
    }
    overflow: hidden;
    position: relative;
    min-height: 100vh;
    transform: skew(0deg, -5deg);
    .skew-inner-container {
        background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url(${background_third_sec});
        // background-attachment: fixed;
        background-size: cover;
        background-position: center 23%;
        position: absolute;
        top: -10%;
        transform: skew(0deg, 5deg);
        width: 100%;
        height: 120%;
        padding: 14% 0 0 0;
    }
    .top {
        p {
            color: ${variables.white};            
        }
    }
    .small-text {
        font-size: ${variables.btn_text};
        letter-spacing: 3px;
    }
    .large-text {
        font-size: ${variables.m_heading2};
        letter-spacing: 0.5px;
        width: 560px;
        font-weight: 400;
        .thin {
            font-weight: 300;
        }
        .bold {
            font-weight: 600;
        }
    }   
    .btn {
        border-radius: 40px;
        background-color: ${variables.cyan};
        font-size: ${variables.btn_text};
        color: ${variables.white};        
        margin: 30px 0 0 0;
        letter-spacing: 0.5px;
        font-weight: 600;
        transition: 0.3s; 
        padding: ${variables.btn_padding_y} ${variables.btn_padding_x}; 
        svg {
            margin: 0 0 0 30px;
        }
        &:hover {
            background-color: #fff;
            color: #1F2121;
            path {
                fill: #1F2121;
            }
        }
    } 

    @media screen and (min-width: 1793px) {
        .container {
            max-width: ${variables.container_size_1920};
        }
        .small-text {
            font-size: ${variables.btn_text_1920};
        }
        .large-text {
            font-size: ${variables.m_heading2_1920};
            width: 741px;
        }
        .btn {
            font-size: ${variables.btn_text_1920};
            padding: ${variables.btn_padding_y_1920} ${variables.btn_padding_x_1920};
        } 
    }

    @media screen and (max-width: 1792px) and (min-width: 1601px) {
        .container {
            max-width: ${variables.container_size_1792};
        }
        .small-text {
            font-size: ${variables.btn_text_1792};
        }
        .large-text {
            font-size: ${variables.m_heading2_1792};
            width: 700px;
        }
        .btn {
            font-size: ${variables.btn_text_1792};
            padding: ${variables.btn_padding_y_1792} ${variables.btn_padding_x_1792};
        }
    }

    @media screen and (max-width: 1440px) {
        .container {
            max-width: ${variables.container_size_1440};
        }
        .small-text {
            font-size: ${variables.btn_text_1440};
        }
        .large-text {
            font-size: ${variables.m_heading2_1440};
            width: 500px;
        }
        .btn {
            font-size: ${variables.btn_text_1440};
        }
    }

    @media screen and (max-width: 1366px) {
        .container {
            max-width: ${variables.container_size_1366};
        }
        .small-text {
            font-size: ${variables.btn_text_1366};
        }
        .large-text {
            font-size: ${variables.m_heading2_1366};
            width: 430px;
        }
        .btn {
            font-size: ${variables.btn_text_1366};
            padding: ${variables.btn_padding_y_1366} ${variables.btn_padding_x_1366};
        }
    }

    @media screen and (max-width: 1100px) {
        .skew-inner-container {
            padding: 22% 0 0 0;
        }
        .container {
            max-width: ${variables.container_size_1100};
        }
        .small-text {
            font-size: ${variables.btn_text_1100};
        }
        .large-text {
            font-size: ${variables.m_heading2_1100};            
        }
        .btn {
            font-size: ${variables.btn_text_1100};            
        }
    }

    @media screen and (max-width: 900px) {
        min-height: 80vh;
        .container {
            max-width: ${variables.container_size_800};
        }
        .small-text {
            font-size: ${variables.btn_text_1100};
        }
        .large-text {
            font-size: ${variables.m_heading2_800};
            width: 100%;
            max-width: 400px;
        }
        .btn {
            padding: ${variables.btn_padding_y_800} ${variables.btn_padding_x_800};
        }
    }

    @media screen and (max-width: 767px) {
        min-height: 70vh;
        .skew-inner-container {
            // padding: 40% 0 0 0;
            padding: calc(10% + 50px) 0 0 0;
        }
        .container {
            max-width: ${variables.container_size_500};
        }
        .small-text {
            font-size: ${variables.btn_text_500};
        }
        .large-text {
            font-size: ${variables.m_heading2_500};
        }
        .btn {
            font-size: ${variables.btn_text_500};  
            margin: 0;        
        }
    }
`;

export const SectionFourth = styled.section`
    .container {
        max-width: ${variables.container_size};
    }
    background: #F6FAFF;
    padding: 150px 0 170px; 
    transform: skew(0deg, -5deg);
    position: relative;
    .section-lining {
        position: absolute;
        width: 35%;          
        .dotted {
            width: 100%;
            border-top: 2.5px dashed white;
        }
    }
    .lining-before {
        top: 0;
        left: 0;
        background-color: #00E1F8;
        padding: 10px 30px 10px 0px;
    }
    .lining-after {
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.67);
        padding: 10px 0px 10px 30px;
    }
    .skew-inner-container {
        transform: skew(0deg, 5deg);                
    }
    * {
        box-sizing: border-box;
    }
    .l-box {
        width: 100%;
        img {
            width: 100%;
        }
    }
    .r-box {
        width: 100%;
        .large-text {
            font-size: ${variables.m_heading};
            margin: 0;
        }
        .small-text {
            font-size: ${variables.s_heading};
            margin-top: 10px;
            line-height: 37px;
        }
        .paragraph {
            font-size: ${variables.l_paragraph};
            font-weight: 400;
            margin-top: 20px;
            line-height: 30px;
        }
        .btn {            
            background: ${variables.cyan};
            font-size: ${variables.btn_text};
            color: ${variables.white};
            border-radius: 40px;
            margin: 30px 0 0 0;
            font-weight: 600;
            transition: 0.3s; 
            padding: ${variables.btn_padding_y} ${variables.btn_padding_x};
            svg {
                margin: 0 0 0 30px;
            }
            &:hover {
                background-color: #1F2121;
            }
        }
    }

    @media screen and (min-width: 1793px) {
        .container {
            max-width: ${variables.container_size_1920};
        }
        .r-box {
            .large-text {
                font-size: ${variables.m_heading_1920};
            }
            .small-text {
                font-size: ${variables.s_heading_1920};
                line-height: 55px;
            }
            .paragraph {
                font-size: ${variables.l_paragraph_1920};
                line-height: 40px;
            }
            .btn {
                padding: ${variables.btn_padding_y_1920} ${variables.btn_padding_x_1920};
                font-size: ${variables.btn_text_1920};
            }
        }
    }

    @media screen and (max-width: 1792px) and (min-width: 1601px) {
        .container {
            max-width: ${variables.container_size_1792};
        }
        .r-box {
            .large-text {
                font-size: ${variables.m_heading_1792};
            }
            .small-text {
                font-size: ${variables.s_heading_1792};
                line-height: 35px;
            }
            .paragraph {
                font-size: ${variables.l_paragraph_1792};
            }
            .btn {
                padding: ${variables.btn_padding_y_1792} ${variables.btn_padding_x_1792};
                font-size: ${variables.btn_text_1792};
            }
        }
    }

    @media screen and (max-width: 1440px) {
        .container {
            max-width: ${variables.container_size_1440};
        }
        .r-box {
            .large-text {
                font-size: ${variables.m_heading_1440};
            }
            .small-text {
                font-size: ${variables.s_heading_1440};
            }
            .paragraph {
                font-size: ${variables.l_paragraph_1440};
            }
            .btn {                
                font-size: ${variables.btn_text_1440};
            }
        }
    }

    @media screen and (max-width: 1366px) {
        .container {
            max-width: ${variables.container_size_1366};
        }
        .r-box {
            .large-text {
                font-size: ${variables.m_heading_1366};
            }
            .small-text {
                font-size: ${variables.s_heading_1366};
            }
            .paragraph {
                font-size: ${variables.l_paragraph_1366};
            }
            .btn {
                padding: ${variables.btn_padding_y_1366} ${variables.btn_padding_x_1366};
                font-size: ${variables.btn_text_1366};
            }
        }
    }

    @media screen and (max-width: 1100px) {
        padding: 100px 0 150px;
        .container {
            max-width: ${variables.container_size_1100};
        }
        .r-box {
            .large-text {
                font-size: ${variables.m_heading_1100};
            }
            .small-text {
                font-size: ${variables.s_heading_1100};
                line-height: 33px;
            }
            .paragraph {
                font-size: ${variables.l_paragraph_1100};
                line-height: 29px;
            }
            .btn {
                font-size: ${variables.btn_text_1100};
            }
        }
    }

    @media screen and (max-width: 900px) {        
        .container {
            max-width: ${variables.container_size_800};
        }
        .r-box {
            width: 100%;
            .large-text {
                font-size: ${variables.m_heading_800};
            }
            .small-text {
                font-size: ${variables.s_heading_800};
                line-height: 31px;
            }
            .paragraph {
                font-size: ${variables.l_paragraph_800};
                line-height: 27px;
                margin: 15px 0 0 0;
            }
            .btn {
                margin: 30px 0 0 0;
                padding: ${variables.btn_padding_y_800} ${variables.btn_padding_x_800};
            }
        }
    }

    @media screen and (max-width: 767px) {        
        padding: 70px 0 100px;
        .section-lining {
            width: 50%;
        }
        .container {
            max-width: ${variables.container_size_500};
        }
        .order-1 {
            order: 1;
        }
        .order-2 {
            order: 2;
        }
        .l-box {
            margin-top: 30px;
        }
        .r-box {
            .large-text {
                font-size: ${variables.m_heading_500};
            }
            .small-text {
                font-size: ${variables.s_heading_500};
                line-height: 28px;
            }
            .paragraph {
                font-size: ${variables.l_paragraph_500};
                line-height: 25px;
            }
            .btn {
                font-size: ${variables.btn_text_500}; 
                margin: 25px 0 0 0;
            }
        }        
    }
`;