import { Component } from 'react';
import { SectionFirst } from './Styled';
import bullet from '../../../assets/common-icons/list-bullet.png';

export class SectionOne extends Component {
    render() {
        return (
            <SectionFirst>
                <div className="container skew-inner-container">

                    <div className="text-section">
                        <p>The responsible party within the meaning of the data protection laws is 4.screen GmbH. The privacy of your personal data is very important to us. We treat your personal data as confidential and in accordance with the statutory data protection regulations and this data protection declaration. You can use this website without providing any personal data. We collect personal data such as name, address or email address on a voluntary basis. This information shall not be disclosed to third parties without your explicit consent. Data transmission over the Internet can have security issues, e.g. via communication by email. It is not possible to fully protect data from third-party access.</p>
                    </div>

                    <div className="text-section">
                        <h3>General information on data processing</h3>
                        <p>We only process personal data of our users to the extent that this is necessary to provide a functional website as well as our contents and services. The processing of personal data of our users takes place regularly only after the consent of the user. An exception applies in those cases where prior consent cannot be obtained for actual reasons and the processing of the data is permitted by law.</p>

                        <p>Insofar as we obtain the consent of the data subject for the processing of personal data, Art. 6 para. 1 lit. a EU General Data Protection Regulation (GDPR) serves as the legal basis.</p>

                        <p>In the processing of personal data required for the performance of a contract to which the data subject is a party, Art. 6 para. 1 lit. b GDPR serves as the legal basis. This also applies to processing operations that are necessary to carry out pre-contractual measures.</p>

                        <p>Insofar as the processing of personal data is required to fulfill a legal obligation to which our company is subject, Art. 6 para. 1 lit. c GDPR serves as the legal basis.</p>

                        <p>In the event that the vital interests of the data subject or another natural person require the processing of personal data, Article 6(1)(d) GDPR shall serve as the legal basis.</p>

                        <p>If processing is necessary to safeguard a legitimate interest of our company or a third party and if the interests, fundamental rights and freedoms of the data subject do not outweigh the first-mentioned interest, Art. 6 para. 1 lit. f GDPR shall serve as the legal basis for processing.</p>

                        <p>The personal data of the person concerned will be deleted or blocked as soon as the purpose of storage ceases to apply. Furthermore, data may be stored if this has been provided for by the European or national legislator in EU regulations, laws or other provisions to which the data controller is subject. The data will also be blocked or deleted if a storage period prescribed by the aforementioned standards expires, unless there is a need for further storage of the data for the conclusion or fulfillment of a contract.</p>
                    </div>
                    <div className="text-section">
                        <h3>Provision of the website and creation of log files</h3>
                    </div>
                    <div className="mustnotlist">
                        <p>Every time you visit our website, our system automatically collects data and information from the computer system of the calling computer.The following data is collected:</p>
                        <ul>
                            <li className="d-flex align-items-start">
                                <img src={bullet} alt="" />
                                Information about the browser type and version used
                            </li>
                            <li className="d-flex align-items-start">
                                <img src={bullet} alt="" />
                                The user's operating system
                            </li>
                            <li className="d-flex align-items-start">
                                <img src={bullet} alt="" />
                                The user's Internet service provider
                            </li>
                            <li className="d-flex align-items-start">
                                <img src={bullet} alt="" />
                                The IP address of the user
                            </li>
                            <li className="d-flex align-items-start">
                                <img src={bullet} alt="" />
                                Date and time of access
                            </li>
                            <li className="d-flex align-items-start">
                                <img src={bullet} alt="" />
                                Websites from which the user's system accesses our website
                            </li>
                            <li className="d-flex align-items-start">
                                <img src={bullet} alt="" />
                                Websites accessed by the user's system through our website
                            </li>
                        </ul>
                    </div>
                    <div className="text-section">
                        <p>
                            The data is also stored in the log files of our system. Not affected by this are the IP addresses of the user or other data that enable the assignment of the data to a user. This data is not stored together with other personal data of the user. The legal basis for the temporary storage of data is Art. 6 para. 1 lit. f GDPR.
                        </p>
                    </div>
                    <div className="text-section">
                        <h3>Contacting via e-mail and contact form</h3>                     
                    </div>
                    <div className="mustnotlist">
                        <p>We process the following personal data that the user transmits to us as part of their application via e-mail and/or the application form:</p>
                        <ul>
                            <li className="d-flex align-items-start">
                                <img src={bullet} alt="" />
                                First name, Last name of the user
                            </li>
                            <li className="d-flex align-items-start">
                                <img src={bullet} alt="" />
                                E-mail address of the user
                            </li>
                            <li className="d-flex align-items-start">
                                <img src={bullet} alt="" />
                                Phone number of the user
                            </li>
                            <li className="d-flex align-items-start">
                                <img src={bullet} alt="" />
                                Company that the user is from
                            </li>
                            <li className="d-flex align-items-start">
                                <img src={bullet} alt="" />
                                Content of the message
                            </li>
                        </ul>
                    </div>
                    <div className="text-section security-section">
                        <p>The data will be forwarded to the responsible contact person within the company and used exclusively for the designated purpose of contacting. The data will not be passed on to third parties; affiliated companies are not regarded as third parties.</p>

                            <p>If the data processing serves to safeguard our legitimate interests, Art. 6 (1) lit. f GDPR can be considered as a legal basis. If the purpose of contacting is to conclude a contract, Art. 6 (1) lit. b GDPR is the legal basis for the processing.</p>

                            <p>The data will be deleted as soon as they are no longer necessary to achieve the purpose for which they were collected. For data transmitted via e-mail and/or the contact form, this holds true as soon as the respective conversation has ended. The conversation has ended when it can be inferred from the circumstances that the issue/question has been conclusively settled.</p>
                    </div>
                    <div className="text-section security-section">
                        <h3>SSL encryption</h3>
                        <p>To protect the security of your data during transmission, we use encryption methods representing the state of the art (such as SSL) via HTTPS.</p>
                    </div>
                </div>
                <div className="section-lining lining-after"><div className="dotted"></div></div>
            </SectionFirst>
        )
    }
}

export default SectionOne;