import styled from "styled-components";
import variables from '../../../Style-js/variable';
import sec2_bg from '../../../assets/Automotive/I1.webp';

export const BannerSection = styled.section`
    margin: 80px 0 0 0;
    background: #F6FAFF;
    padding: 100px 0 calc(100px + 5%);
    position: relative;
    .container {
        max-width: ${variables.container_size};
    }
    .left {
        width: 43%;
        h1 {
            font-weight: 600;
            font-size: ${variables.l_heading};
            line-height: 45px;
            width: 84%;
        }
        p {
            font-weight: 500;
            font-size: ${variables.l_paragraph};
            margin: 6% 0 0 0;
            line-height: 36px;
        }
        .btn {
            padding: ${variables.btn_padding_y} ${variables.btn_padding_x};
            background: ${variables.cyan};
            border-radius: 40px;
            color: ${variables.white};
            font-weight: 600;
            font-size: ${variables.btn_text};
            margin-top: 30px;
            letter-spacing: 0.5px;
            transition: 0.3s;  
            svg {
                margin: 0px 0 0 30px;
            }
            &:hover {
                background-color: #1F2121;
                color: ${variables.white};
            }   
        }
    }
    .right {
        width: 55%;
        img {
            width: 100%;
        }
    }

    @media screen and (min-width: 1793px) {
        margin: 94px 0 0 0;
        .container {
            max-width: ${variables.container_size_1920};
        }
        .left {
            h1 {
                font-size: ${variables.l_heading_1920};
                line-height: 63px;
            }
            p {
                font-size: ${variables.l_paragraph_1920};
                line-height: 46px;
                margin: 7% 0 0 0;
            }
            .btn {
                padding: ${variables.btn_padding_y_1920} ${variables.btn_padding_x_1920};
                font-size: ${variables.btn_text_1920};
                margin-top: 50px;
            }
        }
    }

    @media screen and (max-width: 1792px) and (min-width: 1601px) {        
        .container {
            max-width: ${variables.container_size_1792};
        }
        .left {
            h1 {
                font-size: ${variables.l_heading_1792};
                line-height: 53px;
            }
            p {
                font-size: ${variables.l_paragraph_1792};
                line-height: 41px;
            }
            .btn {
                padding: ${variables.btn_padding_y_1792} ${variables.btn_padding_x_1792};
                font-size: ${variables.btn_text_1792};
                margin-top: 50px;
            }
        }
    }

    @media screen and (max-width: 1441px) {
        .container {
            max-width: ${variables.container_size_1440};
        }
        .left {
            width: 45%;
            h1 {
                font-size: ${variables.l_heading_1440};
                line-height: 42px;
                width: 80%;
            }
            p {
                font-size: ${variables.l_paragraph_1440};
                line-height: 32px;                
            }
            .btn {
                font-size: ${variables.btn_text_1440};
                margin-top: 20px;
            }
        }
    }

    @media screen and (max-width: 1367px) {
        margin: 75px 0 0 0;
        .container {
            max-width: ${variables.container_size_1366};
        }
        .left {
            h1 {
                line-height: 35px;
                width: 71%;
                font-size: ${variables.l_heading_1366};                
            }
            p {
                line-height: 30px;
                font-size: ${variables.l_paragraph_1366};
            }
            .btn {
                padding: ${variables.btn_padding_y_1366} ${variables.btn_padding_x_1366};
                font-size: ${variables.btn_text_1366};
            }
        }
    }

    @media screen and (max-width: 1100px) {
        .container {
            max-width: ${variables.container_size_1100};
        }
        .left {
            position: relative;
            z-index: 3;
            h1 {
                line-height: 30px;
                font-size: ${variables.l_heading_1100};
            }
            p {
                line-height: 27px;
                font-size: ${variables.l_paragraph_1100};
            }
            .btn {
                font-size: ${variables.btn_text_1100};
            }
        }
    }

    @media screen and (max-width: 900px) {
        .container {
            max-width: ${variables.container_size_800};
        }
        // color: white;
        // &::before {
        //     display: block;
        //     content: "";
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 100%;
        //     background: rgba(0,0,0,0.6);
        //     z-index: 1;
        // }
        .left {
            width: 50%;            
            h1 {
                font-size: ${variables.l_heading_800};
                line-height: 25px;
            }
            p {
                font-size: ${variables.l_paragraph_800};
                line-height: 25px;
            }
            .btn {
                padding: ${variables.btn_padding_y_800} ${variables.btn_padding_x_800};
            }
        }
        .right {
            width: 50%;
        }
        // .right {
        //     position: absolute;
        //     width: 100%;
        //     text-align: center;
        //     top: 50px;
        //     z-index: 0;
        //     left: 0;
        //     img {                
        //         width: 90%;
        //         max-width: 600px;
        //     }
        // }
    }

    @media screen and (max-width: 767px) {           
        padding: 50px 0 calc(5% + 50px);
        .container {
            max-width: ${variables.container_size_500};
        }
        .left {
            width: 100%; 
            max-width: 300px;
            text-align: center;
            margin: 0 auto;            
            h1 {
                font-size: ${variables.l_heading_500};
                width: 100%;
            }
            p {
                font-size: ${variables.l_paragraph_500};
            }
            .btn {
                margin-top: 10px;
                letter-spacing: 0.5px;
                font-size: ${variables.btn_text_500};
            }
        }
        .right {
            width: 100%;
            margin-top: 20px;
        }
    }
`;

export const SectionFirst = styled.section`
    margin: -5% 0 0 0;
    padding: 150px 0 0;
    position: relative;
    z-index: 1;
    transform: skew(0deg, -5deg);
    background: #fff;
    .skew-inner-container {
        transform: skew(0deg, 5deg);
    }
    .section-lining {
        background: rgba(0, 0, 0, 0.67);;
        position: absolute;
        width: 35%;        
        .dotted {
            width: 100%;
            border-top: 2.5px dashed white;
        }
    }
    .lining-after {
        bottom: 0;
        right: 0;
        padding: 10px 0px 10px 30px;
    }
    .container {
        max-width: ${variables.container_size};
    }
    br {
        display: none;
    }
    h2 {
        font-weight: 500;
        font-size: ${variables.m_heading};
        margin: 0px 0 1.3%;
    }
    .sub-heading {
        font-weight: 300;
        font-size: ${variables.l_paragraph};
        width: 70%;
        margin: 0 auto;
    }
    .box-container {
        justify-content: space-between;
        width: 85%;
        margin: 0 auto 0;
        padding: 7% 0 13% 0;
        .box {
            background: #fff;
            text-align: center;
            box-shadow: 0.4px 0.4px 30px -4px rgba(0,0,0,0.3);
            border-radius: 15px;
            width: 28%;
            padding: 3%;
            transition: 300ms;
            .image-container {                
                margin: 0 auto;
                img {
                    width: 95px;
                    background: #fff;
                    border-radius: 15px;
                }
            }
            h3 {
                font-weight: 600;
                line-height: 20px;
                margin: 12% 0 5.5%;
                font-size: ${variables.l_paragraph};
            }
            p {
                margin: 0;
                line-height: 18px;
                font-size: ${variables.btn_text};
            }
            &:hover {
                background: #91F0FC;
            }
        }
    }

    @media screen and (min-width: 1793px) {        
        .container {
            max-width: ${variables.container_size_1920};
        }
        h2 {
            font-size: ${variables.m_heading_1920};
            margin-bottom: 24px;
        }
        .sub-heading {
            // font-size: ${variables.l_paragraph_1920};
            font-size: 30px;
        }
        .box-container {
            .box {
                .image-container {
                    img {
                        width: 120px;
                    }
                }
                h3 {
                    font-size: ${variables.l_paragraph_1920};
                    line-height: 25px;
                    margin-bottom: 6%;
                }
                p {
                    font-size: ${variables.btn_text_1920};
                    line-height: 22px;
                }
            }
        }
    }

    @media screen and (max-width: 1792px) and (min-width: 1601px) {        
        .container {
            max-width: ${variables.container_size_1792};
        }
        h2 {
            font-size: ${variables.m_heading_1792};
        }
        .sub-heading {
            font-size: ${variables.l_paragraph_1792};
        }
        .box-container {
            .box {
                .image-container {
                    img {
                        width: 110px;
                    }
                }
                h3 {
                    font-size: ${variables.l_paragraph_1792};
                    line-height: 23px;
                }
                p {
                    font-size: ${variables.btn_text_1792};
                    line-height: 20px;
                }
            }
        }
    }

    @media screen and (max-width: 1441px) {        
        .container {
            max-width: ${variables.container_size_1440};
        }
        h2 {
            font-size: ${variables.m_heading_1440};
        }
        .sub-heading {
            font-size: ${variables.l_paragraph_1440};
        }
        .box-container {
            .box {
                h3 {
                    line-height: 19px;
                    font-size: ${variables.l_paragraph_1440};
                }
                p {
                    line-height: 17px;
                    font-size: ${variables.btn_text_1440};
                }
            }
        }
    }

    @media screen and (max-width: 1367px) {
        .container {
            max-width: ${variables.container_size_1366};
        }
        h2 {
            font-size: ${variables.m_heading_1366};
        }
        .sub-heading {
            font-size: ${variables.l_paragraph_1366};
        }
        .box-container {
            .box {
                .image-container {
                    img {
                        width: 85px;
                    }
                }
                h3 {
                    line-height: 18px;
                    font-size: ${variables.l_paragraph_1366};
                }
                p {
                    line-height: 16px; 
                    font-size: ${variables.btn_text_1366};
                }
            }
        }
    }

    @media screen and (max-width: 1100px) {
        padding: 100px 0 0;
        .container {
            max-width: ${variables.container_size_1100};
        }
        h2 {
            font-size: ${variables.m_heading_1100};
        }
        .sub-heading {
            font-size: ${variables.l_paragraph_1100};
        }
        .box-container {
            width: 100%;
        }
    }

    @media screen and (max-width: 900px) {
        .container {
            max-width: ${variables.container_size_800};
        }
        h2 {
            font-size: ${variables.m_heading_800};
        }
        .sub-heading {
            font-size: ${variables.l_paragraph_800};
        }
        .box-container {
            max-width: 500px;
            .box {
                width: 45%;
                margin-bottom: 30px;
                .image-container {
                    img {
                        width: 80px;
                    }
                }
                h3 {
                    font-size: ${variables.l_paragraph_800};
                    line-height: 16px;
                }
                p {
                    font-size: ${variables.btn_text_1100};
                    line-height: 15px;
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        padding: 70px 0;
        .section-lining {
            width: 50%;
        }
        .container {
            max-width: ${variables.container_size_500};
        }
        br {
            display: block;
        }
        h2 {
            font-size: ${variables.m_heading_500};
        }
        .sub-heading {
            font-size: ${variables.l_paragraph_500};
        }
        .box-container  {
            max-width: 300px;
            .box {
                width: 100%;
                padding: 20px;
                h3 {
                    font-size: ${variables.l_paragraph_500};
                }
                p {
                    font-size: ${variables.btn_text_500};
                }
            }
        }
    }
`;

export const SectionSecond = styled.section`
    margin: -5% 0 0;
    position: relative;
    background: linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.5)),url(${sec2_bg});
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: calc(5% + 150px) 0 calc(5% + 150px);
    .container {
        max-width: ${variables.container_size};
        justify-content: space-between;
    }
    .left {
        color: #fff;
        width: 35%;
        h2 {
            font-size: ${variables.m_heading};
            line-height: 48px;
        }
        h3 {
            font-size: ${variables.s_heading};
            line-height: 43px;
            letter-spacing: 0.5px;
            width: 88%;
        }
        p {
            font-size: ${variables.l_paragraph};
            line-height: 34px;
            font-weight: 400;
            margin: 6% 0 0;
            letter-spacing: 0.5px;
        }
        .btn {
            background-color: ${variables.cyan};
            margin: 8% 0 0;
            font-weight: 600;
            border-radius: 40px;
            letter-spacing: 0.5px;
            color: ${variables.white};;
            padding: ${variables.btn_padding_y} ${variables.btn_padding_x};
            font-size: ${variables.btn_text};
            transition: 0.3s;  
            svg {
                margin: 0 0 0 30px;
            }
            &:hover {
                background-color: #fff;
                color: #1F2121;
                path {
                    fill: #1F2121;
                }
            }
        }
    }
    .right {
        width: 61%;
        img {
            width: 100%;
        }
    }

    @media screen and (min-width: 1793px) {
        .container {
            max-width: ${variables.container_size_1920};
        }
        .left {
            width: 33%;
            h2 {                
                line-height: 70px;
                font-size: ${variables.m_heading_1920};
            }
            h3 {
                line-height: 55px;
                width: 101%;
                font-size: ${variables.s_heading_1920};
            }
            p {
                line-height: 40px;
                font-size: ${variables.l_paragraph_1920};
            }
            .btn {
                padding: ${variables.btn_padding_y_1920} ${variables.btn_padding_x_1920};
                font-size: ${variables.btn_text_1920};                
            }
        }
    }

    @media screen and (max-width: 1792px) and (min-width: 1601px) {
        .container {
            max-width: ${variables.container_size_1792};
        }
        .left {
            width: 34%;
            h2 {
                line-height: 60px;
                font-size: ${variables.m_heading_1792};
            }
            h3 {
                line-height: 48px;
                width: 100%;
                font-size: ${variables.s_heading_1792};
            }
            p {
                line-height: 38px;
                font-size: ${variables.l_paragraph_1792};   
            }
            .btn {
                padding: ${variables.btn_padding_y_1792} ${variables.btn_padding_x_1792};
                font-size: ${variables.btn_text_1792};
            }
        }
    }

    @media screen and (max-width: 1441px) {
        .container {
            max-width: ${variables.container_size_1440};
        }
        .left {
            h2 {
                line-height: 43px;
                font-size: ${variables.m_heading_1440};
            }
            h3 {
                line-height: 37px;
                font-size: ${variables.s_heading_1440};
            }
            p {
                font-size: ${variables.l_paragraph_1440};
                line-height: 32px;
            }
            .btn {                
                font-size: ${variables.btn_text_1440};
            }
        }
    }

    @media screen and (max-width: 1367px) {
        .container {
            max-width: ${variables.container_size_1366};
        }
        .left {
            h2 {
                line-height: 40px;
                font-size: ${variables.m_heading_1366};
            }
            h3 {
                line-height: 34px;
                font-size: ${variables.s_heading_1366};
            }
            p {
                line-height: 28px;
                font-size: ${variables.l_paragraph_1366};
            }
            .btn {
                padding: ${variables.btn_padding_y_1366} ${variables.btn_padding_x_1366};
                font-size: ${variables.btn_text_1366};
            }
        }
    }

    @media screen and (max-width: 1100px) {
        padding: calc(5% + 100px) 0 calc(5% + 100px);
        .container {
            max-width: ${variables.container_size_1100};
        }
        .left {
            h2 {
                font-size: ${variables.m_heading_1100};
                line-height: 38px;
            }
            h3 {
                font-size: ${variables.s_heading_1100};
                line-height: 32px;
            }
            p {
                font-size: ${variables.l_paragraph_1100};
                line-height: 27px;
            }
            .btn {
                font-size: ${variables.btn_text_1100};
            }
        }
    }

    @media screen and (max-width: 900px) {
        .container {
            max-width: ${variables.container_size_800};
        }
        .left {
            width: 40%;
            h2 {
                font-size: ${variables.m_heading_800};
                line-height: 35px;
            }
            h3 {
                font-size: ${variables.s_heading_800};
                line-height: 30px;
            }
            p {
                font-size: ${variables.l_paragraph_800};
                line-height: 25px;
            }
            .btn {
                padding: ${variables.btn_padding_y_800} ${variables.btn_padding_x_800};
            }
        }
    }

    @media screen and (max-width: 767px) {
        padding: calc(5% + 70px) 0 calc(5% + 70px);
        background-attachment: unset;        
        background-position: 54%;
        min-height: 0vh;
        .container {
            max-width: ${variables.container_size_500};
        }
        .left {
            width: 100%;
            position: relative;
            z-index: 1;
            h2 {
                font-size: ${variables.m_heading_500};
                line-height: 32px;
            }
            h3 {
                font-size: ${variables.s_heading_500};
                line-height: 27px;
                width: 100%;
            }
            p {
                font-size: ${variables.l_paragraph_500};
                line-height: 23px;
                margin: 4% 0 0;
            }
            .btn {
                font-size: ${variables.btn_text_500};
            }
        }
        .right {
            width: 100%;       
        }
    }
`;

export const SectionThird = styled.section`
    padding: 170px 0 200px;
    margin: -5% 0 0;
    background: #fff;
    transform: skew(0deg, -5deg);
    .skew-inner-container {
        transform: skew(0deg, 5deg);
    }    
    .section-lining {
        position: absolute;
        width: 35%;        
        .dotted {
            width: 100%;
            border-top: 2.5px dashed white;
        }
    }
    .lining-before {
        background: #00E1F8;
        top: 0;
        left: 0;
        padding: 10px 30px 10px 0px;
    }
    .lining-after {
        background: rgba(0, 0, 0, 0.67);
        bottom: 0;
        right: 0;
        padding: 10px 0px 10px 30px;
    }
    .container {
        max-width: ${variables.container_size};
    }
    .f-row {
        justify-content: space-between;
        margin-bottom: 7%;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .text-box {
        width: 45%;
        h2 {
            font-size: ${variables.m_heading};
            line-height: 48px;
        }
        h3 {
            font-size: ${variables.s_heading};
            line-height: 43px;
        }
        p {
            font-size: ${variables.l_paragraph};
            line-height: 34px;
            margin: 2.5% 0 0;
            span {
                font-weight: 600;
            }
        }
        .btn {
            background-color: ${variables.cyan};
            margin: 8% 0 0;
            font-weight: 600;
            border-radius: 40px;
            letter-spacing: 0.5px;
            color: ${variables.white};
            font-size: ${variables.btn_text};
            transition: 0.3s;
            padding: ${variables.btn_padding_y} ${variables.btn_padding_x};
            svg {
                margin: 0 0 0 30px;
            }
            &:hover {
                background-color: #1F2121;
                color: #ffffff;
            }
        }
    }
    .image-container {
        width: 52%;
        img {
            width: 100%;
        }
    }

    @media screen and (min-width: 1793px) {
        .container {
            max-width: ${variables.container_size_1920};
        }
        .text-box {
            width: 47%;
            h2 {
                line-height: 70px;
                font-size: ${variables.m_heading_1920};
            }
            h3 {
                line-height: 55px;
                font-size: ${variables.s_heading_1920};
            }
            p {
                line-height: 40px;
                font-size: ${variables.l_paragraph_1920};
            }
            .btn {
                padding: ${variables.btn_padding_y_1920} ${variables.btn_padding_x_1920};
                font-size: ${variables.btn_text_1920};
            }
        }
        .image-container {
            width: 50%;
        }
    }

    @media screen and (max-width: 1792px) and (min-width: 1601px) {
        .container {
            max-width: ${variables.container_size_1792};
        }
        .text-box {
            h2 {
                line-height: 60px;
                font-size: ${variables.m_heading_1792};
            }
            h3 {
                line-height: 48px;
                font-size: ${variables.s_heading_1792};
            }
            p {
                line-height: 38px;
                font-size: ${variables.l_paragraph_1792};
            }
            .btn {
                padding: ${variables.btn_padding_y_1792} ${variables.btn_padding_x_1792};
                font-size: ${variables.btn_text_1792};
            }
        }
        .image-container {
            width: 51%;
        }
    }

    @media screen and (max-width: 1441px) {
        .container {
            max-width: ${variables.container_size_1440};
        }
        .text-box {
            h2 {
                line-height: 43px;
                font-size: ${variables.m_heading_1440};
            }
            h3 {
                width: 88%;
                line-height: 37px;
                font-size: ${variables.s_heading_1440};
            }
            p {
                line-height: 32px;
                font-size: ${variables.l_paragraph_1440};
            }
            .btn {
                font-size: ${variables.btn_text_1440};
            }
        }
    }

    @media screen and (max-width: 1367px) {
        .container {
            max-width: ${variables.container_size_1366};
        }
        .text-box {
            h2 {
                line-height: 40px;
                font-size: ${variables.m_heading_1366};
            }
            h3 {
                line-height: 34px;
                font-size: ${variables.s_heading_1366};
            }
            p {
                line-height: 28px;
                font-size: ${variables.l_paragraph_1366};
            }
            .btn {
                padding: ${variables.btn_padding_y_1366} ${variables.btn_padding_x_1366};
                font-size: ${variables.btn_text_1366};
            }
        }
    }

    @media screen and (max-width: 1100px) {
        padding: 100px 0 150px;
        .container {
            max-width: ${variables.container_size_1100};
        }
        .text-box {
            h2 {
                font-size: ${variables.m_heading_1100};
                line-height: 37px;
            }
            h3 {
                font-size: ${variables.s_heading_1100};
                line-height: 31px;
            }
            p {
                font-size: ${variables.l_paragraph_1100};
                line-height: 26px;
            }
            .btn {                
                font-size: ${variables.btn_text_1100};
            }
        }
    }

    @media screen and (max-width: 900px) {
        .container {
            max-width: ${variables.container_size_800};
        }
        .text-box {
            h2 {
                font-size: ${variables.m_heading_800};
                line-height: 35px;
            }
            h3 {
                font-size: ${variables.s_heading_800};
                line-height: 29px;
            }
            p {
                font-size: ${variables.l_paragraph_800};
                line-height: 24px;
            }
            .btn {
                padding: ${variables.btn_padding_y_800} ${variables.btn_padding_x_800};
            }
        }
    }

    @media screen and (max-width: 767px) {
        padding: 70px 0 100px;
        .section-lining {
            width: 50%;
        }
        .container {
            max-width: ${variables.container_size_500};
        }
        .f-row {
            margin-bottom: 30px;
        }
        .f-row-swapping {
            .swap-order-1 {
                order: 1;
            }
            .swap-order-2 {
                order: 2;
            }
        }
        .text-box {
            width: 100%;
            margin-bottom: 20px;
            h2 {
                font-size: ${variables.m_heading_500};
                line-height: 32px;
            }
            h3 {
                font-size: ${variables.s_heading_500};
                line-height: 26px;
                width: 100%;
            }
            p {
                font-size: ${variables.l_paragraph_500};
                line-height: 22px;
                margin: 4% 0 0;
            }
            .btn {
                font-size: ${variables.btn_text_500};
            }
        }
        .image-container {
            width: 100%;
        }
    }
`;

