import styled from "styled-components";
import variables from '../../../Style-js/variable';

export const SectionFirst = styled.section`
    margin-top: -5%;
    padding: calc(5% + 180px) 0 100px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    transform: skew(0deg, -5deg);
    background: #F6FAFF;
    .skew-inner-container {
        transform: skew(0deg, 5deg);
    }
    .section-lining {
        position: absolute;
        width: 35%;
        z-index: 3;
        .dotted {
            width: 100%;
            border-top: 2.5px dashed white;
        }
    }
    .lining-after {
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.67);
        padding: 10px 0px 10px 30px;
    }
    .container {
        max-width: ${variables.container_size};
    }
    h2 {
        width: 50%;
        margin: 0 auto;
        font-size: ${variables.m_heading};
    }
    .centered {
        width: 50%;
        margin: 4% auto 0;
    }
    .icon {
        display: inline-block;
        width: 50px;
        height: 50px;
        background: #fff;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.2);
        position: relative;
        border-radius: 100%;
        img {
            position: absolute;                    
        }
        &.success {
            img {
                top: 25%;
                left: 16%;
                width: 65%;    
            }
        }
        &.error {
            img {
                top: 22%;
                left: 20%;
                width: 60%;
            }
        }
    }
    h3 {
        margin-top: 2%;
        font-size: ${variables.s_heading};
        font-weight: 700;
    }
    p {
        margin-top: 2%;
        font-size: ${variables.l_paragraph};
    }
    .btn-container {
        margin-top: 5%;
    }
    .btn {
        background-color: #00E1F8;
        font-size: ${variables.btn_text};
        font-weight: 600;
        border-radius: 40px;
        letter-spacing: 0.5px;
        color: #ffffff;
        padding: ${variables.btn_padding_y} ${variables.btn_padding_x};
        svg {
            margin: 0 0 0 30px;
        }
        &:hover {
            background-color: #1F2121;
        }
    }

    @media screen and (min-width: 1793px) { 
        .container {
            max-width: ${variables.container_size_1920};
        } 
        h2 {
            width: 80%;
            font-size: ${variables.m_heading_1920};
        }
        .icon {
            width: 70px;
            height: 70px;
        }
        .centered { 
            width: 70%;
        }        
        P {
            font-size: ${variables.l_paragraph_1920};
        }
        h3 {
            font-size: ${variables.s_heading_1920};
        }
        .btn {
            font-size: ${variables.btn_text_1920};
            padding: ${variables.btn_padding_y_1920} ${variables.btn_padding_x_1920};
        }
    }

    @media screen and (max-width: 1792px) and (min-width: 1601px) {
        .container {
            max-width: ${variables.container_size_1792};
        }
        h2 {
            width: 80%;
            font-size: ${variables.m_heading_1792};
        }
        .icon {
            width: 70px;
            height: 70px;
        }
        .centered { 
            width: 70%;
        }
        P {
            font-size: ${variables.l_paragraph_1792};
        }
        h3 {
            font-size: ${variables.s_heading_1792};
        }
        .btn {
            font-size: ${variables.btn_text_1792};
            padding: ${variables.btn_padding_y_1792} ${variables.btn_padding_x_1792};
        }
    }

    @media screen and (max-width: 1441px) {
        .container {
            max-width: ${variables.container_size_1440};
        } 
        h2 {
            font-size: ${variables.m_heading_1440};
        }
        P {
            font-size: ${variables.l_paragraph_1440};
        }
        h3 {
            font-size: ${variables.s_heading_1440};
        }
        .btn {
            font-size: ${variables.btn_text_1440};
        }
    }

    @media screen and (max-width: 1367px) {
        .container {
            max-width: ${variables.container_size_1366};
        }
        h2 {
            font-size: ${variables.m_heading_1366};
        }
        P {
            font-size: ${variables.l_paragraph_1366};
        }
        h3 {
            font-size: ${variables.s_heading_1366};
        }
        .btn {
            font-size: ${variables.btn_text_1366};
            padding: ${variables.btn_padding_y_1366} ${variables.btn_padding_x_1366};
        }
    }

    @media screen and (max-width: 1100px) {
        .container {
            max-width: ${variables.container_size_1100};
        }
        h2 {
            font-size: ${variables.m_heading_1100};
        }
        .icon {
            width: 40px;
            height: 40px;
        }
        P {
            font-size: ${variables.l_paragraph_1100};
        }
        h3 {
            font-size: ${variables.s_heading_1100};
        }
        .btn {
            font-size: ${variables.btn_text_1100};
        }
    }

    @media screen and (max-width: 900px) {
        .container {
            max-width: ${variables.container_size_800};
        }
        h2 {
            font-size: ${variables.m_heading_800};
        }
        P {
            font-size: ${variables.l_paragraph_800};
        }
        h3 {
            font-size: ${variables.s_heading_800};
        }
        .btn {
            padding: ${variables.btn_padding_y_800} ${variables.btn_padding_x_800};
        }
    }

    @media screen and (max-width: 767px) {
        padding: calc(5% + 100px) 0 50px;
        .section-lining {
            width: 50%;
        }
        h2 {
            width: 100%;
            font-size: ${variables.m_heading_500};
        }
        .centered { 
            width: 100%;
        }
        P {
            font-size: ${variables.l_paragraph_500};
        }
        .container {
            max-width: ${variables.container_size_500};
        }
        h3 {
            font-size: ${variables.s_heading_500};
        }
        .btn {
            font-size: ${variables.btn_text_500};
        }
    }
`;

