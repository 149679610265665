import { Component } from 'react';
import {SectionFirst} from './Styled'
import car1 from '../../../assets/Automotive/F.png';
import car2 from '../../../assets/Automotive/G.png';
import car3 from '../../../assets/Automotive/H.png';

export class SectionOne extends Component {
    render() {
        return (
            <SectionFirst>
                <div className="container skew-inner-container">
                    <h2 className="text-center">Benefits for Automotive &amp; <br/> Mobility Service Providers</h2>
                    <p className="text-center sub-heading">Why should you integrate our API into your existing user interfaces and future services?</p>
                    <div className="box-container d-flex flex-wrap">
                        <div className="box">
                            <div className="image-container text-center">
                                <img src={car1} alt=""/>
                            </div>
                            <h3>Enhanced Driving Experience</h3>
                            <p> We deliver current, dynamic content that is constantly updated in the existing user interface, while enabling you to offer services with added value for drivers.</p>
                        </div>
                        <div className="box">
                            <div className="image-container text-center">
                                <img src={car2} alt=""/>
                            </div>
                            <h3>Access to large partner network</h3>
                            <p>We provide access to our comprehensive partner network.</p>
                        </div>
                        <div className="box">
                            <div className="image-container text-center">
                                <img src={car3} alt=""/>
                            </div>
                            <h3>Easy API Integration</h3>
                            <p>Our APIs will be integrated into your existing systems so that users can use our value-added services in their familiar environment.</p>
                        </div>
                    </div>
                </div>
                <div className="section-lining lining-after"><div className="dotted"></div></div>
            </SectionFirst>
        )
    }
}

export default SectionOne;