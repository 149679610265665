import styled from "styled-components";
import variables from '../../../Style-js/variable';

export const BannerSection = styled.section`  
    margin: 80px 0 0 0;
    position: relative;
    padding: 60px 0 150px;
    background: #F0F7FF;
    position: relative;
    .container {
        max-width: ${variables.container_size};
    }
    .left {
        width: 100%;
        text-align: center;
        position: relative;
        z-index: 2;
        h1 {
            font-weight: 600;
            font-size: ${variables.l_heading};
            letter-spacing: 0.5px;
        }
    }    

    @media screen and (min-width: 1793px) {
        margin: 94px 0 0 0;
        padding: 100px 0 240px;
        .container {
            max-width: ${variables.container_size_1920};
        }   
        .left {
            h1 {
                line-height: 63px;
                font-size: ${variables.l_heading_1920};
            }
        }
    }

    @media screen and (min-width: 3000px) {
        padding: 150px 0 330px;
    }

    @media screen and (max-width: 1792px) and (min-width: 1601px) {
        .container {
            max-width: ${variables.container_size_1792};
        } 
        .left {
            h1 {
                font-size: ${variables.l_heading_1792};
                line-height: 53px;
            }
        }
    }

    @media screen and (max-width: 1441px) {
        .container {
            max-width: ${variables.container_size_1440};
        }
        .left {
            h1 {
                font-size: ${variables.l_heading_1440};
                line-height: 42px;
            }
        }
    }

    @media screen and (max-width: 1367px) {
        margin: 75px 0 0 0;
        .container {
            max-width: ${variables.container_size_1366};
        }
        .left {
            h1 {
                font-size: ${variables.l_heading_1366};
                line-height: 35px;
            }
        }
    }

    @media screen and (max-width: 1100px) {
        .container {
            max-width: ${variables.container_size_1100};
        }
        .left {
            h1 {
                font-size: ${variables.l_heading_1100};
                line-height: 30px;
            }
        }
    }

    @media screen and (max-width: 900px) {
        .container {
            max-width: ${variables.container_size_800};
        }       
        .left {
            h1 {
                font-size: ${variables.l_heading_800};
                line-height: 25px;
            }
        }
    }

    @media screen and (max-width: 767px) {
        padding: 30px 0 100px;
        .container {
            max-width: ${variables.container_size_500};
        }
        .left {
            max-width: 300px;
            text-align: center;
            margin: 0 auto;
            h1 {
                font-size: ${variables.l_heading_500};
                line-height: 25px;
            }
        }
    }
`;

export const SectionFirst = styled.section`
    position: relative;
    z-index: 2;
    background: #fff;
    transform: skew(0deg,-5deg);
    padding: 200px 0px;
    margin: -84px 0 0 0;
    .skew-inner-container {
        transform: skew(0deg,5deg);
    }
    .section-lining {
        position: absolute;
        width: 35%;
        .dotted {
            width: 100%;
            border-top: 2.5px dashed white;
        }
    }
    .lining-after {
        bottom: 0;
        right: 0;
        background-color: rgba(0,0,0,0.67);
        padding: 10px 0px 10px 30px;
    }
    .container {
        max-width: ${variables.container_size};
    }
    .headings {        
        small {
            font-size: ${variables.btn_text};
            letter-spacing: 3px;
            margin: 0px 0 2%;
        }   
        h2 {
            font-size: ${variables.m_heading2};
            line-height: 35px;
            font-weight: 400;
            margin: 10px 0 0 0;
            width: 38%;
        }         
        p {            
            margin: 10px 0 0 0;
            font-size: 18px;
            a {
                text-decoration: underline;
                color: black;
                text-underline-offset: 5px;
            }
        }
        .firstPara {
            margin: 20px 0 0 0;
        }
    }
    .blog-list {
        margin: 6% 0 0 0; 
        padding: 0;
        list-style-type: none;
        li {
            background: #FFFFFF;
            box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.08);
            border-radius: 20px;
            margin: 0 0 5% 0;
            width: 29%;
            margin-right: 80px;
            cursor: pointer;
            .image-container {
                height: 250px;
                margin: 2%;
                position: relative;
                border-radius: 20px;
                background-size: auto 100%;
                background-position: center top;
            }
            .content {
                padding: 7% 7% 7%;
            }
            h3 {
                font-size: 20px;
                font-weight: 700;
                margin: 0 0 4%;
            }
            p {
                font-size: ${variables.btn_text};
                font-weight: 300;
                line-height: 26px;
                margin: 0 0 6%;
            }
            .btn {
                font-size: ${variables.btn_text};
                font-weight: 500;
                height: 30px;
                width: 130px;
                border: 1px solid #000000;
                box-sizing: border-box;
                border-radius: 5px;
                padding: 0;
                &:hover {
                    color: #fff;
                    background-color: #00E1F8;
                    border: none;
                }
            }
            &:nth-child(3n+0) {
                margin-right: 0;
            }
        }
    }
    .pagination-container {
        // margin: 8% 0 0 0;
        li {
            a {
                color: #000;
                font-weight: 700;
                text-align: center;
                padding-top: 6px;
                border-radius: 100% !important;
                font-size: ${variables.s_heading};
                width: 45px;
                height: 45px;
                background: #F6FAFF;
                // background: #FFFFFF;
                // border: 1px solid #000000;
                box-sizing: border-box;
                margin: 0 0 0 15px;                
                svg {
                    width: 13px;
                }
                &:hover {
                    background-color: ${variables.cyan} !important;
                    border-color: ${variables.cyan} !important;
                    color: #fff;
                }
            }
            .no-border {
                border: none;
                background: none;
            }
        }
        .page-item.active .page-link {
            background-color: ${variables.cyan} !important;
            border-color: ${variables.cyan} !important;
        }
        .page-item.disabled .page-link {
            path {
                stroke: #6c757d;
            }
        }
    }

    @media screen and (min-width: 1793px) {  
        margin: -135px 0 0 0;      
        .container {
            max-width: ${variables.container_size_1920};
        } 
        .headings {              
            small {
                font-size: ${variables.btn_text_1920};
                letter-spacing: 4px;
            }   
            h2 {
                margin: 15px 0 0 0;
                font-size: ${variables.m_heading2_1920};
                line-height: 50px;
            }
            p {            
                margin: 15px 0 0 0;
                font-size: 24px;
            }
            .firstPara {
                margin: 25px 0 0 0;
            }
        }  
        .blog-list {
            li {
                margin-right: 108px;
                border-radius: 25px;
                .image-container {
                    height: 280px;
                    border-radius: 25px;
                }
                h3 {
                    font-size: 26px;
                }
                p {
                    font-size: ${variables.btn_text_1920};
                    line-height: 32px;
                }
                .btn {
                    font-size: ${variables.btn_text_1920};
                    height: 38px;
                    width: 158px;
                }
            }
        }
        .pagination-container {
            li {
                a {
                    width: 60px;
                    height: 60px;
                    margin: 0 0 0 26px;
                    padding-top: 7px; 
                    font-size: ${variables.s_heading_1920}; 
                    svg {
                        width: 17px;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 3000px) {
        margin: -200px 0 0 0;
    }

    @media screen and (max-width: 1792px) and (min-width: 1601px) {
        .container {
            max-width: ${variables.container_size_1792};
        }
        .headings {              
            small {
                font-size: ${variables.btn_text_1792};
            }   
            h2 {
                font-size: ${variables.m_heading2_1792};
                line-height: 45px;
                margin-top: 13px;
            }
            p {            
                margin: 13px 0 0 0;
                font-size: 22px;
            }
            .firstPara {
                margin: 23px 0 0 0;
            }
        } 
        .blog-list {
            li {
                margin-right: 95px;
                border-radius: 25px;
                .image-container {
                    height: 270px;
                    border-radius: 25px;
                }
                h3 {
                    font-size: 26px;
                }
                p {
                    font-size: ${variables.btn_text_1792};
                    line-height: 30px;
                }
                .btn {
                    font-size: ${variables.btn_text_1792};
                    height: 35px;
                    width: 140px;
                }
            }
        } 
        .pagination-container {
            li {
                a {
                    font-size: ${variables.s_heading_1792}; 
                    width: 50px;
                    height: 50px;
                    padding-top: 6px;
                    margin: 0 0 0 20px;
                    svg {
                        width: 17px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1441px) {
        .container {
            max-width: ${variables.container_size_1440};
        }
        .headings {              
            small {
                font-size: ${variables.btn_text_1440};
            }   
            h2 {
                font-size: ${variables.m_heading2_1440};
                margin: 8px 0 0 0;
                line-height: 33px;
            }
            p {
                margin: 7px 0 0 0;
                font-size: 17px;
            }
            .firstPara {
                margin: 17px 0 0 0;
            }
        } 
        .blog-list {
            li {
                margin-right: 76px;
                .image-container {
                    height: 240px;
                }
                h3 {
                    font-size: 19px;
                }
                p {
                    line-height: 25px;
                    font-size: ${variables.btn_text_1440};
                }
                .btn {
                    height: 27px;
                    width: 120px;
                    font-size: ${variables.btn_text_1440};
                }
            }
        }
        .pagination-container {
            li {
                a {
                    padding-top: 5px;
                    font-size: ${variables.s_heading_1440};
                    width: 40px;
                    height: 40px;
                    svg {
                        width: 11px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1367px) {
        .container {
            max-width: ${variables.container_size_1366};
        }
        .headings {
            small {
                font-size: ${variables.btn_text_1366};
            }   
            h2 {
                font-size: ${variables.m_heading2_1366};
                line-height: 30px;
                margin: 6px 0 0 0;
            }  
            p {
                margin: 5px 0 0 0;
                font-size: 16px;
            }
            .firstPara {
                margin: 15px 0 0 0;
            } 
        }
        .blog-list {
            li {
                border-radius: 15px;
                margin-right: 69px;
                .image-container {
                    height: 230px;
                    border-radius: 15px;
                }
                h3 {
                    font-size: 17px;
                }
                p {
                    line-height: 24px;
                    font-size: ${variables.btn_text_1366};
                }
                .btn {
                    height: 25px;
                    width: 110px;
                    font-size: ${variables.btn_text_1366};
                }                
            }
        }
        .pagination-container {
            li {
                a {
                    width: 35px;
                    height: 35px;
                    padding: 5px 0 0 0;
                    margin: 0 0 0 10px;
                    font-size: ${variables.s_heading_1366};
                    svg {
                        width: 9px;
                    }                    
                }
                .no-border {
                    padding: 2px 0 0;
                }
            }
        }
    }

    @media screen and (max-width: 1100px) {
        padding: 100px 0 100px;
        .container {
            max-width: ${variables.container_size_1100};
        }
        .headings {
            small {
                font-size: ${variables.btn_text_1100};
            }   
            h2 {
                font-size: ${variables.m_heading2_1100};
                line-height: 27px;
                margin: 5px 0 0 0;
            }
            p {
                font-size: 15px;
            }
            .firstPara {

            }
        }
        .blog-list {
            li {
                width: 31%;
                margin-right: 33px;
                .image-container {
                    height: 220px;
                }
            }
        }
    }

    @media screen and (max-width: 900px) {
        .container {
            max-width: ${variables.container_size_800};
        }
        .lines-bg {
            width: 30%;
        }
        .headings {            
            width: 100%;
            h2 {
                font-size: ${variables.m_heading2_800};
                line-height: 30px;
                width: 50%;
                line-height: 25px;
            }
        }
        .blog-list {
            margin: 7% auto 0;
            width: 100%;
            li {
                width: 47%;
                .image-container {
                    height: 200px;
                }
                h3 {
                    font-size: 16px;
                }
                p {                    
                    font-size: ${variables.btn_text_1100};
                    line-height: 22px;
                }
                .btn {
                    height: 23px;
                    width: 100px;
                    font-size: ${variables.btn_text_1100};
                }
                &:nth-child(n + 0){
                    margin-right: 39px;
                }
                &:nth-child(2n + 0) {
                    margin-right: 0;
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        padding: 70px 0 ;
        .section-lining {
            width: 50%;
        }
        .lines-bg {
            width: 100%;
            opacity: 0.3;
            top: 10px;
        }
        .container {
            max-width: ${variables.container_size_500};
        }
        .headings {            
            small {
                font-size: ${variables.btn_text_500};                
            }   
            h2 {
                font-size: ${variables.m_heading2_500};
                line-height: 23px;
                width: 100%;
            }
            p {
                font-size: 13px;
            }
        }
        .blog-list {
            max-width: 300px;
            li {
                margin-top: 30px !important;
                width: 100%;
                .image-container {
                    height: 180px;
                }
                h3 {
                    font-size: 15px;
                }
                p {
                    line-height: 20px;
                    font-size: ${variables.btn_text_500};
                }
                .btn {
                    border-radius: 3px;
                    height: 20px;
                    width: 90px;
                    font-size: ${variables.btn_text_500};
                }
                &:nth-child(n + 0){
                    margin-right: 0;
                }
            }
        }
        .pagination-container {
            margin-top: 50px;
        }
        .pagination {
            justify-content: center !important; 
        }
    }
`;
