import { Component } from 'react';
import { BannerSection } from './Styled';
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';

export class Banner extends Component {
    render() {
        return (
            <BannerSection>
                <div className="container">
                    <div className="left">
                        <ScrollAnimation offset={0} duration={1} animateIn="animate__fadeInUp" animateOnce={true}>   
                        <h1>Join Us to Shape <br /> the Future of Mobility!</h1>            
                        </ScrollAnimation>
                        {/* <ScrollAnimation offset={0} duration={0.5}  delay={500} animateIn="animate__fadeInUp" animateOnce={true}>   
                        <p>At 4.screen we strive to surpass the goals we set for ourselves. This means always taking initiative, acting with integrity, and aspiring for greatness</p>
                        </ScrollAnimation> */}
                    </div>
                </div>
            </BannerSection>
        )
    }
}

export default Banner;