import {Component} from 'react';
import {SectionFourth} from './Styled';
import image from '../../../assets/Marketeer/D.webp';

export class SectionFour extends Component {
    render() {
        return (
            <SectionFourth>
                <div className="section-lining lining-before"><div className="dotted"></div></div>
                <div className="container skew-container-inner clearFix">                    
                    <div className="left">
                        <h2>4.screen Portal</h2>
                        <h3>Start Your First Campaign Immediately in 3 Simple Steps</h3>
                        <p>With our state-of-the-art portal, you stay in control.</p>
                        <ul>
                            <li>Register your free account</li>
                            <li>Set up campaigns and upload creatives</li>
                            <li>Launch and manage campaigns in real-time</li>
                        </ul>
                    </div> 
                    <div className="right">
                        <img src={image} alt=""/>
                    </div>                   
                    <div className="card-container-2">
                        <div className="card text-center mx-auto">
                            <h2>Interested? Book a Demo!</h2>
                            <p>Start your successful in-car marketing journey and grow your business with 4.screen.</p>
                            <form action="#" className="d-flex justify-content-center">
                                {/* <input className="" type="text" placeholder="Email id"/> */}
                                <a href="/contact-us" className="btn">Contact Us 
                                    <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.03516 6.4314C6.48287 6.4314 6.03516 6.87911 6.03516 7.4314C6.03516 7.98368 6.48287 8.4314 7.03516 8.4314L7.03516 6.4314ZM8.37202 8.1385C8.76255 7.74798 8.76255 7.11481 8.37202 6.72429L2.00806 0.360328C1.61754 -0.0301964 0.984371 -0.0301964 0.593847 0.360328C0.203322 0.750852 0.203322 1.38402 0.593847 1.77454L6.2507 7.4314L0.593846 13.0882C0.203322 13.4788 0.203322 14.1119 0.593846 14.5025C0.98437 14.893 1.61754 14.893 2.00806 14.5025L8.37202 8.1385ZM7.03516 8.4314L7.66491 8.4314L7.66491 6.4314L7.03516 6.4314L7.03516 8.4314Z" fill="white"/>
                                    </svg>
                                </a>
                            </form>
                        </div>                       
                    </div>
                </div>
                <div className="section-lining lining-after"><div className="dotted"></div></div>
            </SectionFourth>
        )
    }
}

export default SectionFour;