import { StateModel } from "../Models";

export default class Stroage {
    public static LoadState = ():StateModel => {
        try {
            const sessionState:any = sessionStorage.getItem('state') || {};
            const state: StateModel = Object.assign({}, JSON.parse(sessionState));
            return state;
        } catch (err) {
            const params:StateModel = new StateModel();
            return Object.assign({}, params);
        }
    };

    public static SaveState = (state:StateModel):void => {
        try {
            const sessionState = {
                Auth: state.Auth,
                Config: state.Config,
            };
            sessionStorage.setItem('state', JSON.stringify(sessionState));
        } catch (err) {
            console.error(err);
        }
    };
}
