import { Component } from 'react';
import {Banner} from './Banner';
import {SectionOne} from './SectionOne';
import {SectionTwo} from './SectionTwo';
import {SectionThree} from './SectionThree';
import {SectionFour} from './SectionFour';

export class ForMarketers extends Component {
    render() {
        return (
            <div>
                <Banner/>
                <SectionOne/>
                <SectionTwo />
                <SectionThree/>
                <SectionFour/>
            </div>
        )
    }
}

export default ForMarketers;
