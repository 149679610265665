import { Component } from 'react';
import { SectionFirst } from './Styled';
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import { BannerSection } from './Styled';
import { Props, States } from '../../../Redux/Models';
import BackendService from '../../../Services/BackendService';

export class SectionOne extends Component<Props,States> {
    public state:States={
        job: null
    };


    getJob(){
        const {id} = this.props;
        BackendService.getJobs({id}).then(resp => {
            if(resp.isSuccess() && resp?.data.length){
                this.setState({job:resp.data[0]});
            }
        })
    }


    componentDidMount(){
        this.getJob();
        window.scrollTo(0, 0);
    }

    render() {
    const { job } = this.state

        return (
            <>
                <BannerSection>
                    <div className="container">
                        <p className="back"><a href="/career">
                                <svg height="13.466" viewBox="0 0 7.73 13.466" width="7.73" xmlns="http://www.w3.org/2000/svg"><path d="m0 5.315 5.322-5.315s5.307 5.3 5.316 5.315" fill="none" stroke="#1F2121" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" transform="matrix(0 -1 1 0 1 12.052)"/></svg> Back to Jobs
                            </a>
                        </p>
                        <div className="heading text-center">
                            <h1>{job?.title}</h1>
                            <h2>{job?.department?.title}</h2>
                            <a href="mailto:jobs@4screen.com" target="_top">
                                <button className="btn">Apply Now
                                <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.03516 6.4314C6.48287 6.4314 6.03516 6.87911 6.03516 7.4314C6.03516 7.98368 6.48287 8.4314 7.03516 8.4314L7.03516 6.4314ZM8.37202 8.1385C8.76255 7.74798 8.76255 7.11481 8.37202 6.72429L2.00806 0.360328C1.61754 -0.0301964 0.984371 -0.0301964 0.593847 0.360328C0.203322 0.750852 0.203322 1.38402 0.593847 1.77454L6.2507 7.4314L0.593846 13.0882C0.203322 13.4788 0.203322 14.1119 0.593846 14.5025C0.98437 14.893 1.61754 14.893 2.00806 14.5025L8.37202 8.1385ZM7.03516 8.4314L7.66491 8.4314L7.66491 6.4314L7.03516 6.4314L7.03516 8.4314Z" fill="white"></path></svg>
                                </button>
                            </a>
                        </div>
                    </div>
                </BannerSection>
                <SectionFirst>
                    <div className="container skew-inner-container">
                        <div className="description">
                            { job?.description?.indexOf('</') !== -1
                                ? (
                                    <div dangerouslySetInnerHTML={{__html: job?.description?.replace(/(<? *script)/gi, 'illegalscript')}} >
                                    </div>
                                )
                                : job?.description
                            }
                        </div>
                        <div className="card-container-2">
                            <ScrollAnimation duration={2} animateIn="animate__slideInUp" animateOnce={true}>
                            <div className="card text-center mx-auto">
                                {/* <h2><strong>Interested</strong> in becoming a part of our <strong>team</strong>?</h2> */}
                                {/* <p>Your company may not be in the software business, but eventually, a software company will be in your business.</p> */}
                                <h2>Join Us</h2>
                                <form action="#" className="d-flex justify-content-center">
                                    {/* <input className="" type="text" placeholder="Email id"/> */}
                                    <a href="mailto:jobs@4screen.com" target="_top" className="btn">Apply Now 
                                        <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.03516 6.4314C6.48287 6.4314 6.03516 6.87911 6.03516 7.4314C6.03516 7.98368 6.48287 8.4314 7.03516 8.4314L7.03516 6.4314ZM8.37202 8.1385C8.76255 7.74798 8.76255 7.11481 8.37202 6.72429L2.00806 0.360328C1.61754 -0.0301964 0.984371 -0.0301964 0.593847 0.360328C0.203322 0.750852 0.203322 1.38402 0.593847 1.77454L6.2507 7.4314L0.593846 13.0882C0.203322 13.4788 0.203322 14.1119 0.593846 14.5025C0.98437 14.893 1.61754 14.893 2.00806 14.5025L8.37202 8.1385ZM7.03516 8.4314L7.66491 8.4314L7.66491 6.4314L7.03516 6.4314L7.03516 8.4314Z" fill="white"/>
                                        </svg>
                                    </a>
                                </form>
                            </div>  
                            </ScrollAnimation>                      
                        </div> 
                    </div>                
                    <div className="section-lining lining-after"><div className="dotted"></div></div>
                </SectionFirst>
            </>
        )
    }
}

export default SectionOne;